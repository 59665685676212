<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    <v-icon @click="onCancel">mdi-arrow-left-circle</v-icon>
                    Role Detail
                </h1>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card class="w-700" :loading="isBusy">
                    <v-container>
                        <v-form ref="formDetail" v-model="isValid">
                            <v-row class="row-smaller mt-0">
                                <v-col sm="12">
                                    <v-text-field label="Name" v-model="item.Name" autofocus max-length="20" counter="20" :rules="[rules.required, rules.min]"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-subheader class="text-uppercase mt-8 mb-3" style="height: 20px;">Permissions</v-subheader>
                            <v-row class="row-smaller">
                                <v-col sm="12">
                                    <v-checkbox
                                        v-model="item.Perms"
                                        v-for="o in permOptions"
                                        :key="o.id"
                                        :label="o.text"
                                        :value="o.id"
                                        class="mt-1"
                                        multiple
                                        hide-details>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row class="row-smaller mt-6 mb-3">
                                <v-col sm="12">
                                    <v-btn text @click="onAll">
                                        <v-icon class="mr-2">mdi-select-all</v-icon>
                                        Select All
                                    </v-btn>
                                    <v-btn text @click="onNone">
                                        <v-icon class="mr-2">mdi-select-off</v-icon>
                                        Deselect All
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row class="row-smaller mt-3 mb-2">
                            <v-col sm="12" class="d-flex">
                                <v-btn color="primary" :disabled="!isValid" :loading="isSaving" @click="onSave">Save</v-btn>
                                <v-btn class="ml-3" @click="onCancel">Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-menu bottom left offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="error" v-show="item._id" :loading="isDeleting">Delete</v-btn>
                                    </template>
                                    <v-btn color="error" @click="onDelete">
                                        <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                                        Are you sure?
                                    </v-btn>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import { mapState } from 'vuex';

export default {
    name: 'Role',
    mounted () {
        if (this.$route.query && this.$route.query.id) this.item._id = this.$route.query.id;
        this.loadData();
    },
    data: () => ({
        isBusy: false,
        isSaving: false,
        isDeleting: false,
        item: { // Defined the whole object otherwise the properties are not reactive.
            Name: '',
            Perms: []
        },
        itemOrg: {},
        permOptions: Constants.PERMISSION_LIST,
        isValid: false,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.',
            min: value => (value || '').length > 1 || 'Minimum 2 characters'
        }
    }),
    methods: {
        async loadData () {
            if (!this.item._id) {
                this.isBusy = false;
                return;
            }
            this.isBusy = true;
            try {
                const res = await this.$http.get(`/Role/${this.item._id}`);
                if (!res.data.s) {
                    return this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
                }
                const d = res.data;
                this.item = d.d;
                this.itemOrg = Data.duplicate(d.d); // To calculate delta changes.
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        getStatusColor (id) {
            const status = Constants.STATUS_USER.find(o => o.id === id);
            return status ? status.color : 'primary';
        },
        onAll () {
            this.item.Perms = this.permOptions.map(o => o.id);
        },
        onNone () {
            this.item.Perms = [];
        },
        async onSave () {
            this.$refs.formDetail.validate();
            if (this.isValid) {
                const delta = Data.delta(this.itemOrg, this.item); // Only the changed fields.
                if (delta) {
                    try {
                        this.isSaving = true;
                        let res = null;
                        if (this.item._id) { // Update.
                            res = await this.$http.put(`/Role/${this.item._id}`, delta);
                        }
                        else { // Add.
                            delta.ProjectId = this.viewProject._id;
                            res = await this.$http.post('/Role', delta);
                        }
                        if (res.data.s) {
                            this.onCancel();
                        }
                        else this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                    }
                    catch (ex) {
                        this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                    }
                    finally {
                        this.isSaving = false;
                    }
                }
                else this.onCancel(); // Nothing was changed. Nav back.
            }
        },
        async onDelete () {
            try {
                this.isDeleting = true;
                const res = await this.$http.delete(`/Role/${this.item._id}`);
                if (res.data.s) {
                    this.onCancel();
                }
                else this.$error(this.$t('general.delete_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isDeleting = false;
            }
        },
        onCancel () {
            this.$router.go(-1);
        }
    },
    computed: {
        itemColor () {
            return this.item.Color;
        },
        ...mapState({
            viewProject: 'viewProject'
        })
    }
};
</script>
