export class Token {
    constructor (type, value, associativity, precedence, rawValue, ref) {
        this.type = type;
        this.value = value;
        this.associativity = associativity;
        this.precedence = precedence;
        this.paramCount = 0;
        this.rawValue = rawValue || value;
        this.ref = ref;
    }
};

export const TYPE = {
    Operator: 1, // Operator, associativity, precedence.
    Int32: 2,
    Number: 3,
    Text: 4,
    Bool: 5,
    FunctionCall: 6, // Function name, parameter count (number of parameter values).
    Range: 7, // Range name e.g. A1 or A1:C9, type of the value, the value.
    DefinedName: 8,
    Comma: 9,
    LeftParen: 10,
    RightParen: 11,
    LeftCurly: 12,
    RightCurly: 13,
    Space: 14,
    ArrayComma: 15,
    Index: 16,
    Jump: 17,
    None: 18,
    Unknown: 19,
    Error: 99
};
export const LEFT_ASSOCIATIVE = 1;
export const RIGHT_ASSOCIATIVE = 2;

export const SYMBOL_TABLE = new Map();
SYMBOL_TABLE.set(',', new Token(TYPE.Comma, ','));
// SYMBOL_TABLE.set(';', new Token(TYPE.ArrayComma, ';'));
SYMBOL_TABLE.set('&', new Token(TYPE.Operator, '&'));
SYMBOL_TABLE.set('@', new Token(TYPE.Index, '@')); // Current question.
SYMBOL_TABLE.set(' ', new Token(TYPE.Space, ' '));
SYMBOL_TABLE.set('+', new Token(TYPE.Operator, '+'));
SYMBOL_TABLE.set('-', new Token(TYPE.Operator, '-'));
SYMBOL_TABLE.set('(', new Token(TYPE.LeftParen, '('));
SYMBOL_TABLE.set(')', new Token(TYPE.RightParen, ')'));
// SYMBOL_TABLE.set('{', new Token(TYPE.LeftCurly, '{'));
// SYMBOL_TABLE.set('}', new Token(TYPE.RightCurly, '}'));
SYMBOL_TABLE.set('*', new Token(TYPE.Operator, '*'));
// SYMBOL_TABLE.set('.', new Token(TYPE.Operator, '.'));
SYMBOL_TABLE.set('/', new Token(TYPE.Operator, '/'));
SYMBOL_TABLE.set('\\', new Token(TYPE.Operator, '\\'));
// SYMBOL_TABLE.set('=', new Token(TYPE.Operator, '='));
SYMBOL_TABLE.set('>', new Token(TYPE.Operator, '>'));
SYMBOL_TABLE.set('<', new Token(TYPE.Operator, '<'));
SYMBOL_TABLE.set('^', new Token(TYPE.Operator, '^'));

export const SYMBOL_TABLE_2 = new Map();
SYMBOL_TABLE_2.set('==', new Token(TYPE.Operator, '=='));
SYMBOL_TABLE_2.set('>>', new Token(TYPE.Jump, '>>')); // Question jump.
// SYMBOL_TABLE_2.set('<>', new Token(TYPE.Operator, '<>'));
SYMBOL_TABLE_2.set('!=', new Token(TYPE.Operator, '!='));
SYMBOL_TABLE_2.set('>=', new Token(TYPE.Operator, '>='));
SYMBOL_TABLE_2.set('<=', new Token(TYPE.Operator, '<='));
SYMBOL_TABLE_2.set('||', new Token(TYPE.Operator, '||'));
SYMBOL_TABLE_2.set('&&', new Token(TYPE.Operator, '&&'));
// SYMBOL_TABLE_2.set('++', new Token(TYPE.Operator, '++'));
// SYMBOL_TABLE_2.set('--', new Token(TYPE.Operator, '--'));
