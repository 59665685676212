<template>
    <v-container fluid>
        <v-row>
            <v-col sm="3">
                <h1 class="title font-weight-light">
                    Forms ({{ $format.int(pager.total) }})
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="primary" dark v-on="on" @click="onNew"><v-icon>mdi-plus-circle</v-icon></v-btn>
                        </template>
                        <span>Add a new survey</span>
                    </v-tooltip>
                </h1>
            </v-col>
            <v-col sm="1">
                <v-btn color="primary" dark small right fab :loading="isBusy" @click="loadData">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
            <v-col sm="2">
                <v-menu open-on-hover bottom offset-y nudge-top="-10">
                    <template v-slot:activator="{ on }">
                        <span class="forStatus">
                            <v-chip label :color="viewStatus.color" text-color="white" v-on="on" class="mt-1"><v-icon left>mdi-filter-variant</v-icon>{{ viewStatus.text }}</v-chip>
                        </span>
                    </template>
                    <v-list>
                        <v-list-item v-for="item in viewStatusOptions" :key="item.id" @click="viewStatusClick(item)">
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col sm="6">
                <v-container class="pa-0">
                    <v-row no-gutters>
                        <v-spacer></v-spacer>
                        <v-col sm="auto" justify-self="end">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="toggleSearchAt">
                                        <v-icon>{{ searchAt.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>Match search text at this position</span>
                            </v-tooltip>
                        </v-col>
                        <v-col sm="10">
                            <v-text-field ref="search" v-model="searchText" autofocus dense placeholder="Type here to filter the list" :hint="`(${searchAt.text}) ${searchOnFields.join(', ')}`" prepend-icon="mdi-magnify" persistent-hint clearable @keyup.native="searchChange" @click:clear="searchChange"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card>
                    <v-data-table :headers="headers" :items="tableData" :items-per-page="pager.size" :loading="isBusy" multi-sort hide-default-footer no-data-text="No data." :options.sync="tableOptions" @click:row="onSelect">
                        <template v-slot:item.Name="{ item }">
                            <div class="subtitle-2 cur-default">{{ item.Name }}</div>
                            <div class="caption opa-5 cur-default" style="line-height:1">{{ item.Comment }}</div>
                        </template>
                        <template v-slot:item.UpdateDate="{ item }">
                            <!-- <v-chip :color="getColor(item.calories)" dark>{{ item.calories }}</v-chip> -->
                            <span class="cur-default">{{ $format.dateShortTime(item.UpdateDate) }}</span>
                        </template>
                        <template v-slot:item.Version="{ item }">
                            <div v-if="item.PublishedVer" class="f-s cur-default" style="line-height:1;"><span class="el-tag-label f-b pl-1 pr-1 success white--text">v.{{ item.PublishedVer }}</span> Live</div>
                            <div v-if="item.Version !== item.PublishedVer" class="f-s cur-default"><span class="el-tag-label f-b pl-1 pr-1 warning white--text">v.{{ item.Version }}</span> Edited</div>
                            <!-- <div>v.{{ item.PublishedVer }}</div>
                            <div v-if="item.Version !== item.PublishedVer" class="el-tag-label f-xs pl-1 pr-1 warning white--text">v.{{ item.Version }} edited</div>
                            <div v-if="item.Version === item.PublishedVer" class="el-tag-label f-xs opa-5">is live</div> -->
                        </template>
                        <template v-slot:item.StatusId="{ item }">
                            <span class="cur-default">
                                <span :class="`color-circle-s mr-1 ${$getStatusColor(item.StatusId)}`"></span>{{ $getStatusText(item.StatusId) }}
                            </span>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <div class="el-clearfix pb-1 pr-2">
                        <div class="mt-1 float-left">
                            <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="10" @next="filterData" @previous="filterData" @input="filterData"></v-pagination>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details style="max-width:80px;" @change="filterData"></v-select>
                        </div>
                    </div>
                    <!-- <v-row dense>
                        <v-col sm="10">
                            <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="10" @next="filterData" @previous="filterData" @input="filterData" class="justify-start"></v-pagination>
                        </v-col>
                        <v-col sm="2">
                            <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details class="ml-auto mt-3 mr-2" style="max-width:80px;" @change="filterData"></v-select>
                        </v-col>
                    </v-row> -->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import StatusColor from '@/util/StatusColorMixin';
// import Util from '@/util/Util';
import { mapState } from 'vuex';

export default {
    name: 'SurveyList',
    mixins: [StatusColor],
    data: () => ({
        isBusy: false,
        viewStatus: {},
        viewStatusOptions: Constants.STATUS_FILTER,
        searchText: '',
        searchOptions: Constants.SEARCH_OPTIONS,
        searchOnFields: ['Name', 'Table'],
        searchAt: {},
        searchAtOptions: Constants.SEARCH_AT_OPTIONS,
        tableOptions: {
            sortBy: ['Name'],
            sortDesc: [false]
        },
        headers: [
            { text: 'Name', align: 'start', value: 'Name' },
            // { text: 'Table Name', value: 'Table', width: '160px' },
            { text: 'Questions', value: 'QCount', width: '120px', align: 'center' },
            { text: 'Last Update', value: 'UpdateDate', width: '170px' },
            { text: 'Version', value: 'Version', width: '90px', sortable: false },
            { text: 'Status', value: 'StatusId', width: '160px' }
        ],
        tableData: [],
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 10,
            page: 1,
            pages: 1,
            total: 0
        }
    }),
    mounted () {
        this.searchAt = this.searchAtOptions[2];
        const status = this.viewStatusOptions.find(o => o.id === parseInt((this.$ls.get('Survey_ViewStatus') || '0'), 10));
        this.viewStatus = status || this.viewStatusOptions[0];
        const pageSize = this.$ls.get('Survey_PageSize');
        if (pageSize) this.pager.size = parseInt(pageSize, 10);
        this.loadData();
    },
    methods: {
        async loadData () {
            this.isBusy = true;
            this.searchText = '';
            /* try {
                // Remember the page size.
                this.$ls.set('Survey_PageSize', this.pager.size);
                const parms = Util.buildGetParams({
                    searchText: this.searchText,
                    searchAt: this.searchAt,
                    pager: this.pager,
                    tableOptions: this.tableOptions,
                    searchFields: this.searchOnFields,
                    viewStatusId: this.viewStatus.id,
                    projectId: this.viewProject._id
                });
                const res = await this.$http.get('/Survey', parms);
                const d = res.data;
                // Data.
                if (d.d) this.tableData = d.d;
                else this.tableData = [];
                // Pager.
                this.pager = d.p;
            }
            catch (ex) {
                // console.error(ex.stack);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            } */
            try {
                if (this.viewProject._id) {
                    this.tableData = await this.$db.getSurveys(this.viewProject._id, this.viewStatus.id, this.pager, latest => { // Locally stored.
                        this.filterData(); // Refresh from the server.
                    });
                }
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        async filterData () {
            this.isBusy = true;
            try {
                this.$ls.set('Survey_PageSize', this.pager.size);
                if (this.searchText == null) this.searchText = '';
                const localRecords = await this.$db.filterSurveys(this.viewProject._id, this.searchText, this.searchAt.value, this.searchOnFields, this.viewStatus.id, this.pager);
                this.tableData = localRecords;
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        viewStatusClick (rec) {
            this.viewStatus = rec;
            this.$ls.set('Survey_ViewStatus', this.viewStatus.text);
            this.pager.page = 1;
            this.filterData();
        },
        toggleSearchAt () {
            let pos = this.searchAtOptions.indexOf(this.searchAt);
            pos += 1;
            if (pos === this.searchAtOptions.length) pos = 0;
            this.searchAt = this.searchAtOptions[pos];
            if (this.searchText.trim().length) {
                this.pager.page = 1;
                this.filterData();
            }
            this.$refs.search.focus();
        },
        searchChange () {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.pager.page = 1;
                this.filterData();
            }, 100);
        },
        onSelect (item) {
            this.$router.push({ name: 'SurveyEdit', query: { id: item._id } });
        },
        onNew () {
            this.$router.push({ name: 'SurveyEdit' });
        }
    },
    watch: {
        tableOptions: {
            handler () {
                if (this.tableData.length) this.filterData();
            },
            deep: true,
        },
        viewProject () {
            this.filterData();
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject'
        })
    }
};
</script>

<style scoped>
.forStatus::before {
    content: "Status";
    color: rgba(0,0,0,.54);
    font-size: 9pt;
    position: absolute;
    margin-top: 35px;
}
</style>
