<template>
    <v-container fluid>
        <v-row>
            <v-col sm="4">
                <h1 class="title font-weight-light">
                    {{ isInApproval ? 'Approvals' : 'Data Manager' }} ({{ $format.int(pager.total) }})
                </h1>
            </v-col>
            <v-col sm="2">
                <v-btn color="primary" dark small right fab :loading="isBusy" @click="loadData">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
            <!-- <v-col sm="2">
                <v-menu v-model="filterMenu" bottom offset-y nudge-top="-10" nudge-left="300" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <span class="forStatus">
                            <v-chip label color="primary" text-color="white" v-on="on" class="mt-1"><v-icon left>mdi-filter-variant</v-icon>Filter</v-chip>
                        </span>
                    </template>
                    <v-card width="680">
                        <v-container>
                            <v-row class="border-bottom">
                                <v-col sm="5">
                                    <v-select ref="filterQuestion" label="Question" v-model="filterQuestion" :items="filterFields" item-text="text" item-value="value" autofocus>
                                    <template v-slot:item="{ item }">
                                        <v-icon class="mr-2">{{ item.inIcon }}</v-icon>{{ item.text }}
                                    </template>
                                    </v-select>
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center">
                                    <v-menu open-on-hover bottom offset-y nudge-left="38">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" icon><v-icon>{{ searchAt.icon }}</v-icon></v-btn>
                                        </template>
                                        <v-list dense class="pl-2 pr-2">
                                            <v-list-item link class="ma-0 pa-0" v-for="item in searchAtOptions" :key="item.value" @click="onSearchAt(item)">
                                                <v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.text }}
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col sm="5">
                                    <component
                                        ref="filterValue"
                                        :is="filterValueControl"
                                        v-bind="$props"
                                        v-model="filterValue"
                                        clearable
                                        placeholder="Filter value"
                                        :hint="searchAtText"
                                        :error="filterValueError"
                                        v-on:enter="onFilterAdd"
                                        @change="onFilterValueChange"
                                        @focus="$event.target.select()"></component>
                                    <slot></slot>
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center">
                                    <v-btn icon @click="onFilterAdd"><v-icon>mdi-plus</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-for="item in filters" :key="item._id" class="border-bottom">
                                <v-col sm="5" class="d-flex flex-column justify-center pt-0 pb-0">
                                    {{ item.Field }}
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center pt-0 pb-0">
                                    <span><v-icon>{{ item.Oper.icon }}</v-icon></span>
                                </v-col>
                                <v-col sm="5" class="d-flex flex-column justify-center pt-0 pb-0">
                                    <span class="pa-2">{{ oneOrMoreDisplay(item.Value) }}</span>
                                </v-col>
                                <v-col sm="1" class="d-flex flex-column justify-center pt-0 pb-0">
                                    <v-btn icon @click="onFilterRemove(item)"><v-icon>mdi-window-close</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-card-actions>
                            <span v-if="isInApproval">Approval</span>
                            <v-btn-toggle v-if="isInApproval" v-model="approvalFilter" borderless color="primary" class="ml-2">
                                <v-btn small value="P">To Review</v-btn>
                                <v-btn small value="N">Rejected</v-btn>
                                <v-btn small value="Y">Approved</v-btn>
                            </v-btn-toggle>
                            <v-spacer></v-spacer>
                            <v-btn text @click="filterMenu = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col> -->
            <v-col sm="6">
                <v-select ref="survey" label="Survey" v-model="surveyId" :items="surveyOptions" item-text="Name" item-value="_id" hide-details @change="onSurveyChange"></v-select>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card>
                    <div class="el-clearfix pb-1 pr-2">
                        <div class="mt-1 float-left">
                            <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="10" @next="loadData" @previous="loadData" @input="loadData" style="width:auto;"></v-pagination>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-numeric v-model="pager.page" :maxlength="11" label="Go to Page" dense placeholder="Page #" :hint="`Max: ${$format.int(pager.pages)}`" class="el-text-right" style="max-width:90px;" persistent-hint @enter="loadData" v-on:blur="loadData"></v-numeric>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details style="max-width:80px;" @change="loadData"></v-select>
                        </div>
                        <div class="mt-2 float-right">
                            <!-- <v-btn text class="ml-2"><v-icon>mdi-table-eye</v-icon></v-btn> -->
                            <v-menu bottom left offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text class="ml-2"><v-icon :color="headers.length - 1 === headers.filter(o => o._vis).length - 1 ? '' : 'warning'">mdi-table-eye</v-icon></v-btn>
                                </template>
                                <v-list dense class="pl-2 pr-2">
                                <v-list-item>
                                    <v-btn
                                        color="primary"
                                        elevation="2"
                                        x-small
                                        @click="onHeaderVisAll">All</v-btn>
                                    <v-btn
                                        color="primary"
                                        elevation="2"
                                        x-small
                                        @click="onHeaderVisNone">None</v-btn>
                                </v-list-item>
                                    <v-list-item dense link class="ma-0 pa-0 mh-30" v-for="(item, index) in headers.filter(o => o.value !== 'ApprovedIcon')" :key="index">
                                        <v-checkbox v-model="item._vis" :label="item.text" dense hide-details class="ma-0 pa-0" @change="onHeaderVisChange" />
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="mt-1 float-right">
                            <v-checkbox v-model="fieldNames" label="Field Names" hide-details @change="onFieldNamesChange" />
                        </div>
                    </div>
                    <!-- <div class="d-flex ml-2 mr-2 mb-2">
                        <span class="caption mr-2" style="margin-top:2px;">Filters</span>
                        <v-chip v-if="isInApproval" small color="info" class="mr-1" label>Approval <v-icon small class="ml-1" color="yellow">mdi-equal</v-icon><strong class="ml-1">{{ approvalFilter === 'P' ? 'Pending' : (approvalFilter === 'N' ? 'Rejected' : 'Approved') }}</strong></v-chip>
                        <v-chip v-if="!isInApproval && !filters.length" small color="grey lighten-3" class="mr-1" label>None</v-chip>
                        <v-chip v-for="item in filters" :key="item._id" small color="info" class="mr-1" label close @click:close="onFilterRemove(item)">
                            {{ item.Field }} <v-icon small class="ml-1" color="yellow">{{ item.Oper.icon }}</v-icon><strong class="ml-1">{{ oneOrMoreDisplay(item.Value) }}</strong>
                        </v-chip>
                    </div> -->
                    <v-filter class="d-flex ml-2 mr-2 mb-2" :filterList="filters" :surveyFilterFields="filterFields" @removed="loadData" @closed="loadData"></v-filter>
                    <v-divider></v-divider>
                    <div class="wide-table">
                        <v-data-table dense v-model="selected" :show-select="isInApproval" :headers="headers.filter(o => o._vis)" :items="tableData" item-key="_id" :items-per-page="pager.size" :loading="isBusy" multi-sort hide-default-footer no-data-text="No data." :options.sync="tableOptions">
                            <template v-slot:item="{ item }">
                            <tr>
                                <td v-if="isInApproval">
                                    <v-checkbox v-model="selected" :value="item" style="margin:0px;padding:0px" hide-details />
                                </td>
                                <td v-if="isInApproval" class="ma-0 pa-0">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" :color="item.Approved === 'P' ? 'gray' : (item.Approved === 'N' ? 'error' : 'success')">{{ item.ApprovedIcon }}</v-icon>
                                        </template>
                                        <span>{{ item.Reason || 'n/a' }} <i>[by: {{ item.Approver || '?' }}]</i></span>
                                    </v-tooltip>
                                </td>
                                <td v-if="isInApproval">
                                    {{ getApprovalText(item.Approved) }}
                                </td>
                                <td
                                    v-for="header in headers.filter(o => o._vis && o.value !== 'ApprovedIcon' && o.value !== 'Approved')"
                                    :key="header.value"
                                    :class="`el-table-top pt-1 pb-1 el-text-nowrap ${header.align === 'start' ? 'text-left' : 'text-right'}`"
                                    v-html="getValueForCell(item[header.value], header)"
                                    @click="onSelect(item)">
                                </td>
                            </tr>
                            </template>
                        </v-data-table>
                    </div>
                    <v-divider></v-divider>
                    <div class="el-clearfix pb-1 pr-2">
                        <div class="mt-2 el-text-center">
                            <v-menu bottom offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="isInApproval" v-on="on" text class="ml-2" :disabled="!selected.length" :loading="isActioning"><v-icon class="mr-2">mdi-format-list-checks</v-icon>Action</v-btn>
                                </template>
                                <v-list>
                                    <v-list-item link @click="onActionApprove">
                                        Approve
                                    </v-list-item>
                                    <v-list-item link @click="onActionReject">
                                        Reject
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-btn v-if="isInApproval" text class="ml-2" @click="onBulkFile"><v-icon class="mr-2">mdi-list-box-outline</v-icon>Bulk File</v-btn>
                            <v-menu open-on-hover bottom offset-y nudge-top="-4">
                                <template v-slot:activator="{ on }">
                                    <span class="forStatus">
                                        <v-btn v-on="on" text class="ml-2"><v-icon class="mr-2">mdi-export</v-icon>Export</v-btn>
                                    </span>
                                </template>
                                <v-list>
                                    <v-list-item v-for="item in exportOptions" :key="item.id" @click="onExportClick(item.id)">
                                        <v-list-item-title><v-icon left>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="el-clearfix pb-1 pr-2">
                        <div class="mt-1 float-left">
                            <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="10" @next="loadData" @previous="loadData" @input="loadData" style="width:auto;"></v-pagination>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-numeric v-model="pager.page" :maxlength="11" label="Go to Page" dense placeholder="Page #" :hint="`Max: ${$format.int(pager.pages)}`" class="el-text-right" style="max-width:90px;" persistent-hint @enter="loadData" v-on:blur="loadData"></v-numeric>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details style="max-width:80px;" @change="loadData"></v-select>
                        </div>
                    </div>
                    <!-- <v-row class="mt-0">
                        <v-col>
                            <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="10" @next="loadData" @previous="loadData" @input="loadData" style="width:auto;"></v-pagination>
                        </v-col>
                        <v-col align-self="center" sm="6" md="2">
                            <span class="ml-2 opa-6">{{ $format.int(pager.total) }} records</span>
                        </v-col>
                        <v-col align-self="center" sm="6" md="2">
                            <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details @change="loadData"></v-select>
                        </v-col>
                    </v-row> -->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import Util from '@/util/Util';
import numeric from '@/controls/Numeric';
import filterFilter from '@/controls/filter/Filter';
import { mapState } from 'vuex';

export default {
    name: 'DataManager',
    components: {
        'v-numeric': numeric,
        'v-filter': filterFilter,
    },
    data: () => ({
        isInApproval: false,
        isBusy: false,
        isActioning: false,
        surveyId: '',
        surveyOptions: [],
        editLang: 'en', // English is the first language for this deployment.
        tagReg: /<\/?[^>]+(>|$)/g, // Remove tags from string.
        tableOptions: {
            sortBy: ['_UD'],
            sortDesc: [true],
            page: 1,
            itemsPerPage: 20
        },
        rawHeaders: [],
        headers: [
            { text: '_UD', align: 'start', value: 'Date' }
        ],
        headerVis: null,
        tableData: [],
        selected: [],
        fieldNames: false,
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 20,
            page: 1,
            pages: 1,
            total: 0
        },
        filterFields: [],
        filters: [],
        reasons: [],
        userOptions: [],
        searchAt: {},
        searchAtText: '',
        searchAtOptions: Constants.SEARCH_STRING,
        approvalOptions: [
            { _id: 'P', Value: 'To Review' },
            { _id: 'N', Value: 'Rejected' },
            { _id: 'Y', Value: 'Approved' },
            { _id: '', Value: 'All Records' },
        ],
        options: {
            ProjectId: '',
            Survey: null,
            // StartDate: new Date().toISOString().substring(0, 10),
            // EndDate: new Date().toISOString().substring(0, 10),
            // Techs: [],
            Tags: []
        },
        exportOptions: [
            { id: 'pdf', icon: 'mdi-alpha-p-box-outline', text: 'Acrobat PDF (pdf)' },
            { id: 'comma', icon: 'mdi-comma-box-outline', text: 'Comma Separated Values (csv)' },
            { id: 'xlsx', icon: 'mdi-file-excel-box-outline', text: 'Microsoft Excel (xlsx)' },
            { id: 'semi', icon: 'mdi-alpha-s-box-outline', text: 'Semicolon Separated Values (csv)' },
            { id: 'tab', icon: 'mdi-alpha-t-box-outline', text: 'Tab Separated Values (tsv)' },
        ],
    }),
    mounted () {
        const pageSize = this.$ls.get('DataMan_PageSize');
        if (pageSize) this.pager.size = parseInt(pageSize, 10) || '20';
        this.fieldNames = this.$ls.get('DataMan_Fields') === 1;
        this.isInApproval = this.$route.name === 'ApprovalList';
        this.loadViewOptions();
        this.loadLookups();
    },
    methods: {
        async loadLookups () {
            this.isBusy = true;
            try {
                // Multi column sorting uses a different pager object. Sync the two.
                this.tableOptions.page = this.pager.page;
                this.tableOptions.itemsPerPage = this.pager.size;
                const res = await this.$http.get('/Survey', { params: { where: { ProjectId: this.viewProject._id }, fields: 'Name Version Approval' } });
                this.surveyOptions = this.isInApproval ? res.data.d.filter(o => o.Approval) : res.data.d;
                this.surveyId = this.$ls.get('Report_Survey'); // DataMan_Survey
                if (this.surveyId) {
                    // Make sure the id is for the selected project.
                    const survey = this.surveyOptions.find(o => o._id === this.surveyId);
                    if (!survey && this.surveyOptions.length) this.surveyId = this.surveyOptions[0]._id;
                }
                else {
                    if (this.surveyOptions[0] !== undefined) {
                        this.surveyId = this.surveyOptions[0]._id;
                    }
                    else {
                        this.surveyId = '';
                    }
                }
                this.searchAt = this.searchAtOptions.find(o => o.value === 'any');
                this.searchAtText = this.searchAt.text;
                // Users.
                const resu = await this.$http.get('/User/fullnames', { params: { ProjectId: this.viewProject._id } });
                if (resu.data.s && resu.data.d) {
                    this.userOptions = resu.data.d;
                    for (const user of this.userOptions) { // Adjust for common selects.
                        user.Value = user.FullName;
                        delete user.FullName;
                    }
                }
                await this.setFilterOptions();
            }
            catch (ex) {
                console.log(ex.stack);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                await this.loadData();
            }
            this.isBusy = false;
        },
        async loadData () {
            if (!this.surveyId) return;
            this.isBusy = true;
            try {
                this.options.ProjectId = this.viewProject._id;
                this.options.Survey = this.surveyId;
                this.options.paging = this.pager;
                this.options.Where = {};
                Util.buildQueryWithFilters(this.options.Where, this.filters);
                this.options.Table = this.tableOptions;
                // Remember the page size.
                this.$ls.set('DataMan_PageSize', this.pager.size);
                /* const parms = Util.buildGetParams({
                    where: this.options.Where,
                    pager: this.pager,
                    tableOptions: this.tableOptions
                }); */
                // console.log(JSON.stringify(this.options, null, 4));
                // const res = await this.$http.get(`/Survey/${this.surveyId}/answer`, parms);
                // Query the data.
                const res = await this.$http.post(`/Survey/${this.options.Survey}/answer`, this.options);
                const d = res.data;
                this.selected = [];
                // Data.
                if (d.d) {
                    this.reasons = d.d.reasons;
                    this.rawHeaders = d.d.headers;
                    this.tableData = d.d.data;
                    for (const rec of this.tableData) {
                        rec.ApprovedIcon = rec.Approved === 'P'
                            ? 'mdi-progress-question'
                            : (rec.Approved === 'N' ? 'mdi-minus-circle' : 'mdi-check-circle');
                    }
                    await this.loadTable(true);
                    /* const headers = [];
                    d.d.headers.forEach(o => {
                        headers.push({ text: `${o.Index}. ${this.getHeaderText(o)}`, align: o.Type === 'Number' ? 'end' : 'start', value: o.Field, width: this.getWidthByType(o), type: o.Type, Iter: o.Iter });
                        if (o.Type === 'Image') {
                            d.d.data.forEach(rec => {
                                rec[o.Field] = `${this.$_ROOT_URL_IMG}/${rec[o.Field]}`;
                            });
                        }
                        // if (o.Type === 'Boolean') {
                        //     d.d.data.forEach(rec => {
                        //         rec[o.Field] = this.$format.yesNo(rec[o.Field]);
                        //     });
                        // }
                        // else if (o.Type === 'Number') {
                        //     d.d.data.forEach(rec => {
                        //         rec[o.Field] = this.$format.number(rec[o.Field], { decimals: Number.isInteger(rec[o.Field]) ? 0 : 2 });
                        //     });
                        // }
                        // else if (o.Type === 'Image') {
                        //     d.d.data.forEach(rec => {
                        //         // rec[o.Field] = this.$format.number(rec[o.Field], { decimals: Number.isInteger(rec[o.Field]) ? 0 : 2 });
                        //         rec[o.Field] = '';
                        //     });
                        // }
                        // else if (o.Type === 'Location') {
                        //     d.d.data.forEach(rec => {
                        //         if (rec[o.Field] && Array.isArray(rec[o.Field])) {
                        //             rec[o.Field] = this.$format.dmsLatLong(rec[o.Field][1], rec[o.Field][0]);
                        //         }
                        //     });
                        // }
                    });
                    // System columns.
                    headers.push({ text: `${this.makeShort('Version')}`, align: 'start', value: '_V', width: 100, type: 'Number', Iter: null });
                    headers.push({ text: `${this.makeShort('Start')}`, align: 'start', value: '_SD', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', Iter: null });
                    headers.push({ text: `${this.makeShort('End')}`, align: 'start', value: '_ED', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', Iter: null });
                    headers.push({ text: `${this.makeShort('User')}`, align: 'start', value: '_U', width: 200, type: 'String', Iter: null });
                    this.headers = headers;
                    this.tableData = d.d.data;
                    // console.log(this.tableData);
                    this.filterFields = Data.duplicate(headers);
                    // this.filterFields.unshift({ text: 'Approved', align: 'start', value: 'Approved', width: 60, type: 'Boolean', Iter: null });
                    this.filterQuestion = this.headers[0].value; */
                }
                else {
                    this.headers = [{ text: 'Data', align: 'start', value: 'Data' }];
                    this.tableData = [];
                }
                // Pager.
                this.pager = d.p;
                this.options.paging = this.pager; // In case the user exports, this is needed.
            }
            catch (ex) {
                console.error(ex.stack);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        async loadTable (storeOptions) {
            if (storeOptions && !this.headerVis) {
                this.headerVis = {};
                this.rawHeaders.forEach(o => {
                    this.headerVis[o.Field] = true;
                });
                this.headerVis.ApprovedIcon = true;
                this.headerVis.Version = true;
                this.headerVis.Start = true;
                this.headerVis.End = true;
                this.headerVis.User = true;
            }
            const headers = [];
            if (this.isInApproval) {
                headers.push({ text: '', align: 'end', value: 'ApprovedIcon', width: 30, type: 'Icon', itype: Constants.INPUT_TYPE.Note, Iter: '', class: 'ma-0 pa-0', _vis: true }); // this.getHeaderVisibility('ApprovedIcon')
                headers.push({ text: `${this.makeShort('Approval')}`, align: 'start', value: 'Approved', width: 100, type: 'String', itype: Constants.INPUT_TYPE.SelectOne, options: this.approvalOptions, Iter: null, _vis: this.getHeaderVisibility('Approval'), removable: false });
            }
            for (const o of this.rawHeaders) {
                headers.push({
                    text: `${o.Index}. ${this.getHeaderText(o)}`,
                    align: o.Type === 'Number' ? 'end' : 'start',
                    value: o.Field,
                    width: this.getWidthByType(o),
                    type: o.Type,
                    itype: o.InputType,
                    Iter: o.Iter,
                    _vis: this.getHeaderVisibility(o.Field)
                });
                if (o.Type === 'Image') {
                    this.tableData.forEach(rec => {
                        rec[o.Field] = `${this.$_ROOT_URL_IMG}/${rec[o.Field]}`;
                    });
                }
            }
            // if (survey.ParentId) {
            //     headers.push({ text: `${this.makeShort('Primary')}`, align: 'start', value: '_PrimaryData', width: 200, type: 'String', itype: Constants.INPUT_TYPE.String, Iter: null, _vis: this.getHeaderVisibility('_PrimaryData') });
            // }
            // System columns.
            headers.push({ text: `${this.makeShort('Version')}`, align: 'start', value: '_Version', width: 100, type: 'String', itype: Constants.INPUT_TYPE.String, Iter: null, _vis: this.getHeaderVisibility('_Version') });
            headers.push({ text: `${this.makeShort('Start')}`, align: 'start', value: '_Started', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_Started') });
            headers.push({ text: `${this.makeShort('End')}`, align: 'start', value: '_Ended', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_Ended') });
            headers.push({ text: `${this.makeShort('User')}`, align: 'start', value: '_Tech', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_Tech') });
            headers.push({ text: `${this.makeShort('Username')}`, align: 'start', value: '_User', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_User') });
            headers.push({ text: 'UID', align: 'start', value: '_id', width: 100, type: 'String', itype: Constants.INPUT_TYPE.Text, Iter: '', _vis: this.getHeaderVisibility('_id') });
            this.headers = headers;
            // this.filterFields = Data.duplicate(headers.filter(o => o.value !== 'ApprovedIcon'));
            // this.setFilterOptions();

            // this.filterFields.push({ text: 'UID', align: 'start', value: '_id', width: 0, type: 'UUID', Iter: '', class: 'ma-0 pa-0', _vis: false });
            /* this.filterQuestion = this.filterFields[0].value;
            for (const o of this.filterFields) {
                switch (o.type) {
                    case 'String': o.inIcon = 'mdi-card-text-outline'; break;
                    case 'Array': o.inIcon = 'mdi-format-list-checkbox'; break;
                    case 'Number': o.inIcon = 'mdi-alpha-n-box-outline'; break;
                    case 'Date': o.inIcon = 'mdi-calendar'; break;
                    case 'DateTime': o.inIcon = 'mdi-calendar'; break;
                    case 'Time': o.inIcon = 'mdi-clock-outline'; break;
                    case 'Boolean': o.inIcon = 'mdi-toggle-switch-off-outline'; break;
                    case 'Dummy': o.inIcon = 'mdi-car-break-alert'; break;
                    case 'Image': o.inIcon = 'mdi-image-outline'; break;
                    case 'Location': o.inIcon = 'mdi-map-marker-outline'; break;
                    default: o.inIcon = 'mdi-card-text-outline'; break;
                }
            } */

            // Store some options for when coming back to this view.
            if (storeOptions) {
                this.storeViewOptions();
            }
        },
        async setFilterOptions () {
            this.filters.splice(0, this.filters.length);

            // Prepare the survey questions for the filter.
            const survey = await this.$db.getPublishedOne(this.surveyId);
            if (survey !== undefined) {
                const headers = [];
                if (survey.Approval) {
                    headers.push({ text: `${this.makeShort('Approval')}`, align: 'start', value: 'Approved', width: 100, type: 'String', itype: Constants.INPUT_TYPE.SelectOne, options: this.approvalOptions, Iter: null, _vis: this.getHeaderVisibility('Approval'), removable: false });
                    this.filters.push({ _id: Date.now(), Field: 'Approved', Value: (this.isInApproval ? 'P' : 'Y'), Oper: Data.duplicate(Constants.SEARCH_STRING.find(o => o.text === 'Exact')), IType: Constants.INPUT_TYPE.SelectOne, Removable: false });
                }
                for (const o of survey.Questions) {
                    // Options for when the input has additional settings.
                    let options;
                    if (o.InputType === Constants.INPUT_TYPE.SelectOne || o.InputType === Constants.INPUT_TYPE.SelectMany) {
                        options = o.Options.filter(x => x.Lang === this.editLang && x.Value);
                    }
                    // const showField = req.body.fields && req.body.fields.indexOf(q.Field) > -1;
                    const showField = true;
                    o.Question = o.Question[0].Value; // TODO: Language.
                    o.Iter = o.LoopParentIndex !== undefined;
                    o.Type = Data.inputDataType(o.InputType, Constants.INPUT_TYPE);
                    o.Show = o.Rep_Show === false ? showField : false;
                    headers.push({
                        text: `${o.Index}. ${this.getHeaderText(o)}`,
                        align: o.Type === 'Number' ? 'end' : 'start',
                        value: o.Field,
                        width: 100,
                        type: o.Type,
                        itype: o.InputType,
                        options,
                        Iter: o.Iter,
                        _vis: this.getHeaderVisibility(o.Field)
                    });
                }
                if (survey.ParentId) {
                    headers.push({ text: `${this.makeShort('Primary')}`, align: 'start', value: '_PrimaryData', width: 200, type: 'String', itype: Constants.INPUT_TYPE.String, Iter: null, _vis: this.getHeaderVisibility('_PrimaryData') });
                }
                // System columns.
                headers.push({ text: `${this.makeShort('Version')}`, align: 'start', value: '_Version', width: 100, type: 'String', itype: Constants.INPUT_TYPE.String, Iter: null, _vis: this.getHeaderVisibility('_Version') });
                headers.push({ text: `${this.makeShort('Start')}`, align: 'start', value: '_Started', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_Started') });
                headers.push({ text: `${this.makeShort('End')}`, align: 'start', value: '_Ended', width: this.getWidthByType({ Type: 'DateTime' }), type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_Ended') });
                headers.push({ text: `${this.makeShort('User')}`, align: 'start', value: '_Tech', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_Tech') });
                headers.push({ text: `${this.makeShort('Username')}`, align: 'start', value: '_User', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_User') });
                headers.push({ text: 'UID', align: 'start', value: '_id', width: 100, type: 'String', itype: Constants.INPUT_TYPE.Text, Iter: '', _vis: this.getHeaderVisibility('_id') });
                this.filterFields = headers;
            }
            // System columns.
            // headers.push({ text: `${this.makeShort('Version')}`, align: 'start', value: '_V', width: 100, type: 'Number', itype: Constants.INPUT_TYPE.Number, Iter: null, _vis: this.getHeaderVisibility('_V') });
            // headers.push({ text: `${this.makeShort('Start')}`, align: 'start', value: '_SD', width: 100, type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_SD') });
            // headers.push({ text: `${this.makeShort('End')}`, align: 'start', value: '_ED', width: 100, type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_ED') });
            // headers.push({ text: `${this.makeShort('User')}`, align: 'start', value: '_U', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_U') });
            // headers.push({ text: 'UID', align: 'start', value: '_id', width: 100, type: 'String', itype: Constants.INPUT_TYPE.Text, Iter: '', _vis: this.getHeaderVisibility('_id') });
            // this.filterFields = headers;
        },
        storeViewOptions () {
            this.$ls.set(`DataMan_Options_${this.surveyId}`, JSON.stringify({
                approvalFilter: this.approvalFilter,
                pager: this.pager,
                tableOptions: this.tableOptions,
                headerVis: this.headerVis
            }));
        },
        loadViewOptions () {
            // Clear the options.
            this.approvalFilter = 'P'; // Pending.
            this.pager = {
                size: 20,
                page: 1,
                pages: 1,
                total: 0
            };
            this.tableOptions = {
                sortBy: ['_UD'],
                sortDesc: [true],
                page: 1,
                itemsPerPage: 20
            };
            this.headerVis = null;
            // Load the saved state.
            const options = this.$ls.get(`DataMan_Options_${this.surveyId}`);
            if (options) {
                try {
                    const opt = JSON.parse(options);
                    this.approvalFilter = opt.approvalFilter;
                    this.pager = opt.pager;
                    this.tableOptions = opt.tableOptions;
                    this.headerVis = opt.headerVis;
                }
                catch (ex) {}
            }
        },
        onHeaderVisAll () {
            this.headerVis = {};
            this.headers.forEach(o => {
                o._vis = true;
                this.headerVis[o.value] = o._vis;
            });
            this.storeViewOptions();
        },
        onHeaderVisNone () {
            this.headerVis = {};
            this.headers.forEach(o => {
                o._vis = false;
                this.headerVis[o.value] = o._vis;
            });
            this.storeViewOptions();
        },
        onHeaderVisChange () {
            this.headerVis = {};
            this.headers.forEach(o => {
                this.headerVis[o.value] = o._vis;
            });
            this.storeViewOptions();
        },
        getHeaderVisibility (field) {
            if (!this.headerVis || this.headerVis[field] === undefined) return true;
            return this.headerVis[field];
        },
        getHeaderText (q) {
            if (this.fieldNames) return q.Rep_Field || q.Field;
            else return this.makeShort(q.Question);
        },
        getApprovalText (status) {
            switch (status) {
                case 'Y': return 'Approved';
                case 'N': return 'Rejected';
                default: return 'Pending';
            }
        },
        makeShort (str) {
            const len = str.length;
            const s = str.replace(this.tagReg, '').substr(0, 20);
            return (len > 20 && s.length === 20) ? `${s}...` : s;
        },
        onFieldNamesChange () {
            this.$ls.set('DataMan_Fields', this.fieldNames ? 1 : 0);
            this.loadTable();
        },
        onActionApprove () {
            this.$confirm({ title: 'Approve?', message: 'Are you sure you want to approve these records?' }, async yes => {
                if (!yes) return;
                this.approve();
            });
        },
        onActionReject () {
            const reasons = this.reasons.filter(o => o.Lang === this.editLang).map(o => o.Value);
            if (reasons.length) {
                this.$prompt({
                    title: 'Rejection Reason?',
                    message: 'Please provide the reason for rejecting these records:',
                    value: reasons[0],
                    options: reasons,
                    okText: this.$t('general.reject')
                }, async pres => {
                    if (!pres.Action) return;
                    await this.reject(pres.Value);
                });
            }
            else {
                this.$confirm({ title: 'Reject?', message: 'Are you sure you want to reject these records?' }, async yes => {
                    if (!yes) return;
                    await this.reject('None');
                });
            }
        },
        async approve () {
            this.isActioning = true;
            try {
                const res = await this.$http.post(`/Survey/${this.surveyId}/answer/bulk-approve`, {
                    Field: '_id',
                    Uids: this.selected.map(o => o._id),
                });
                if (res.data.s) {
                    this.$success('Success', 'The records were successfully flagged as approved.');
                    this.loadData();
                }
                else this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isActioning = false;
            }
        },
        async reject (reason) {
            this.isActioning = true;
            try {
                const res = await this.$http.post(`/Survey/${this.surveyId}/answer/bulk-reject`, {
                    Field: '_id',
                    Uids: this.selected.map(o => o._id),
                    Reason: reason
                });
                if (res.data.s) {
                    this.$success('Success', 'The records were successfully flagged as rejected.');
                    this.loadData();
                }
                else this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isActioning = false;
            }
        },
        /* viewStatusClick (rec) {
            this.viewStatus = rec;
            this.$ls.set('DataManager_ViewStatus', this.viewStatus.text);
            this.pager.page = 1;
            this.loadData();
        },
        searchChange () {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.pager.page = 1;
                this.loadData();
            }, 600);
        }, */
        getWidthByType (header) {
            return 'auto';
            /* switch (header.Type) {
                case 'String': return 300;
                case 'Number': return 100;
                case 'Boolean': return 80;
                case 'Date': return 120;
                case 'DateTime': return 170;
                case 'Time': return 80;
                case 'Array': return 200;
                case 'Image': return 150;
                case 'Location': return 190;
                default: return 200;
            } */
        },
        getValueForCell (value, header) {
            /* if (header.value === '_U') {
                const user = this.userOptions.find(o => o._id === value);
                return user ? user.Value : value;
            } */
            if (header.Iter) return this.getValueForCellIter(value, header);
            if (value === null || value === undefined) return '';
            if (header.type === 'Location') console.log(value, this.viewProject.GPS);
            switch (header.type) {
                case 'String': return value;
                case 'Number': return this.$format.number(value, { decimals: Number.isInteger(value) ? 0 : 2 });
                case 'Boolean': return this.$format.yesNo(value);
                case 'Date': return typeof value === 'string' ? (value.length > 10 ? this.$format.dateShortTime(new Date(value)) : value) : this.$format.dateYMDDash(value);
                case 'Time': return value;
                case 'Array': return Data.arrayToBullet(value);
                case 'Image': return `<img src="${value || Constants.NO_IMAGE}" width="130" />`;
                // case 'Location': return this.$format.gps(value[1], value[0], this.viewProject.GPS);
                default: return value;
            }
        },
        getValueForCellIter (values, header) {
            if (values === undefined || values === null) return '';
            if (!Array.isArray(values)) values = [values];
            const vals = ['<ol>'];
            values.forEach(value => {
                vals.push('<li>');
                switch (header.type) {
                    case 'String': vals.push(value); break;
                    case 'Number': vals.push(this.$format.number(value, { decimals: Number.isInteger(value) ? 0 : 2 })); break;
                    case 'Boolean': vals.push(this.$format.yesNo(value)); break;
                    case 'Date': vals.push(value); break;
                    case 'Time': vals.push(value); break;
                    case 'Array': vals.push(Data.arrayToBullet(value)); break;
                    case 'Image': vals.push(`<img src="${value || Constants.NO_IMAGE}" width="130" />`); break;
                    case 'Location': vals.push(this.$format.gps(value[1], value[0], this.viewProject.GPS)); break;
                    default: vals.push(value); break;
                }
                vals.push('</li>');
            });
            vals.push('</ol>');
            return vals.join('');
        },
        onSurveyChange () {
            // Store the survey id.
            this.$ls.set('Report_Survey', this.surveyId); // DataMan_Survey
            // Load the viewing options for the selected survey.
            this.loadViewOptions();
            this.headers = [];
            // this.headerVis = {};
            this.selected = [];
            this.reasons = [];
            this.rawHeaders = [];
            this.tableData = [];
            this.setFilterOptions();
            // load the data.
            this.loadData();
        },
        onSelect (item) {
            // _CONST.PERMISSION.SURVEY_ACCESS, _CONST.PERMISSION.SURVEY_VIEW
            const survey = this.surveyOptions.find(o => o._id === this.surveyId);
            this.$router.push({
                name: 'DataEdit',
                query: {
                    id: item._id,
                    surveyId: this.surveyId,
                    surveyName: survey ? survey.Name : '?',
                    isApproval: this.isInApproval
                }
            });
        },
        toggleSearchAt () {
            let pos = this.searchAtOptions.indexOf(this.searchAt);
            pos += 1;
            if (pos === this.searchAtOptions.length) pos = 0;
            this.searchAt = this.searchAtOptions[pos];
            this.searchAtText = this.searchAt.text;

            this.adjustFilterValueInput();

            this.$refs.filterValue.focus();
        },
        onSearchAt (item) {
            this.searchAt = item;
            this.searchAtText = this.searchAt.text;

            this.adjustFilterValueInput();

            this.$refs.filterValue.focus();
        },
        oneOrMoreDisplay (value) {
            return Array.isArray(value) ? Util.oneAndMore(value) : value;
        },
        onBulkFile () {
            const survey = this.surveyOptions.find(o => o._id === this.surveyId);
            this.$router.push({
                name: 'BulkFile',
                query: {
                    surveyId: this.surveyId,
                    surveyName: survey ? survey.Name : '?',
                    isApproval: this.isInApproval
                }
            });
        },
        async onExportClick (id) {
            try {
                const options = Data.duplicate(this.options);
                options.exportAs = id;
                const res = await this.$http.post(`/Survey/${this.options.Survey}/answer-report`, options);
                if (res.data.s) {
                    this.$success('Submitted', 'Your export has been queued for generation and will be available in the Downloads section, once complete.', 3);
                }
                else this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
    },
    watch: {
        tableOptions: {
            handler () {
                this.loadData();
            },
            deep: true,
        },
        viewProject () {
            this.loadLookups();
        },
        filterMenu () {
            // Reload the data when the menu closes and reset the inputs.
            if (!this.filterMenu) {
                this.filterValueType = 'Dummy';
                this.adjustFilterValueInput();
                this.loadData();
            }
        },
        filterQuestion () {
            const field = this.filterFields.find(o => o.value === this.filterQuestion);
            this.filterValueType = field.type;

            // Set the match options for the type.
            switch (this.filterValueType) {
                case 'String':
                case 'Array':
                    this.searchAtOptions = Constants.SEARCH_STRING;
                    break;
                case 'Number':
                case 'Date':
                case 'DateTime':
                case 'Time':
                    this.searchAtOptions = Constants.MATCH_MATH;
                    break;
                case 'Boolean':
                    this.searchAtOptions = Constants.MATCH_BOOL;
                    break;
                case 'Image':
                case 'Location':
                default:
                    this.searchAtOptions = Constants.SEARCH_STRING;
                    break;
            }
            // Set the default.
            this.searchAt = this.searchAtOptions[0];
            this.searchAtText = this.searchAt.text;

            this.adjustFilterValueInput();

            if (this.$refs.filterValue && this.$refs.filterValue.focus) {
                this.$refs.filterValue.focus();
            }
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject'
        })
    }
};
</script>
