<template>
    <v-text-field
        ref="field"
        v-model="model"
        v-on:keyup.enter="onEnter"
        @input="handleChange"
        @click:append="onAppendClick"
        @paste="onPaste"
        :error-messages="errorMessages"
        prepend-icon="mdi-web"
        :rules="[rules.web]"
        v-bind="$attrs">
    </v-text-field>
</template>

<script>
export default {
    name: 'v-filter-web',
    props: {
        value: null,
        'error-messages': null,
    },
    /* beforeUpdate () {
        console.log(this.value, this.model);
    }, */
    data () {
        return {
            model: this.value,
            rules: {
                web: value => {
                    const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
                    return pattern.test(value) || 'Invalid web address.';
                }
            },
        };
    },
    methods: {
        handleChange () {
            this.$emit('change', this.model);
        },
        focus () {
            this.$refs.field.focus();
        },
        onEnter () {
            return this.$emit('send-value', this.model);
        },
        onAppendClick () {
            return this.$emit('append-click', this.model);
        },
        onPaste (evt) {
            this.$emit('paste', evt);
        },
    },
    watch: {
        value () {
            this.model = this.value;
        },
    }
};
</script>
