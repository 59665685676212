<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    <v-icon @click="onCancel">mdi-arrow-left-circle</v-icon>
                    Project Detail
                    <span :class="`color-circle-s ml-1 ${getStatusColor(item.StatusId)}`"></span>
                    <span class="subtitle-1 ml-2 primary--text">{{item.Name}}</span>
                    <v-menu bottom left offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="canFull.indexOf(user._id) > -1" v-on="on" color="error" class="ml-5" small v-show="item._id" :loading="isDeleting">Full Delete</v-btn>
                        </template>
                        <v-btn color="error" @click="onFullDelete">
                            <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                            Are you sure!?
                        </v-btn>
                    </v-menu>
                </h1>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card class="w-700" :loading="isBusy">
                    <v-container>
                        <v-tabs>
                            <v-tab>Detail</v-tab>
                            <v-tab>Properties</v-tab>
                            <v-tab>Tags</v-tab>
                            <v-tab>Branding</v-tab>
                            <!-- Detail -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-form ref="form" v-model="isValid">
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-text-field
                                                ref="name"
                                                label="Project Name"
                                                v-model="item.Name"
                                                counter="50"
                                                clearable
                                                autofocus
                                                :loading="isProjectBusy"
                                                :error="nameError"
                                                :error-messages="nameErrors"
                                                :rules="[rules.required, rules.min, rules.startChar, rules.avail]"
                                                @blur="onBlur">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-select ref="status" label="Status" v-model="item.StatusId" :items="statusOptions" item-text="text" item-value="id" :rules="[rules.required]"></v-select>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="Table Prefix" v-model="item.Prefix" hint="System generated and immutable" :loading="isProjectBusy" persistent-hint disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-select label="Languages" v-model="item.Languages" :items="languageOptions" item-text="text" item-value="id" multiple :rules="[rules.required]"></v-select>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="Last Update" v-model="item.UpdateDateString" persistent-hint disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-textarea label="Comment or Description" v-model="item.Comment" hint="Brief description of the project" rows="4" clearable counter></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <!-- TODO: Notify on changes (send email) -->
                                </v-form>
                            </v-tab-item>
                            <!-- Properties -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-form ref="formProperties" v-model="isValid">
                                    <v-subheader class="text-uppercase mt-1 mb-3" style="height: 20px;">Dashboard</v-subheader>
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-select label="Default Dashboard Report" v-model="item.DashboardReportId" :items="reportOptions" item-text="Title" item-value="_id" hint="Select a custom report to be used as the default dashboard" persistent-hint></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-subheader class="text-uppercase mt-8 mb-3" style="height: 20px;">Notification</v-subheader>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-switch v-model="item.EmailOnChange" label="Send an email on form edit" hint="Notification when a survey is altered" persistent-hint></v-switch>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="Email to" v-model="item.ChangeEmail" hint="Recipient email address" persistent-hint :disabled="!item.EmailOnChange"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-subheader class="text-uppercase mt-8 mb-3" style="height: 20px;">Defaults</v-subheader>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-select label="GPS display format" v-model="item.GPS" :items="gpsOptions" item-text="text" item-value="value" hint="Display format on lists and reports" persistent-hint @change="onGPSChange"></v-select>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="GPS Sample" v-model="gpsSample" hint="Example of what the selected format looks like" persistent-hint readonly></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-switch v-model="item.Approval" label="Requires Approval" hint="Submissions must be reviewed and approved" persistent-hint></v-switch>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-select label="Approver" v-model="item.Approver" :items="approverOptions" item-text="Name" item-value="_id" :disabled="!item.Approval" hint="Approver that is a registered system user" persistent-hint></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-switch v-model="item.Notify" label="Notifications" hint="Enable notification on submission" persistent-hint></v-switch>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="Email to" v-model="item.NotifyEmail" hint="Recipient email address" persistent-hint :disabled="!item.Notify"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-switch v-model="item.NotifyDetail" label="Detailed" hint="Basic notification or full form answer" persistent-hint :disabled="!item.Notify"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                            <!-- Tags -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-row class="row-list mt-0 mb-0">
                                    <v-col sm="12">
                                        <span class="caption ml-2">Tags may be used on reports for grouping or filtering of data. Assign them to users.</span>
                                    </v-col>
                                </v-row>
                                <v-simple-table fixed-header height="400">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center w-50">&nbsp;</th>
                                                <th class="text-left">Tag Name</th>
                                                <th class="text-center w-50">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <draggable v-model="item.Tags" tag="tbody" handle=".optionHandle" @start="drag = true" @end="drag = false">
                                            <tr v-for="(tag, i) in item.Tags" :key="i">
                                                <td class="text-center"><v-icon class="optionHandle">mdi-drag</v-icon></td>
                                                <td>
                                                    <v-text-field v-model="item.Tags[i]" placeholder="Capture tag name" hide-details single-line solo flat></v-text-field>
                                                </td>
                                                <td class="text-center" @click="removeTag(i)"><v-icon class="error--text">mdi-delete-outline</v-icon></td>
                                            </tr>
                                        </draggable>
                                        <tfoot v-if="!item.Tags.length"><tr><td colspan="3" class="text-center">No data.</td></tr></tfoot>
                                    </template>
                                </v-simple-table>
                                <v-divider></v-divider>
                                <v-row class="row-list mt-0">
                                    <v-col cols="12" class="d-flex">
                                        <v-btn text class="mt-2" @click="addTag"><v-icon>mdi-plus</v-icon>Add a tag</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <!-- Branding -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-form ref="form" v-model="isValid">
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-switch v-model="item.Branding" label="Apply Project Branding" hint="Assign custom colours and images" persistent-hint></v-switch>
                                        </v-col>
                                    </v-row>
                                    <!-- Main Colours -->
                                    <v-row class="row-smaller" v-if="item.Branding">
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopPrimary" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorPrimary"
                                                        :disabled="!item.Branding"
                                                        label="Primary"
                                                        placeholder="Primary Colour"
                                                        hint="Focus, buttons and checkbox colours"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorPrimary" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorPrimary)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorPrimary" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopPrimary = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                        <!-- <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopSecondary" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorSecondary"
                                                        :disabled="!item.Branding"
                                                        label="Secondary"
                                                        placeholder="Secondary Colour"
                                                        hint="Set a custom colour"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorSecondary" label :disabled="!item.Branding">
                                                                <v-icon :color="colorText">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorSecondary" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopSecondary = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col> -->
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopAccent" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorAccent"
                                                        :disabled="!item.Branding"
                                                        label="Accent"
                                                        placeholder="Accent Colour"
                                                        hint="Draws attention to an important section"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorAccent" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorAccent)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorAccent" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopAccent = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <!-- Titlebar colours -->
                                    <v-row class="row-smaller" v-if="item.Branding">
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopTitlebarBack" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorTitlebarBack"
                                                        :disabled="!item.Branding"
                                                        label="Titlebar Background"
                                                        placeholder="Titlebar Background Colour"
                                                        hint="Applies to the main page titlebar"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorTitlebarBack" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorTitlebarBack)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorTitlebarBack" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopTitlebarBack = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopTitlebarText" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorTitlebarText"
                                                        :disabled="!item.Branding"
                                                        label="Titlebar Text"
                                                        placeholder="Titlebar Text Colour"
                                                        hint="Applies to the main page titlebar"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorTitlebarText" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorTitlebarText)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorTitlebarText" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopTitlebarText = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <!-- Menu Colours -->
                                    <v-row class="row-smaller" v-if="item.Branding">
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopMenuBack" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorMenuBack"
                                                        :disabled="!item.Branding"
                                                        label="Menu Background"
                                                        placeholder="Menu Background Colour"
                                                        hint="Applies to the main menu section"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorMenuBack" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorMenuBack)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorMenuBack" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopMenuBack = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopMenuText" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorMenuText"
                                                        :disabled="!item.Branding"
                                                        label="Menu Text"
                                                        placeholder="Menu Text Colour"
                                                        hint="Applies to the main menu section"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorMenuText" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorMenuText)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorMenuText" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopMenuText = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller" v-if="item.Branding">
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopMenuSelectedBack" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorMenuSelectedBack"
                                                        :disabled="!item.Branding"
                                                        label="Menu Selected Background"
                                                        placeholder="Menu Selected Background Colour"
                                                        hint="Applies to the main menu section"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorMenuSelectedBack" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorMenuSelectedBack)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorMenuSelectedBack" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopMenuSelectedBack = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                        <v-col sm="6" align-self="center">
                                            <v-menu v-model="colourPopMenuSelectedText" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="item.ColorMenuSelectedText"
                                                        :disabled="!item.Branding"
                                                        label="Menu Text"
                                                        placeholder="Menu Text Colour"
                                                        hint="Applies to the main menu section"
                                                        append-icon="mdi-format-color-fill"
                                                        persistent-hint
                                                        v-on="on">
                                                        <template v-slot:append-outer>
                                                            <v-chip v-on="on" class="elevation-1" :color="item.ColorMenuSelectedText" label :disabled="!item.Branding">
                                                                <v-icon :color="$getContrast(item.ColorMenuSelectedText)">mdi-format-color-text</v-icon>
                                                            </v-chip>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-color-picker v-model="item.ColorMenuSelectedText" swatches-max-height="100" canvas-height="200" dot-size="30" show-swatches flat @input="onColourChange"></v-color-picker>
                                                <v-divider></v-divider>
                                                <div class="d-flex">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text @click="colourPopMenuSelectedText = false">Close</v-btn>
                                                </div>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <!-- Image -->
                                    <v-row class="row-smaller" v-if="item.Branding">
                                        <v-col sm="6" align-self="center" class="mt-4">
                                            <span class="caption">Logo - 32 x 32 px</span>
                                            <v-image-load-crop-dialog v-model="item.Logo32" @fileData="onLogoAccept" :width="32" :height="32" :dialog-width="400" class="brand-logo-preview"></v-image-load-crop-dialog>
                                            <!-- <img v-bind:src="cropped"> -->
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                        </v-tabs>
                        <v-row class="row-smaller mt-4 mb-2">
                            <v-col sm="12" class="d-flex">
                                <v-btn color="primary" :disabled="!isValid" :loading="isSaving" @click="onSave">Save</v-btn>
                                <v-btn class="ml-3" v-show="item._id">Duplicate</v-btn>
                                <v-btn class="ml-3" @click="onCancel">Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-menu bottom left offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="error" v-show="item._id" :loading="isDeleting">Delete</v-btn>
                                    </template>
                                    <v-btn color="error" @click="onDelete">
                                        <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                                        Are you sure?
                                    </v-btn>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import ImageLoadCropDialog from '@/controls/ImageLoadCropDialog';
import Util from '@/util/Util';
import draggable from 'vuedraggable';
import { mapState } from 'vuex';
import { isNullOrUndefined } from 'html5-qrcode/esm/core';

let isNameGood = false;

export default {
    name: 'Project',
    components: {
        draggable,
        'v-image-load-crop-dialog': ImageLoadCropDialog
    },
    mounted () {
        this.$getContrast = Util.getContrast;
        this.item.Languages = ['en'];
        this.item.UpdateDate = new Date();
        this.item.UpdateDateString = this.$format.dateShort(this.item.UpdateDate);

        if (this.$route.query && this.$route.query.id) this.item._id = this.$route.query.id;
        this.item.ColorPrimary = this.$vuetify.theme.themes.light.primary;
        this.item.ColorSecondary = this.$vuetify.theme.themes.light.secondary;
        this.item.ColorAccent = this.$vuetify.theme.themes.light.accent;
        this.item.ColorTitlebarBack = this.$vuetify.theme.themes.light.titlebarBack;
        this.item.ColorTitlebarText = this.$vuetify.theme.themes.light.titlebarText;
        this.item.ColorMenuBack = this.$vuetify.theme.themes.light.menuBack;
        this.item.ColorMenuText = this.$vuetify.theme.themes.light.menuText;
        this.item.ColorMenuSelectedBack = this.$vuetify.theme.themes.light.menuSelectedBack;
        this.item.ColorMenuSelectedText = this.$vuetify.theme.themes.light.menuSelectedText;
        this.lastColorTime = Date.now();
        this.loadLookups();
    },
    data: () => ({
        isBusy: false,
        isSaving: false,
        isDeleting: false,
        isProjectBusy: false,
        item: { // Defined the whole object otherwise the properties are not reactive.
            Name: '',
            Prefix: '',
            StatusId: 'A',
            Languages: [],
            EmailOnChange: false,
            ChangeEmail: '',
            Approval: false,
            Approver: '',
            Notify: false,
            NotifyEmail: '',
            NotifyDetail: false,
            GPS: 'dmst',
            Tags: [],
            Branding: false,
            UpdateDateString: ''
        },
        itemOrg: {},
        statusOptions: Constants.STATUS,
        languageOptions: Constants.LANGUAGES,
        approverOptions: [],
        gpsSample: '',
        gpsOptions: [
            { value: 'd', text: 'Degrees' },
            { value: 'dm', text: 'Degrees and Minutes' },
            { value: 'dms', text: 'Degrees Minutes and Seconds' },
            { value: 'dmst', text: 'Tight DMS (no spaces)' },
            { value: 'n', text: 'Signed Numeric' }
        ],
        reportOptions: [],
        colourPopPrimary: false,
        // colourPopSecondary: false,
        colourPopAccent: false,
        colourPopTitlebarBack: false,
        colourPopTitlebarText: false,
        colourPopMenuBack: false,
        colourPopMenuText: false,
        colourPopMenuSelectedBack: false,
        colourPopMenuSelectedText: false,
        lastColorTime: '',
        nameIsGood: false,
        isValid: false,
        nameError: null,
        nameErrors: null,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.',
            min: value => (value || '').length > 1 || 'Minimum 2 characters',
            startChar: value => isNaN((value || '')[0]) || 'Must start with a character',
            avail: value => isNameGood || 'Validation required.',
        },
        canFull: ['5f7ddb907ecc9e996518ae36']
    }),
    methods: {
        async loadLookups () {
            // If this object is null return
            if (isNullOrUndefined(this.item)) return;
            if (!this.item._id) return;
            this.isBusy = true;
            try {
                // Users for approvers.
                /* const localRecords = await this.$db.getApprovers(this.item._id, recs => { // Locally stored.
                    console.log(recs);
                    if (recs) this.approverOptions = recs || []; // Refresh from the server.
                });
                this.approverOptions = localRecords || []; */
                await this.loadReports();
            }
            catch (ex) {
                console.log(ex);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.loadData();
            }
        },
        async loadData () {
            if (!this.item._id) return;
            this.isBusy = true;
            try {
                // const res = await this.$http.get(`/Project/${this.item._id}`);
                // const d = res.data;
                const d = await this.$db.getProject(this.item._id);
                // console.log(d);
                isNameGood = true;
                this.isValid = true;
                if (!d.Tags) d.Tags = [];
                if (!d.ColorPrimary) d.ColorPrimary = this.$vuetify.theme.themes.light.primary;
                if (!d.ColorSecondary) d.ColorSecondary = this.$vuetify.theme.themes.light.secondary;
                if (!d.ColorAccent) d.ColorAccent = this.$vuetify.theme.themes.light.accent;
                if (!d.ColorTitlebarBack) d.ColorTitlebarBack = this.$vuetify.theme.themes.light.titlebarBack;
                if (!d.ColorTitlebarText) d.ColorTitlebarText = this.$vuetify.theme.themes.light.titlebarText;
                if (!d.ColorMenuBack) d.ColorMenuBack = this.$vuetify.theme.themes.light.menuBack;
                if (!d.ColorMenuText) d.ColorMenuText = this.$vuetify.theme.themes.light.menuText;
                if (!d.ColorMenuSelectedBack) d.ColorMenuSelectedBack = this.$vuetify.theme.themes.light.menuSelectedBack;
                if (!d.ColorMenuSelectedText) d.ColorMenuSelectedText = this.$vuetify.theme.themes.light.menuSelectedText;
                this.item = d;
                this.item.UpdateDateString = this.$format.dateShort(this.item.UpdateDate);
                this.itemOrg = Data.duplicate(d); // To calculate delta changes.
                this.gpsSample = this.$format.gps(-33.9116724, 18.5522531, this.item.GPS);
            }
            catch (ex) {
                console.error(ex.stack);
            }
            finally {
                this.isBusy = false;
            }
        },
        async loadReports () {
            if (!this.viewProject._id) return;
            const localRecords = await this.$db.getReports(this.viewProject._id, 'A', { page: 1, size: 50 }, async latest => { // Locally stored.
                this.updateReportsList(latest);
            });
            this.updateReportsList(localRecords);
        },
        updateReportsList (reports) {
            this.reportOptions = [];
            this.reportOptions.push({ _id: undefined, Title: 'Default Dashboard' });
            if (reports && reports.length) {
                reports.forEach(o => {
                    this.reportOptions.push({ _id: o._id, Title: o.Title });
                });
            }
        },
        getStatusColor (id) {
            const status = Constants.STATUS.find(o => o.id === id);
            return status ? status.color : 'primary';
        },
        onBlur () {
            const name = this.item.Name || '';
            if (!this.item._id && name.length) {
                const pre = Util.nameToTableShort(name);
                // Append a number, if none.
                this.item.Prefix = `${pre}_0001`;
                // Check the database.
                this.isProjectBusy = true;
                setTimeout(() => {
                    this.checkProjectName();
                }, 2000);
            }
        },
        onColourChange () {
            if (Date.now() - this.lastColorTime < 100) return;
            this.lastColorTime = Date.now();
            this.$vuetify.theme.themes.light.primary = this.item.ColorPrimary;
            // this.$vuetify.theme.themes.light.secondary = this.item.ColorSecondary;
            this.$vuetify.theme.themes.light.accent = this.item.ColorAccent;
            this.$vuetify.theme.themes.light.titlebarBack = this.item.ColorTitlebarBack;
            this.$vuetify.theme.themes.light.titlebarText = this.item.ColorTitlebarText;
            this.$vuetify.theme.themes.light.menuBack = this.item.ColorMenuBack;
            this.$vuetify.theme.themes.light.menuText = this.item.ColorMenuText;
            this.$vuetify.theme.themes.light.menuSelectedBack = this.item.ColorMenuSelectedBack;
            this.$vuetify.theme.themes.light.menuSelectedText = this.item.ColorMenuSelectedText;
        },
        async checkProjectName () {
            this.isProjectBusy = true;
            try {
                const res = await this.$http.get(`/Project/available/${this.item.Name}`);
                const d = res.data.d;
                isNameGood = d.Name === null; // There is no exact name in the DB.
                this.nameError = !isNameGood;
                this.nameErrors = isNameGood ? null : 'This project name is already taken!';
                this.item.Prefix = d.Prefix; // Server suggested prefix.
                this.$refs.form.validate();
            }
            catch (ex) {
                console.error(ex.message);
                isNameGood = false;
                this.nameError = !isNameGood;
                this.nameErrors = `Validation failed for '${this.item.Name}'`;
            }
            finally {
                this.isProjectBusy = false;
            }
        },
        addTag () {
            this.item.Tags.push('');
        },
        removeTag (index) {
            this.item.Tags.splice(index, 1);
        },
        onGPSChange () {
            this.gpsSample = this.$format.gps(-33.9116724, 18.5522531, this.item.GPS);
        },
        onLogoAccept (data) {
            this.item.Logo32 = data;
        },
        async onSave () {
            this.$refs.form.validate();
            if (this.isValid) {
                const delta = Data.delta(this.itemOrg, this.item); // Only the changed fields.
                if (delta) {
                    try {
                        this.isSaving = true;
                        // this.$store.commit('gpsDisplay', this.item.GPS);
                        let res = null;
                        if (this.item._id) { // Update.
                            res = await this.$http.put(`/Project/${this.item._id}`, delta);
                        }
                        else { // Add.
                            res = await this.$http.post('/Project', delta);
                        }
                        if (res.data.s) {
                            if (this.item._id === this.viewProject._id) this.$store.commit('viewProject', this.item);
                            // Give the signed in user access to the new project.
                            if (!this.item._id) {
                                const acc = await this.$http.patch(`/User/${this.user._id}/project/${res.data.d}`, delta);
                                if (!acc.data.s) console.warn('Project access failed.');
                            }
                            this.onCancel();
                        }
                        else this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                    }
                    catch (ex) {
                        this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                    }
                    finally {
                        this.isSaving = false;
                    }
                }
                else this.onCancel(); // Nothing was changed. Nav back.
            }
        },
        async onDelete () {
            try {
                this.isDeleting = true;
                const res = await this.$http.delete(`/Project/${this.item._id}`);
                if (res.data.s) {
                    this.onCancel();
                }
                else this.$error(this.$t('general.delete_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isDeleting = false;
            }
        },
        async onFullDelete () {
            try {
                this.isDeleting = true;
                const res = await this.$http.delete(`/Project/${this.item._id}/full-delete`);
                if (res.data.s) {
                    this.onCancel();
                }
                else this.$error(this.$t('general.delete_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isDeleting = false;
            }
        },
        onCancel () {
            this.$router.go(-1);
        }
    },
    computed: {
        ...mapState({
            user: 'user',
            viewProject: 'viewProject'
        })
    }
};
</script>
