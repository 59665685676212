<template>
    <v-container>
        <v-row>
            <v-col sm="12" class="mb-0 pb-0">
                <h1 class="title font-weight-light">
                    <v-icon v-if="this.preview" @click="onBack">mdi-arrow-left-circle</v-icon>
                    {{ this.item.Name }}&nbsp;<span class="font-weight-light subtitle-1" v-html="this.preview ? '<b class=accent--text>PREVIEW</b>. No answers will be saved' : ''"></span>
                    <span class="caption ml-2">v.{{item.Version}}</span>
                </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12" class="mt-0 pt-0 w-1024">
                <v-card-actions class="ma-0 pa-0">
                    <span class="font-weight-light f-m">{{ user.FullName }}</span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-light f-s">{{ $format.dateTime(new Date()) }}</span>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card :loading="isBusy" class="w-1024">
                    <v-container>
                        <v-progress-linear :value="(index / item.Questions.length) * 100" :active="true" absolute top color="accent"></v-progress-linear>
                        <div class="d-flex"><v-spacer></v-spacer><span class="caption">{{ index === $CONST.END_QUESTION ? item.Questions.length : index }} / {{ item.Questions.length }}</span></div>
                        <!-- Add Previous button to the top as well for simple way to go back-->
                        <!-- <v-card-actions v-if="!isSaveDone">
                            <v-btn v-if="!isSaving" text :disabled="item.ShowStart ? index === 0 : index === 1" @click="onPrevious"><v-icon>mdi-chevron-left</v-icon>Previous</v-btn>
                        </v-card-actions> -->
                        <!------------------- -->
                        <v-card-text style="min-height:300px;">
                            <div class="question-label" v-if="item.ShowStart && index === 0" v-html="item.StartMessage"></div>
                            <div class="question-label" v-if="item.ShowEnd && isEnd" v-html="item.EndMessage"></div>
                            <div class="question-label" v-if="index !== 0 && index !== $CONST.END_QUESTION" v-html="questionText"></div>
                            <div>
                                <!-- Text -->
                                <v-text-field
                                    v-if="showInputType($CONST.INPUT_TYPE.Text) && (question.Lines || 1) < 2"
                                    v-model="question.Answer[loopCounter || 0]"
                                    :counter="question.Max || false"
                                    :minlength="question.Min || false"
                                    :maxlength="question.Max || false"
                                    :prefix="question.Pre"
                                    :suffix="question.Post"
                                    :placeholder="question.InHint"
                                    :hint="question.OutHint"
                                    @keyup="formatValidation(question._id,question.Format)"
                                    @keydown.enter.prevent="onNext"
                                    persistent-hint>
                                </v-text-field>
                                <v-textarea
                                    v-else-if="showInputType($CONST.INPUT_TYPE.Text) && (question.Lines || 1) > 1"
                                    v-model="question.Answer[loopCounter || 0]"
                                    :rows="question.Lines || 2"
                                    :counter="question.Max || false"
                                    :minlength="question.Min || false"
                                    :maxlength="question.Max || false"
                                    :prefix="question.Pre"
                                    :suffix="question.Post"
                                    :placeholder="question.InHint"
                                    :hint="question.OutHint"
                                    persistent-hint>
                                </v-textarea>
                                <!-- Number -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.Number)">
                                    <v-numeric
                                        v-model="question.Answer[loopCounter || 0]"
                                        type="number"
                                        :maxlength="11"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        @enter="onNext"
                                        persistent-hint>
                                    </v-numeric>
                                    <span class="caption ml-2 mt-0" v-if="!isBlank(question.Min) || !isBlank(question.Max)">{{ !isBlank(question.Min) && !isBlank(question.Max) ? `Must be between ${question.Min} and ${question.Max}` : (isBlank(question.Min) ? `Must not be greater than ${question.Max}` : `Must not be less than ${question.Min}`)}}</span>
                                </div>
                                <!-- Mobile -->
                                <v-text-field
                                    v-else-if="showInputType($CONST.INPUT_TYPE.Mobile)"
                                    v-model="question.Answer[loopCounter || 0]"
                                    v-mask="'###-###-####'"
                                    :prefix="question.Pre"
                                    :suffix="question.Post"
                                    :placeholder="question.InHint"
                                    :hint="question.OutHint"
                                    :rules="[rules.mobile]"
                                    @keydown.enter.prevent="onNext"
                                    prepend-icon="mdi-cellphone"
                                    persistent-hint>
                                </v-text-field>
                                <!-- <v-mask
                                    v-else-if="showInputType($CONST.INPUT_TYPE.Mobile)"
                                    v-model="question.Answer[loopCounter || 0]"
                                    v-mask="'###-###-####'"
                                    :prefix="question.Pre"
                                    :suffix="question.Post"
                                    :placeholder="question.InHint"
                                    :hint="question.OutHint"
                                    @keydown.enter.prevent="onNext"
                                    prepend-icon="mdi-cellphone"
                                    persistent-hint>
                                </v-mask> -->
                                <!-- Email -->
                                <v-text-field
                                    v-else-if="showInputType($CONST.INPUT_TYPE.Email)"
                                    v-model="question.Answer[loopCounter || 0]"
                                    :counter="question.Max || false"
                                    :minlength="question.Min || false"
                                    :maxlength="question.Max || false"
                                    :prefix="question.Pre"
                                    :suffix="question.Post"
                                    :placeholder="question.InHint"
                                    :hint="question.OutHint"
                                    :rules="[rules.email]"
                                    @keydown.enter.prevent="onNext"
                                    prepend-icon="mdi-email-open-outline"
                                    persistent-hint>
                                </v-text-field>
                                <!-- WebAddress -->
                                <v-text-field
                                    v-else-if="showInputType($CONST.INPUT_TYPE.WebAddress)"
                                    v-model="question.Answer[loopCounter || 0]"
                                    :counter="question.Max || false"
                                    :minlength="question.Min || false"
                                    :maxlength="question.Max || false"
                                    :prefix="question.Pre"
                                    :suffix="question.Post"
                                    :placeholder="question.InHint"
                                    :hint="question.OutHint"
                                    :rules="[rules.web]"
                                    @keydown.enter.prevent="onNext"
                                    prepend-icon="mdi-web"
                                    persistent-hint>
                                </v-text-field>
                                <!-- YesNo -->
                                <v-btn-toggle
                                    v-else-if="showInputType($CONST.INPUT_TYPE.YesNo)"
                                    v-model="question.Answer[loopCounter || 0]"
                                    color="accent"
                                    group
                                    tile>
                                    <v-btn :value="true" class="pl-11 pr-11">
                                        Yes
                                    </v-btn>
                                    <v-btn :value="false" class="pl-12 pr-12">
                                        No
                                    </v-btn>
                                </v-btn-toggle>
                                <!-- Select One -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.SelectOne)">
                                    <!-- Filter -->
                                    <v-text-field v-show="question.Filter" v-model="filterOne" class="w-512-max" dense single-line hint="Type to search and filter the available options" prepend-icon="mdi-magnify" persistent-hint></v-text-field>
                                    <span v-show="question.Filter" class="caption opa-6 ml-8 mt-0">A maximum result of 20 options will be shown.</span>
                                    <!-- Options -->
                                    <v-radio-group v-model="question.Answer[loopCounter || 0]">
                                        <v-radio
                                            v-for="item in optionsOne"
                                            :key="item._id"
                                            :label="item.Value"
                                            :value="item.Value">
                                        </v-radio>
                                    </v-radio-group>
                                    <span class="caption">{{ question.OutHint }}</span>
                                </div>
                                <!-- Select Many -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.SelectMany)">
                                    <!-- Filter -->
                                    <v-text-field v-show="question.Filter" v-model="filterMany" class="w-512-max" dense single-line hint="Type to search and filter the available options" prepend-icon="mdi-magnify" persistent-hint @keyup.native="filterManyChange"></v-text-field>
                                    <span v-show="question.Filter" class="caption opa-6 ml-8">A maximum result of 20 options will be shown.</span>
                                    <!-- Options -->
                                    <v-checkbox
                                        v-model="question.Answer[loopCounter || 0]"
                                        v-for="item in optionsMany"
                                        :key="item._id"
                                        :label="item.Value"
                                        :value="item.Value"
                                        class="mt-1"
                                        multiple
                                        hide-details>
                                    </v-checkbox>
                                    <span class="caption mt-6 d-block">{{ question.OutHint }}</span>
                                </div>
                                <!-- Date -->
                                <v-menu
                                    v-else-if="showInputType($CONST.INPUT_TYPE.Date)"
                                    v-model="question.ModalDate"
                                    :close-on-content-click="false"
                                    :nudge-right="32"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="question.Answer[loopCounter || 0]"
                                            :placeholder="question.InHint"
                                            :hint="question.OutHint"
                                            @keydown.enter.prevent="onNext"
                                            prepend-icon="mdi-calendar"
                                            persistent-hint
                                            readonly
                                            v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="question.Answer[loopCounter || 0]" @input="question.ModalDate = false"></v-date-picker>
                                </v-menu>
                                <!-- Time -->
                                <v-menu
                                    v-else-if="showInputType($CONST.INPUT_TYPE.Time)"
                                    ref="menu"
                                    v-model="modalTime"
                                    :close-on-content-click="false"
                                    :nudge-right="32"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="question.Answer[loopCounter || 0]"
                                            :placeholder="question.InHint"
                                            :hint="question.OutHint"
                                            @keydown.enter.prevent="onNext"
                                            prepend-icon="mdi-clock-outline"
                                            format="24hr"
                                            scrollable
                                            persistent-hint
                                            readonly
                                            v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-model="question.Answer[loopCounter || 0]" @click:minute="modalTime = false"></v-time-picker>
                                </v-menu>
                                <!-- DOB -->
                                <v-menu
                                    v-else-if="showInputType($CONST.INPUT_TYPE.DateOfBirth)"
                                    v-model="modalDOB"
                                    :close-on-content-click="false"
                                    :nudge-right="32"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="question.Answer[loopCounter || 0]"
                                            :placeholder="question.InHint"
                                            :hint="question.OutHint"
                                            @keydown.enter.prevent="onNext"
                                            prepend-icon="mdi-gift-outline"
                                            persistent-hint
                                            readonly
                                            v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker ref="previewDOB" v-model="question.Answer[loopCounter || 0]" @input="modalDOB = false" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
                                </v-menu>
                                <!-- Rating -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.Rating)">
                                    <v-rating
                                        v-model="question.Answer[loopCounter || 0]"
                                        :length="question.Max || 5"
                                        color="accent"
                                        background-color="grey lighten-1"></v-rating>
                                    <span class="caption">{{ question.OutHint }}</span>
                                </div>
                                <!-- Slider -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.RangeSlider)">
                                    <v-row class="row-smaller mt-7">
                                        <v-col sm="12" class="d-flex">
                                            <span class="body-1 mr-2" v-show="question.Pre">{{ question.Pre }}</span>
                                            <v-slider
                                                v-model="question.Answer[loopCounter || 0]"
                                                :thumb-size="24"
                                                :min="question.Min || 0"
                                                :max="question.Max || 10"
                                                :step="question.Step || 1"
                                                :hint="question.OutHint"
                                                thumb-label="always"
                                                ticks="always"
                                                tick-size="4"
                                                color="accent"
                                                persistent-hint>
                                            </v-slider>
                                            <span class="body-1 ml-2" v-show="question.Post">{{ question.Post }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="12" class="text-center display-1 accent--text" align-self="center">
                                            <span>{{ question.Answer[loopCounter || 0] }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- Image -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.Image)">
                                    <v-btn color="primary" class="mr-2" @click="onTakePicture">
                                        <v-icon left>mdi-camera</v-icon>
                                        Take Picture
                                    </v-btn>
                                    <v-btn color="primary" v-if="question.Local" @click="onFileLoadPhotoClick">
                                        <v-icon left>mdi-folder-image</v-icon>
                                        <span class="hide-sx">Load</span>
                                    </v-btn>
                                    <v-file-input ref="fileLoadPhoto" v-model="question.ValueFile" accept="image/*" label="Load Image" class="d-none" @change="onFileLoadChange"></v-file-input>
                                    <br/>
                                    <v-img :src="question.Answer[loopCounter || 0]" class="mt-2"></v-img><!-- https://picsum.photos/510/300?random -->
                                    <span class="caption">{{ question.OutHint }}</span>
                                </div>
                                <!-- Signature -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.Signature)">
                                    <v-icon class="mr-2" v-if="!canSignAgain">mdi-fingerprint</v-icon>
                                    <v-btn color="primary" class="mr-2" v-if="canSignAgain" @click="onSignAgain">
                                        <v-icon left>mdi-fingerprint</v-icon>
                                        <span class="hide-sx mr-1">Sign</span>Again
                                    </v-btn>
                                    <v-btn color="primary" v-if="question.Local" @click="onFileLoadSignClick">
                                        <v-icon left>mdi-folder-image</v-icon>
                                        <span class="hide-sx">Load</span>
                                    </v-btn>
                                    <v-btn color="primary" class="error ml-2 float-right" @click="onSignClear">
                                        <v-icon text>mdi-delete-forever-outline</v-icon>
                                        <span class="hide-sx">Clear</span>
                                    </v-btn>
                                    <v-file-input ref="fileLoadSign" v-model="question.ValueFile" accept="image/*" label="Load Image" class="d-none" @change="onFileLoadChange"></v-file-input>
                                    <br/>
                                    <canvas v-if="!canSignAgain" id="user-sign" style="width: 100%; height: 300px; border: 10px solid #EEEEEE;"></canvas>
                                    <v-img v-if="canSignAgain" :src="question.Answer[loopCounter || 0]" class="mt-2"></v-img><!-- https://picsum.photos/510/300?random -->
                                    <span class="caption">{{ question.OutHint }}</span>
                                </div>
                                <!-- GPS Location -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.GPSLocation)">
                                    <v-btn color="primary" v-show="!isLocationOn" @click="getLocation">
                                        <v-icon left>mdi-map-marker-radius</v-icon>
                                        Get Location
                                    </v-btn>
                                    <v-btn color="warning" v-show="isLocationOn" @click="stopLocation">
                                        <v-icon left>mdi-map-marker-off-outline</v-icon>
                                        Stop
                                    </v-btn>
                                    <br/>
                                    <span class="caption">{{ question.OutHint }}</span>
                                    <v-row class="w-512-max mt-2 row-smaller">
                                        <v-col sm="4">Coordinates</v-col>
                                        <!-- <v-col sm="8" class="font-weight-bold">{{ question.Answer[loopCounter || 0] || '-' }}</v-col> -->
                                        <v-col sm="8" class="font-weight-bold subtitle-1">{{ question.Display }}</v-col>
                                    </v-row>
                                    <v-row class="w-512-max row-smaller">
                                        <v-col sm="4">Latitude</v-col>
                                        <v-col sm="8">{{ question.DisplayLat || '-' }}</v-col>
                                    </v-row>
                                    <v-row class="w-512-max row-smaller">
                                        <v-col sm="4">Longitude</v-col>
                                        <v-col sm="8">{{ question.DisplayLon || '-' }}</v-col>
                                    </v-row>
                                    <v-row class="w-512-max row-smaller">
                                        <v-col sm="4">Accuracy</v-col>
                                        <v-col sm="8" :class="question.DisplayAccFail ? 'error--text' : ''">{{ question.DisplayAcc }}</v-col>
                                    </v-row>
                                    <v-row class="w-512-max row-smaller">
                                        <v-col sm="4">Altitude</v-col>
                                        <v-col sm="8">{{ question.DisplayAlt || '-' }}</v-col>
                                    </v-row>
                                    <v-row class="w-512-max row-smaller">
                                        <v-col sm="4">Measured at</v-col>
                                        <v-col sm="8">{{ question.DisplayTime || '-' }}</v-col>
                                    </v-row>
                                    <v-row class="w-512-max row-smaller">
                                        <v-col sm="4">Attempts</v-col>
                                        <v-col sm="8">{{ question.DisplayAttempts || '-' }}</v-col>
                                    </v-row>
                                    <!-- <v-row class="w-512-max">
                                        <v-col sm="4">Speed</v-col>
                                        <v-col sm="8">{{ question.Meta[loopCounter || 0].ValueSpd || '-' }}</v-col>
                                    </v-row> -->
                                </div>
                                <!-- Barcode -->
                                <div v-else-if="showInputType($CONST.INPUT_TYPE.Barcode)">
                                    <!-- <v-pdf-417 /> -->
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <v-text-field
                                                v-model="question.Answer[loopCounter || 0]"
                                                prepend-icon="mdi-qrcode-scan"
                                                :prefix="question.Pre"
                                                :suffix="question.Post"
                                                :placeholder="question.InHint"
                                                :hint="question.OutHint"
                                                @keydown.enter.prevent="onNext"
                                                persistent-hint>
                                            </v-text-field>
                                        </div>
                                        <div class="flex-shrink-1 pt-3 pl-2">
                                            <v-btn color="primary" @click="onScanBarcode">Scan</v-btn>
                                        </div>
                                    </div>
                                    <div id="qr-reader" style="height: 100%;"></div>
                                    <!-- <v-row class="w-512-max mt-2">
                                        <v-col sm="12" class="font-weight-bold">{{ question.Answer[loopCounter || 0] || '-' }}"</v-col>
                                    </v-row> -->
                                </div>
                            </div>
                        </v-card-text>
                        <v-alert type="error" v-show="errorDetail">
                            {{ errorDetail }}
                        </v-alert>
                        <v-alert type="error" v-show="answerNotValid">
                            Answer validation failed. Please review your answer.
                        </v-alert>
                        <div v-if="isSaveDone" class="text-right">
                            <v-icon color="success" style="font-size:100px;">mdi-check</v-icon>
                        </div>
                        <v-divider v-if="!isSaveDone"></v-divider>
                        <v-card-actions v-if="!isSaveDone">
                            <v-btn v-if="!isSaving" text :disabled="item.ShowStart ? index === 0 : index === 1" @click="onPrevious"><v-icon>mdi-chevron-left</v-icon>Previous</v-btn>
                            <v-spacer></v-spacer>
                            <v-chip v-if="loopCounter" color="accent" label dark>
                                <v-icon left>mdi-rotate-3d-variant</v-icon>
                                {{ loopCounter }}/{{ loopTo }}
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-chip v-if="item.RatingTotal" label class="mr-2" title="Rating Total">
                                <v-icon left>mdi-calculator-variant</v-icon>
                                {{ ratingTotal }}
                            </v-chip>
                            <v-btn text v-if="item.ShowEnd ? !isEnd : index !== this.item.Questions[this.item.Questions.length - 1].Index" :disabled="isSaving" :loading="isSaving" @click="onNext">Next<v-icon>mdi-chevron-right</v-icon></v-btn>
                            <v-btn text v-if="item.ShowEnd ? isEnd : index === $CONST.END_QUESTION || this.isEnd" :disabled="isSaving" :loading="isSaving" @click="onSave">Finish<v-icon>mdi-flag-checkered</v-icon></v-btn>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-0" v-if="this.preview">
            <v-col sm="12">
                <v-container>
                    <v-btn color="primary" @click="onBack">Back</v-btn>
                </v-container>
            </v-col>
        </v-row>
        <v-dialog v-model="showPrimary" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark :disabled="!selectedPrimaryRecord" @click="showPrimary = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Please select</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="onCancel">
                            Cancel
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text v-if="primaryHeaders.length">
                    <v-data-table
                        v-model="selectedPrimaryRecord"
                        :headers="primaryHeaders"
                        :items="primaryData"
                        :loading="loadingPrimaryData"
                        :single-select="true"
                        :search="primarySearch"
                        :custom-filter="filterPrimary"
                        :items-per-page="10000"
                        item-key="_id"
                        show-select
                        hide-default-footer
                    >
                        <template v-slot:top>
                            <v-text-field v-model="primarySearch" label="Search..." class="mx-4"></v-text-field>
                        </template>
                        <template v-slot:item.Values[0]="{ item }">
                            {{ item.Values[0] }}
                        </template>
                    </v-data-table>
                    <!-- <v-simple-table v-if="!loadingPrimaryData" v-model="selectedPrimaryRecord" :loading="loadingPrimaryData">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left" v-for="(label, i) in selectedPrimary.Labels" :key="i">{{ label.Value }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(rec, i) in primaryData" :key="i">
                                <td v-for="(label, li) in selectedPrimary.Labels" :key="li">
                                    {{ rec.Values[li] }}
                                </td>
                            </tr>
                            </tbody>
                            <tfoot v-if="!primaryData.length"><tr><td :colspan="selectedPrimary.Labels.length + 1" class="text-center">No data.</td></tr></tfoot>
                        </template>
                    </v-simple-table> -->
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
// import ImageTools from '@/util/ImageTools';
import ImageResize from '@/util/ImageResize';
// import mask from '@/controls/Mask';
import numeric from '@/controls/Numeric';
// import pdf417 from '@/controls/Pdf417.vue';
// import { decode } from 'base64-arraybuffer';
import { mapState } from 'vuex';
import { Camera, CameraResultType } from '@capacitor/camera';
// import { Geolocation } from '@capacitor/geolocation';
import { Html5QrcodeScanner } from 'html5-qrcode';
import SignaturePad from 'signature_pad';
import Ui from '@/util/Ui';

// const imgTools = new ImageTools();

/*
 * NOTE: Here, Answers are stored as arrays. Non-looping question answers are on index 0
 *       while looping question answers are on the relevant loop index.
 *       The values are extracted on save.
 */

export default {
    name: 'Act',
    components: {
        // 'v-mask': mask,
        'v-numeric': numeric,
        // 'v-pdf-417': pdf417,
    },
    mounted () {
        if (this.$route.query) {
            if (this.$route.query.id) this.item._id = this.$route.query.id;
            if (this.$route.query.preview) this.preview = this.$route.query.preview;
            if (this.$route.query.rejection) this.rejectionId = this.$route.query.rejection;
        }
        this.loadData();
        this.isApple = Ui.isApple();
    },
    /**
     * This is only called if the view has already been initialised, but shown/used again e.g. /User/1 and /User/2
     */
    beforeRouteUpdate (to, from, next) {
        if (this.$route.query) {
            // Reset previous values.
            this.startDate = new Date();
            this.item = { Name: '...', Questions: [Data.duplicate(Constants.BLANK_QUESTION)] };
            if (to.query.id) this.item._id = to.query.id;
            if (to.query.preview) this.preview = to.query.preview;
            this.stepState = [];
            this.isEnd = false;
            this.answerNotValid = false;
            this.errorDetail = '';
            this.loopCounter = 0;
            this.loopTo = 0;
            this.ratingTotal = 0;
            this.index = -1; // this.item.ShowStart ? 0 : this.item.Questions[0].Index;
            this.question = this.item.Questions[0];
        }
        next();
        this.loadData();
    },
    data: () => ({
        isBusy: false,
        isExternal: false,
        lang: 'en',
        item: { Name: '...', Questions: [Data.duplicate(Constants.BLANK_QUESTION)] },
        preview: false,
        modalDate: false,
        modalTime: false,
        modalDOB: false,
        question: { Question: [{ Value: '' }], ShowRules: [], PassRules: [], RatingRules: [], Options: [] },
        questionText: '',
        loopCounter: 0,
        loopTo: 0,
        answerNotValid: false,
        errorDetail: '',
        canSignAgain: false,
        stepState: [], // Previous question indexes for moving backwards.
        index: -1,
        filterOne: '',
        filterMany: '',
        isEnd: false,
        isSaving: false,
        isSaveDone: false,
        startDate: new Date(),
        isLocationOn: false,
        showPrimary: false,
        primaryHeaders: [],
        primaryData: [],
        selectedPrimary: null,
        selectedPrimaryRecord: [],
        loadingPrimaryData: false,
        primarySearch: '',
        defStartMsg: '',
        defEndMsg: '',
        rejectionId: '',
        ratingTotal: 0,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.',
            min: value => (value || '').length > 1 || 'Minimum 2 characters.',
            mobile: value => (value || '').length === 12 || 'Invalid phone number.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail.';
            },
            web: value => {
                const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
                return pattern.test(value) || 'Invalid web address.';
            }
        },
        questionErrors: {},
    }),
    methods: {
        formatValidation (index, format) {
            const item = this.item.Questions.find(o => o._id === index);
            if (format.toLowerCase() === 'caps') {
                const val = item.Answer[this.loopCounter || 0].toUpperCase();
                this.question.Answer[this.loopCounter || 0] = val;
            }
            else if (format.toLowerCase() === 'lower') {
                const val = item.Answer[this.loopCounter || 0].toLowerCase();
                this.question.Answer[this.loopCounter || 0] = val;
            }
        },
        updatedGridPairs (value) {
            this.question.GridData = value;
            this.question.Answer[this.loopCounter || 0] = value;
        },
        updateGridResult (value) {
            this.gridIsValid = value;
        },
        updateSelect (value) {
            this.question.Answer = [value.Value];
        },
        async loadData () {
            if (!this.viewProject || !this.viewProject._id) {
                setTimeout(() => {
                    this.loadData();
                }, 500);
                return;
            }
            if (!this.item._id) {
                this.isBusy = false;
                this.$warning('No Questions', 'There are no questions to preview. Please add questions and make sure the form is saved before previewing.');
                this.onBack();
                return;
            }
            this.isBusy = true;
            try {
                let d;
                if (this.$route.query.preview !== undefined) {
                    d = await this.$db.getSurvey(this.item._id);
                }
                else {
                    d = await this.$db.getPublishedOne(this.item._id);
                }
                if (d === undefined) {
                    const remoteData = await this.$http.get(`/Survey/${this.item._id}`);
                    d = remoteData.data.d;
                }
                this.isValid = true;
                if (!d.Questions.length) {
                    this.isBusy = false;
                    this.$warning('No Questions', 'There are no questions to preview. Please add questions and make sure the form is saved before previewing.');
                    this.onBack();
                }
                Data.sort(d.Questions, 'Index');
                // Check if there are looping questions. Add the parent id to those questions.
                d.Questions.forEach(o => {
                    if (o.LoopParent) {
                        const nums = o.LoopParent.split(/[ ,;]+/);
                        const loopIndexes = [];
                        nums.forEach(num => {
                            if (num.indexOf('-') > -1) {
                                const startEnd = num.split('-');
                                const numStart = +startEnd[0];
                                const numEnd = +startEnd[1];
                                for (let si = numStart; si <= numEnd; si++) {
                                    loopIndexes.push(si);
                                    const q = d.Questions.find(x => x.Index === si);
                                    q.LoopParentIndex = o.Index;
                                }
                            }
                            else {
                                const idx = +num;
                                loopIndexes.push(idx);
                                const q = d.Questions.find(x => x.Index === idx);
                                q.LoopParentIndex = o.Index;
                            }
                        });
                        o.loopStartIndex = loopIndexes[0];
                        o.loopEndIndex = loopIndexes[loopIndexes.length - 1];
                    }
                    else if (o.LoopParent !== undefined) delete o.LoopParent;

                    this.questionErrors[o.Field] = false;
                });
                this.defStartMsg = d.StartMessage;
                this.defEndMsg = d.EndMessage;
                this.item = d;
                this.index = this.item.ShowStart ? 0 : this.item.Questions[0].Index;
                this.question = this.item.Questions[0];

                if (this.rejectionId) {
                    await this.loadRejection();
                }

                if (this.item.ParentId) {
                    this.showPrimary = true;
                    this.loadingPrimaryData = true;
                    this.selectedPrimary = await this.$db.getPrimary(this.item.ParentId);
                    // console.log(this.selectedPrimary);
                    let idx = 0;
                    for (const lbl of this.selectedPrimary.Labels) {
                        this.primaryHeaders.push({ text: lbl.Value, value: `Values.${idx}` });
                        idx += 1;
                    }
                    // this.primaryHeaders.push({ text: 'Record', value: 'Value' });
                    this.primaryData = await this.$db.getPrimaryDatas(this.viewProject._id, this.item.ParentId, async latest => { // Locally stored.
                        this.primaryData = latest;
                        this.loadingPrimaryData = false;
                    });
                }
            }
            catch (ex) {
                console.error(ex);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        async loadRejection () {
            this.rejectedAnswer = await this.$db.getRejection(this.rejectionId);
            const keys = Object.keys(this.rejectedAnswer);
            for (const key of keys) {
                const q = this.item.Questions.find(o => o.Field === key);
                if (q) q.Answer = [this.rejectedAnswer[key]];
            }
        },
        filterPrimary (value, search, item) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
        },
        isBlank (value) {
            return value === '' || value === undefined || value === null;
        },
        showInputType (typeId) {
            if (this.index === 0 || this.index === Constants.END_QUESTION) return 0;
            return typeId === this.question.InputType;
        },
        async onTakePicture () {
            const image = await Camera.getPhoto({
                quality: this.question.Quality,
                allowEditing: false,
                resultType: CameraResultType.DataUrl // DataUrl | Base64 | Uri
                // resultType: CameraResultType.Base64
                // resultType: CameraResultType.Uri
            });

            const sizer = new ImageResize(this.question.Width, this.question.Height, 1);
            const imgSized = await sizer.resize(image);
            // alert('Now', imgSized.length / 1024 / 1024);

            // const imgStr = image.dataUrl; // dataUrl | base64String | webPath
            // alert(imgStr.length);
            /* alert(image.base64String.length);

            const blobTest = new Blob([new Uint8Array(decode(image.base64String))], {
                type: `image/${image.format}`,
            });
            alert('Converted to Blob');

            // const blobTest = await fetch(rec.Answer[key]).then(res => res.blob());
            // const blobTest = await fetch(imgStr).then(res => res.blob());
            let imgSized = null;
            // if (blobTest.size > 1048576) { // If bigger than 1 MB.
            imgSized = await imgTools.resize(blobTest, { width: this.question.Width, height: this.question.Height, isApple: this.isApple }, { mime: 'image/jpeg', quality: 0.9 });
            alert('Resized', imgSized.length / 1024 / 1024); */
            // }
            // else imgSized = imgStr;

            const answer = this.question.Answer;
            // answer[this.question.loopCounter || 0] = imgStr;
            answer[this.question.loopCounter || 0] = imgSized;
            this.question.Answer = this.question.Answer.slice();
        },
        onFileLoadPhotoClick () {
            const fu = this.$refs.fileLoadPhoto.$el.querySelectorAll('input');
            fu[0].click();
        },
        initSignPad () {
            this.canvas = document.getElementById('user-sign');
            if (!this.canvas) {
                setTimeout(this.initSignPad.bind(this), 500); // Retry until the canvas is available.
                return;
            }
            this.signaturePad = new SignaturePad(this.canvas, { backgroundColor: 'rgb(255, 255, 255)' });
            // const answer = this.question.Answer;
            const canvas = this.canvas;
            const signaturePad = this.signaturePad;
            this.signaturePad.addEventListener('beginStroke', () => {
                this.answerNotValid = false;
                this.errorDetail = false;
            });
            this.signaturePad.addEventListener('endStroke', () => {
                const answer = this.question.Answer;
                answer[this.question.loopCounter || 0] = signaturePad.toDataURL('image/jpeg');
            });
            function resizeCanvas () {
                const ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext('2d').scale(ratio, ratio);
                signaturePad.clear(); // otherwise isEmpty() might return incorrect value
            }
            window.addEventListener('resize', resizeCanvas);
            resizeCanvas();
        },
        onSignAgain () {
            this.canSignAgain = false;
            this.initSignPad();
        },
        onSignClear () {
            if (this.canSignAgain) return this.onSignAgain();
            this.signaturePad.clear();
            this.question.Answer[this.question.loopCounter || 0] = '';
            this.$forceUpdate();
        },
        onFileLoadSignClick () {
            const fu = this.$refs.fileLoadSign.$el.querySelectorAll('input');
            fu[0].click();
        },
        async onFileLoadChange (file) {
            /* const reader = new FileReader();
            reader.onload = e => {
                const answer = this.question.Answer;
                answer[this.question.loopCounter || 0] = e.target.result;
                this.question.Answer = this.question.Answer.slice();
                // this.$set(this.question, 'Answer', answer); // Use .$set to trigger the change.
            };
            reader.readAsDataURL(file); // Convert to base64 string. */

            /* let imgSized = null;
            if (file.size > 1048576) { // If bigger than 1 MB.
                imgSized = await imgTools.resize(file, { width: this.question.Width, height: this.question.Height }, { mime: 'image/jpeg', quality: 0.9 });

                const answer = this.question.Answer;
                // answer[this.question.loopCounter || 0] = imgStr;
                answer[this.question.loopCounter || 0] = imgSized;
                this.question.Answer = this.question.Answer.slice();
            }
            else {
                const reader = new FileReader();
                reader.onload = e => {
                    const answer = this.question.Answer;
                    answer[this.question.loopCounter || 0] = e.target.result;
                    this.question.Answer = this.question.Answer.slice();
                    // this.$set(this.question, 'Answer', answer); // Use .$set to trigger the change.
                };
                reader.readAsDataURL(file); // Convert to base64 string.
            } */
            const sizer = new ImageResize(this.question.Width, this.question.Height, 0.9);
            const imgSized = await sizer.resize({
                dataUrl: sizer.fileToDataUrl(file),
                format: file.type
            });
            const answer = this.question.Answer;
            answer[this.question.loopCounter || 0] = imgSized;
            this.question.Answer = this.question.Answer.slice();
        },
        getLocation () {
            const q = this.question;
            this.$set(q, 'Display', 'Locating...');
            this.$forceUpdate();
            const options = {
                enableHighAccuracy: true,
                timeout: (this.question.Timeout || 20) * 1000,
                maximumAge: 10

            };
            if (!q.Meta) q.Meta = [];
            this.startLocationWatch(q, options);

            // TODO: Apply timeout in options.
            /* let location = {};
            // if (Ui.isMac()) {
            location = await Ui.getLocation(options);
            // }
            // else {
            //     location = await Geolocation.getCurrentPosition(options);
            // }

            // console.log('Current position:', location);
            if (!q.Meta) q.Meta = [];
            const meta = q.Meta;
            const answer = q.Answer;
            const idx = q.loopCounter || 0;

            if (!meta[idx]) meta[idx] = {};
            meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
            this.$set(q, 'DisplayLat', meta[idx].ValueLat);
            meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
            this.$set(q, 'DisplayLon', meta[idx].ValueLon);
            meta[idx].ValueAcc = parseFloat(location.coords.accuracy.toFixed(2));
            this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
            meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
            this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
            meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
            this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
            meta[idx].ValueTime = location.timestamp;
            this.$set(q, 'DisplayTime', new Date(meta[idx].ValueTime).toISOString());
            // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
            meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            this.$set(q, 'Display', meta[idx].ValueDisplay);
            this.$forceUpdate();

            // q.Accuracy
            // q.GPSSkip

            // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat. */

            // q.Answer = q.Answer.slice();
            /* navigator.geolocation.getCurrentPosition(location => {
                // q.ValueLat = parseFloat(location.coords.latitude.toFixed(7));
                // q.ValueLon = parseFloat(location.coords.longitude.toFixed(7));
                // q.ValueAcc = location.coords.accuracy;
                // q.ValueAccFail = location.coords.accuracy > q.Accuracy;
                // q.ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
                // // q.ValueSpd
                // q.ValueArr = [q.ValueLon, q.ValueLat]; // Mongo stores ar long then lat.
                // this.$set(q, 'Answer', this.$format.dmsLatLong(q.ValueLat, q.ValueLon)); // Trigger change with $set.
                // Show a map?
                // mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`;
                // mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
                if (!q.Meta) q.Meta = [];
                const meta = q.Meta;
                const answer = q.Answer;
                const idx = q.loopCounter || 0;

                if (!meta[idx]) meta[idx] = {};
                meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
                this.$set(q, 'DisplayLat', meta[idx].ValueLat);
                meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
                this.$set(q, 'DisplayLon', meta[idx].ValueLon);
                meta[idx].ValueAcc = location.coords.accuracy;
                this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
                meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
                this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
                meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
                this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
                // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
                meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
                this.$set(q, 'Display', meta[idx].ValueDisplay);
                this.$forceUpdate();

                // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
                answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
                // q.Answer = q.Answer.slice();
                // this.$set(q, 'Answer', answer); // Use .$set to trigger the change.
            }); */
        },
        startLocationWatch (q, options) {
            const meta = q.Meta;
            const answer = q.Answer;
            const idx = q.loopCounter || 0;
            let attempts = 0;
            if (!meta[idx]) meta[idx] = {};
            this.isLocationOn = true;
            this.bestLocation = {
                latitude: 0,
                longitude: 0,
                accuracy: 10000
            };
            this.locationWatchId = navigator.geolocation.watchPosition(location => {
                attempts += 1;
                this.$set(q, 'DisplayAttempts', attempts);
                if (location.coords.accuracy < this.bestLocation.accuracy) {
                    this.bestLocation = location.coords;
                    this.setLocation(location, q, meta, answer, idx);
                    if (this.bestLocation.accuracy <= (q.Accuracy || 10)) {
                        this.stopLocation();
                    }
                }
            }, err => {
                this.errorDetail = err.message;
                this.stopLocation();
                if (q.Display === 'Locating...') {
                    this.$set(q, 'Display', err.message);
                }
            }, options);
        },
        stopLocation () {
            this.isLocationOn = false;
            navigator.geolocation.clearWatch(this.locationWatchId);
        },
        setLocation (location, q, meta, answer, idx) {
            meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
            this.$set(q, 'DisplayLat', meta[idx].ValueLat);
            meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
            this.$set(q, 'DisplayLon', meta[idx].ValueLon);
            meta[idx].ValueAcc = parseFloat(location.coords.accuracy.toFixed(2));
            this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
            meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
            this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
            meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : 'n/a';
            this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
            meta[idx].ValueTime = location.timestamp;
            this.$set(q, 'DisplayTime', this.$format.dateShortTime(new Date(meta[idx].ValueTime)));
            // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
            meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            this.$set(q, 'Display', meta[idx].ValueDisplay);
            this.$forceUpdate();

            // q.Accuracy
            // q.GPSSkip

            // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
        },
        async onScanBarcode () {
            const qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
                // const minEdgePercentage = 0.7; // 70%
                // const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
                // const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
                const w = Math.floor(viewfinderWidth * 0.8);
                const h = Math.floor(w / 4);
                return {
                    width: w,
                    height: h
                };
            };
            const answer = this.question.Answer;
            const scanner = new Html5QrcodeScanner('qr-reader', { fps: 5, qrbox: qrboxFunction }); // { width: 400, height: 100 }
            scanner.render((decodedText, decodedResult) => { // Success.
                answer[this.question.loopCounter || 0] = decodedText;
                this.$forceUpdate();
                scanner.pause();
                scanner.clear();
            }); // Ignore errors.
        },
        onPrevious () {
            this.answerNotValid = false;
            this.errorDetail = '';
            if (!this.stepState.length) return;
            this.filterOne = '';
            this.filterMany = '';
            // Move.
            const step = this.stepState.pop();
            this.loopCounter = step.counter;
            this.index = step.index;
            // Lets scroll to top
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        onNext () {
            if (this.isLocationOn) this.stopLocation();
            // Validate current question PASS rules.
            let pass = this.runPassRules();
            if (pass && this.question.Field) {
                const hasError = this.questionErrors[this.question.Field];
                if (hasError) pass = false;
            }
            this.answerNotValid = (pass === false);
            if (this.answerNotValid) return;

            if (this.item.Rating && !(this.index === 0 && this.item.ShowStart)) {
                this.runRatingRules();
            }

            this.filterOne = '';
            this.filterMany = '';

            // Keep current index for moving backwards.
            const currentIndex = this.index;
            const currentCounter = this.loopCounter;
            // if (!this.item.ShowEnd && this.index >= Constants.END_QUESTION) return;

            let nextIndex = 0;
            if (pass === true) { // A boolean and not a number.
                if (this.index === this.item.Questions[this.item.Questions.length - 1].Index && this.item.ShowEnd) {
                    // At the end and can show end message.
                    nextIndex = Constants.END_QUESTION;
                }
                else {
                    // Get the next question and validate its SHOW rules.
                    nextIndex = this.getNextQuestionIndex(currentIndex);
                }
            }
            // else nextIndex = pass - 1; // NextId received from `runPassRules`. Display index therefore - 1.
            else nextIndex = pass;
            // Move.
            this.stepState.push({ index: currentIndex, counter: currentCounter });
            this.index = nextIndex;
            // Lets scroll to top
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        /**
         * Runs the pass rules for the current question.
         * Returns boolean or next question index.
         */
        runPassRules () {
            if (this.index === 0 || this.isEnd) return true; // Start and End messages.
            const value = this.makeRuleValue(this.getAnswerValue(this.index));
            const q = this.question;
            let calcOptions = Constants.INPUT_TYPE;
            if (q.InputType === Constants.INPUT_TYPE.Number) {
                if (q.Min !== undefined && q.Min !== '' && value < +q.Min) return false;
                if (q.Max !== undefined && q.Max !== '' && value > +q.Max) return false;
            }
            if (!q.Optional && this.isBlank(value)) {
                // Question is required therefore check that a value exists before continuing.
                return false;
            }
            if (q.InputType === Constants.INPUT_TYPE.SelectOne || q.InputType === Constants.INPUT_TYPE.SelectMany) {
                calcOptions = q.Options;
            }
            if (!q.PassRules.length) {
                return true; // No rules to run.
            }
            const len = q.PassRules.length;
            // Run each rule. Stop at first success.
            let isOk = false;
            for (let i = 0; i < len; i++) {
                const o = q.PassRules[i];
                // Replace @ with the current question value.
                const rule = o.Value.replace(/@/g, value === '' ? "''" : value);
                const pass = Data.calc(rule, this.item.Questions, calcOptions);
                if (pass) return o.NextId || true; // Rule passes, go to the Next index.
                // TODO: !!! the next else
                else if (o.NextId) isOk = true; // Rule failed but is a jump check because it has a Next index. Let it pass.
                else isOk = false; // Rule failed.
            }
            // Rules are done. Even if validation failed, pass because of optional.
            return q.Optional ? true : isOk;
        },
        runRatingRules () {
            const value = this.makeRuleValue(this.getAnswerValue(this.index));
            const q = this.question;
            const len = q.RatingRules && q.RatingRules.length ? q.RatingRules.length : 0;
            if (!len) return true; // No rules to run.
            for (let i = 0; i < len; i++) {
                const o = q.RatingRules[i];
                // Replace @ with the current question value.
                const rule = o.Value.replace(/@/g, value === '' ? "''" : value);
                const match = Data.calc(rule, this.item.Questions, q.Options);
                if (match) {
                    q.Rating = o.Rating;
                    break;
                }
            }
            this.sumRatings();
        },
        sumRatings () {
            let total = 0;
            for (const q of this.item.Questions) {
                if (typeof q.Rating === 'number') {
                    total += q.Rating;
                }
            }
            this.ratingTotal = total;

            // Update the varables in the questions as well as Start and End messages.
            if (this.item.ShowStart) {
                let def = this.defStartMsg;
                if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                this.item.StartMessage = def;
            }
            if (this.item.ShowEnd) {
                let def = this.defEndMsg;
                if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                this.item.EndMessage = def;
            }
        },
        /**
         * Converts a value to be used in the rule engine.
         */
        makeRuleValue (value) {
            // If value is an array, flatten and wrap in [].
            if (Array.isArray(value)) {
                const vt = [];
                const len = value.length;
                for (let j = 0; j < len; j++) {
                    if (Data.isString(value[j])) vt.push(`"${value[j]}"`);
                    else vt.push(value[j]);
                }
                value = `[${vt.join(',')}]`;
            }
            else if (Data.isDate(value)) {
                value = this.$format.dateYMD(value);
            }
            return value;
        },
        /**
         * Finds the next question index.
         * Runs the show rules on it.
         * Recursive.
         */
        getNextQuestionIndex (fromIndex) {
            const current = this.item.Questions.find(o => o.Index === fromIndex);
            if (!current) return this.item.Questions[0].Index;
            const pos = this.item.Questions.indexOf(current);
            const nextPos = pos + 1;
            let q = this.item.Questions[nextPos];
            if (this.loopCounter === 0 && q && q.LoopParentIndex !== undefined) {
                this.loopCounter = 1;
                this.loopTo = current.Answer[0];
            }
            else if (current && current.LoopParentIndex !== undefined) {
                // const parentAnswer = this.getAnswerValue(current.LoopParentIndex);
                const parent = this.item.Questions.find(o => o.Index === current.LoopParentIndex);
                // const parentAnswer = Data.getAnswerValue(parent, Constants.INPUT_TYPE);
                const parentAnswer = parent.Answer[0];
                this.loopTo = parentAnswer;
                if (fromIndex === parent.loopEndIndex) {
                    if (this.loopCounter < parentAnswer) {
                        this.loopCounter += 1; // Increment the loop counter.
                        q = this.item.Questions.find(x => x.Index === parent.loopStartIndex); // Go back to the loop start.
                    }
                    else this.loopCounter = 0;
                }
            }
            if (!q) return Constants.END_QUESTION;
            const nextId = this.runShowRules(q);
            return nextId; // All passed. Go to the next question.
        },
        runShowRules (q) {
            const len = q.ShowRules && q.ShowRules.length ? q.ShowRules.length : 0;
            let returnIndex = q.Index;
            if (!len) return returnIndex || 0; // No rules. Go to the next question.
            // Run each rule. All must pass.
            for (let i = 0; i < len; i++) {
                const o = q.ShowRules[i];
                const rule = o.Value;
                const pass = Data.calc(rule, this.item.Questions, Constants.INPUT_TYPE);
                if (!pass) {
                    returnIndex = this.getNextQuestionIndex(q.Index); // Failure. Step forward to another valid/available question.
                    return returnIndex;
                }
            }
            return returnIndex;
        },
        getAnswerValue (index) {
            const q = this.item.Questions.find(o => o.Index === index);
            // return Data.getAnswerValue(q, Constants.INPUT_TYPE);
            if (!q) return null;
            return q.Answer[q.loopCounter || 0];
        },
        async onSave () {
            this.isSaving = true;
            const answer = {};

            let pass = this.runPassRules();

            if (pass && this.question.Field) {
                const hasError = this.questionErrors[this.question.Field];
                if (hasError) pass = false;
            }
            this.answerNotValid = pass === false;
            if (this.answerNotValid) {
                this.isSaving = false;
                return;
            }

            this.item.Questions.forEach(o => {
                // const v = this.getAnswerValue(o.Index);
                // console.log(o);
                // console.log('|', o.Answer, '|');
                const v = o.LoopParentIndex
                    ? (Array.isArray(o.Answer) ? o.Answer.slice(1) : o.Answer)
                    : (o.Answer ? o.Answer[0] : null);
                if (v !== undefined && v !== null) answer[o.Field] = v;
            });
            if (this.item.Rating) answer.Rating = this.ratingTotal;
            if (this.preview) {
                this.$info('Answers', `<pre>${JSON.stringify(answer, null, 4)}</pre>`, 20);
                this.onBack();
                return;
            }
            if (this.rejectionId) {
                answer._rejectionId = this.rejectionId;
            }

            if (this.selectedPrimaryRecord.length) {
                const prim = this.selectedPrimaryRecord[0];
                answer._PrimaryData = {
                    _id: prim._id,
                    PrimaryId: prim.PrimaryId,
                    Lang: prim.Lang,
                    Values: prim.Values,
                };
            }

            // Create a server compatible answer record.
            const data = {
                ProjectId: this.viewProject._id,
                SurveyId: this.item._id,
                Version: this.item.Version,
                Answer: answer,
                StartDate: this.startDate,
                EndDate: new Date(),
                User: this.user._id,
                _SvrId: '', // Once pushed to the server, the server side record id.
                _ACK: 0 // Once pushed to the server, this flag will be true. Local only. Not saved server side.
            };

            // Store the answer locally first before trying to send it to the server.
            await this.$db.setAnswer(data, this.rejectionId);
            this.isSaveDone = true;
            if (this.item.Exposure === 'I') { // Internal.
                // Success message.
                this.$success('Done', 'Your answer has been saved.');
                // Send to App to action server push.
                this.$root.$emit('SRVY_SubmitAnswers');
                // Go to the dashboard.
                this.$router.push({ name: 'Dashboard' });
            }
            else { // External. Push to server now.
                this.save(data);
            }
        },
        async save (data) {
            try {
                this.isSaving = true;
                const submitUrl = (this.isExternal) ? '/AnswerStaging/submitexternal' : '/AnswerStaging/submit';
                const res = await this.$http.post(submitUrl, data);
                if (res.data.s) {
                    this.isSaveDone = true;
                    this.$success('Done', 'Your answer has been saved.');
                    if (!this.preview && this.item.Exposure === 'I') { // Internal.
                        setTimeout(() => {
                            this.$router.push({ name: 'Dashboard' });
                        }, 1000);
                    }
                }
                else this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isSaving = false;
            }
        },
        onCancel () {
            if (this.showPrimary) this.showPrimary = false;
            this.$router.push({ name: 'Dashboard' });
        },
        onBack () {
            this.$router.go(-1);
        }
    },
    watch: {
        index () { // val
            /* if (this.question.InputType === Constants.INPUT_TYPE.Signature) {
                this.signaturePad.off();
            } */
            const q = this.item.Questions.find(o => o.Index === this.index);
            if (q) {
                if (q.Answer === undefined) {
                    this.$set(q, 'Answer', []);
                }
                if (q.InputType === Constants.INPUT_TYPE.GPSLocation) {
                    if (!q.Meta) q.Meta = [];
                    const idx = this.loopCounter || 0;
                    if (!q.Meta[idx]) {
                        q.Meta[idx] = {};
                        q.Meta[idx].ValueLat = '';
                        q.Meta[idx].ValueLon = '';
                        q.Meta[idx].ValueAcc = '';
                        q.Meta[idx].ValueAccFail = '';
                        q.Meta[idx].ValueAlt = '';
                        q.Meta[idx].ValueArr = '';
                        // q.Meta[idx].ValueSpd = '';
                    }
                    if (!q.DisplayLat) {
                        q.DisplayLat = '-';
                        q.DisplayLon = '-';
                        q.DisplayAcc = '-';
                        q.DisplayAccFail = '-';
                        q.DisplayAlt = '-';
                        q.DisplayArr = '-';
                        q.Display = '-';
                    }
                    else {
                        if (!q.Meta[idx]) q.Meta[idx] = {};
                        q.DisplayLat = q.Meta[idx].ValueLat || '-';
                        q.DisplayLon = q.Meta[idx].ValueLon || '-';
                        q.DisplayAcc = q.Meta[idx].ValueAcc || '-';
                        q.DisplayAccFail = q.Meta[idx].ValueAccFail || '-';
                        q.DisplayAlt = q.Meta[idx].ValueAlt || '-';
                        q.Display = q.Meta[idx].ValueDisplay || '-';
                    }
                }
                q.loopCounter = this.loopCounter; // This is only used in `getAnswerValue`.
                // Defaults.
                if (q.Default !== undefined) {
                    switch (q.InputType) {
                        case Constants.INPUT_TYPE.Date: {
                            if (`${q.Default}`.toUpperCase() === 'TODAY') q.Answer[q.loopCounter] = this.$format.dateYMDDash(new Date());
                            else q.Answer[q.loopCounter] = q.Default;
                            break;
                        }
                        case Constants.INPUT_TYPE.Time: {
                            if (`${q.Default}`.toUpperCase() === 'NOW') q.Answer[q.loopCounter] = this.$format.dateShortTime(new Date().getTime());
                            else q.Answer[q.loopCounter] = q.Default;
                            break;
                        }
                    }
                }
                // Set to be the current question.
                this.question = q;
                // TODO: Handle language selection.
                // TODO: Add previous answer value in question text, similar to rules e.g. {13}.
                this.questionText = this.question.Question[0].Value || '<p>[Question]</p>';
                if (this.questionText.indexOf('%index%') > -1 && q.LoopParentIndex !== undefined) { // Looping question.
                    this.questionText = this.questionText.replace(/%index%/g, q.loopCounter);
                }
                // Default value.
                if (this.question.Default !== undefined && (this.question.Answer[this.question.loopCounter || 0] === undefined || (this.question.Answer[this.question.loopCounter || 0].toString().includes('{') && this.question.Answer[this.question.loopCounter || 0].toString().includes('}')))) {
                    // this.question.Answer[this.question.loopCounter || 0] = this.question.Default;
                    let def = `${this.question.Default}`;
                    if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                    if (def.indexOf('{now}') > -1) def = def.replace(/{now}/g, this.$format.dateShortTime(new Date()));
                    if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                    this.question.Answer[this.question.loopCounter || 0] = def;
                }

                if (q.InputType === Constants.INPUT_TYPE.Image) {
                    if (!q.Width && !q.Height) q.Width = 640;
                    if (!q.Quality) q.Quality = 85;
                }
                if (q.InputType === Constants.INPUT_TYPE.Signature) {
                    if (this.question.Answer[this.question.loopCounter || 0]) {
                        this.canSignAgain = true;
                    }
                    else {
                        this.initSignPad();
                    }
                }
            }
            else this.question = {};
            // this.isEnd = this.item.ShowEnd ? (this.item.Questions.length === 1 || this.index === Constants.END_QUESTION);
            const len = this.item.Questions.length;
            this.isEnd = this.item.ShowEnd ? this.index === Constants.END_QUESTION : (len === 1 || this.item.Questions[len - 1].Index === this.index);
            if (!this.isEnd) {
                const indexId = this.runShowRules(this.question);
                if (this.index !== indexId) this.index = indexId;
            }
        },
        modalDOB (val) {
            val && setTimeout(() => (this.$refs.previewDOB.activePicker = 'YEAR'));
        },
        viewProject () {
            // if (this.item.Exposure === 'I') this.$router.push({ name: 'Dashboard' });
        },
        selectedPrimaryRecord () {
            this.showPrimary = false;
        },
    },
    computed: {
        optionsOne () {
            if (this.question.Filter) {
                return this.question.Options.filter(o => o.Lang === this.lang && o.Value.toLocaleLowerCase().startsWith(this.filterOne.toLocaleLowerCase())).slice(0, 20);
            }
            else {
                return this.question.Options;
            }
        },
        optionsMany () {
            if (this.question.Filter) {
                return this.question.Options.filter(o => o.Lang === this.lang && o.Value.toLocaleLowerCase().startsWith(this.filterMany.toLocaleLowerCase())).slice(0, 20);
            }
            else {
                return this.question.Options;
            }
        },
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        })
    }
};
</script>
