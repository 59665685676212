<template>
    <div>
        <v-img :src="final" class="mt-1" :width="width" :height="height" @click="pop = true"></v-img>
        <v-dialog v-model="pop" :max-width="dialogWidth">
            <v-card>
                <v-card-title class="headline">{{ title }}</v-card-title>
                <v-card-text class="body-1">
                    <v-sheet :color="dropAreaCls" tabindex="0" @dragenter="onDragEnter" @dragleave="onDragLeave" @drop="onDrop" @dragover="onDragOver">
                        <v-file-input ref="fileLoadImage" accept="image/*" label="Load Image" class="d-none" @change="onFileLoadChange"></v-file-input>
                        <vue-croppie
                            ref="croppieRef"
                            :showZoomer="true"
                            :enableResize="false"
                            :enforceBoundary="false"
                            :enableOrientation="true"
                            :viewport="{ width: this.width * this.ratio, height: this.height * this.ratio, type: this.shape }"
                            :boundary="{ width: this.width * this.ratio, height: this.height * this.ratio }">
                        </vue-croppie>
                    </v-sheet>
                    <v-alert v-if="ratio !== 1" dense text border="left" class="mt-3 opa-5" icon="mdi-information-outline">
                        The image appears at a different size here because the system caters for the pixel aspect ratio of various devices.
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-0" color="primary" @click="onFileLoadPhotoClick">
                        <v-icon left>mdi-folder-image</v-icon>
                        Browse
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="onCancel" text>Cancel</v-btn>
                    <v-btn @click="onAccept" color="primary">Accept</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    nane: 'v-image-load-crop-dialog',
    props: {
        value: { type: String, default: '' },
        title: { type: String, default: 'Load Image' },
        width: { type: Number, default: 64 },
        height: { type: Number, default: 64 },
        ratio: { type: Number, default: 2 },
        border: { type: Number, default: 10 },
        shape: { type: String, default: 'square' },
        dialogWidth: { type: Number, default: 800 },
    },
    data () {
        return {
            pop: false,
            final: this.value || this.$CONST.NO_IMAGE,
            dropAreaCls: ''
        };
    },
    methods: {
        // File loaded from the input file control.
        onFileLoadPhotoClick () {
            const fu = this.$refs.fileLoadImage.$el.querySelectorAll('input');
            fu[0].click();
        },
        // Called by input file change. Set it on Croppie.
        onFileLoadChange (file) {
            const reader = new FileReader();
            reader.onload = e => {
                this.$refs.croppieRef.bind({
                    url: e.target.result
                });
            };
            reader.readAsDataURL(file); // Convert to base64 string.
        },
        onDragEnter (evt) {
            evt.preventDefault();
            this.dropAreaCls = 'accent lighten--3';
        },
        onDragLeave (evt) {
            evt.preventDefault();
            this.dropAreaCls = '';
        },
        onDragOver (evt) {
            // Prevent default behavior (prevent file from being opened).
            evt.preventDefault();
            evt.dataTransfer.dropEffect = 'copy';
            this.dropAreaCls = 'accent lighten--3';
        },
        onDrop (evt) {
            // Prevent default behavior (prevent file from being opened).
            evt.preventDefault();
            this.dropAreaCls = '';
            if (evt.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < evt.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them.
                    if (evt.dataTransfer.items[i].kind === 'file') {
                        const file = evt.dataTransfer.items[i].getAsFile();
                        // console.log('... file[' + i + '].name = ' + file.name);
                        this.onFileLoadChange(file);
                        break; // Stop on the first one.
                    }
                }
            }
            else {
                // Use DataTransfer interface to access the file(s)
                /* for (let i = 0; i < evt.dataTransfer.files.length; i++) {
                    // console.log('... file[' + i + '].name = ' + evt.dataTransfer.files[i].name);
                    this.onFileLoadChange(evt.dataTransfer.files[i]);
                    break; // Stop on the first one.
                } */
                if (evt.dataTransfer.files && evt.dataTransfer.files.length) {
                    this.onFileLoadChange(evt.dataTransfer.files[0]);
                }
            }
        },
        onCancel () {
            this.pop = false;
        },
        onAccept () {
            // Return a base64 version of the uploaded image with the specified size.
            const options = {
                type: 'base64',
                size: { width: this.width * this.ratio, height: this.height * this.ratio },
                format: 'png'
            };
            this.$refs.croppieRef.result(options, output => {
                // this.$set(this, 'value', output);
                this.final = output;
                this.$emit('fileData', output);
                this.pop = false;
            });
        }
    },
    watch: {
        value () {
            this.final = this.value;
        }
    }
};
</script>

<style lang="scss">
</style>
