<template>
    <v-container fluid>
        <v-row>
            <v-col sm="4">
                <h1 class="title font-weight-light">
                    Roles ({{ $format.int(pager.total) }})
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="primary" dark v-on="on" @click="onNew"><v-icon>mdi-plus-circle</v-icon></v-btn>
                        </template>
                        <span>Add a new role</span>
                    </v-tooltip>
                </h1>
            </v-col>
            <v-col sm="2">
                <v-btn color="primary" dark small right fab :loading="isBusy" @click="loadData">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
            <v-col sm="6">
                <v-container class="pa-0">
                    <v-row no-gutters>
                        <v-spacer></v-spacer>
                        <v-col sm="auto" justify-self="end">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="toggleSearchAt">
                                        <v-icon>{{ searchAt.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>Match search text at this position</span>
                            </v-tooltip>
                        </v-col>
                        <v-col sm="10">
                            <v-text-field ref="search" v-model="searchText" autofocus dense placeholder="Type here to filter the list" :hint="`(${searchAt.text}) ${searchOnFields.join(', ')}`" prepend-icon="mdi-magnify" persistent-hint clearable @keyup.native="searchChange" @click:clear="searchChange"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card>
                    <v-data-table :headers="headers" :items="tableData" :items-per-page="pager.size" :loading="isBusy" multi-sort hide-default-footer no-data-text="No data." :options.sync="tableOptions" @click:row="onSelect">
                        <template v-slot:item.Name="{ item }">
                            <div class="subtitle-2">{{ item.Name }}</div>
                        </template>
                        <template v-slot:item.Perms="{ item }">
                            <div>{{ getPermissionNames(item.Perms) }}</div>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <div class="el-clearfix pb-1 pr-2">
                        <div class="mt-1 float-left">
                            <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="7" @next="filterData" @previous="filterData" @input="filterData"></v-pagination>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details style="max-width:80px;" @change="filterData"></v-select>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
// import Util from '@/util/Util';

export default {
    name: 'RoleList',
    data: () => ({
        isBusy: false,
        searchText: '',
        searchOptions: Constants.SEARCH_OPTIONS,
        searchOnFields: ['Name'],
        searchAt: {},
        searchAtOptions: Constants.SEARCH_AT_OPTIONS,
        tableOptions: {
            sortBy: ['Name'],
            sortDesc: [false]
        },
        headers: [
            { text: 'Name', value: 'Name', width: '200' },
            { text: 'Permissions', value: 'Perms' }
        ],
        tableData: [],
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 20,
            page: 1,
            pages: 1,
            total: 0
        }
    }),
    mounted () {
        this.searchAt = this.searchAtOptions[2];
        const pageSize = this.$ls.get('Role_PageSize');
        if (pageSize) this.pager.size = parseInt(pageSize, 10);
        this.loadData();
    },
    methods: {
        async loadData () {
            this.isBusy = true;
            try {
                this.tableData = await this.$db.getRoles(this.pager, latest => { // Locally stored.
                    this.tableData = latest; // Refresh from the server.
                });
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        async filterData () {
            this.isBusy = true;
            try {
                if (this.searchText == null) this.searchText = '';
                this.tableData = await this.$db.filterRoles(this.searchText, this.searchAt.value, this.searchOnFields);
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        toggleSearchAt () {
            let pos = this.searchAtOptions.indexOf(this.searchAt);
            pos += 1;
            if (pos === this.searchAtOptions.length) pos = 0;
            this.searchAt = this.searchAtOptions[pos];
            if (this.searchText.trim().length) {
                this.pager.page = 1;
                this.filterData();
            }
            this.$refs.search.focus();
        },
        searchChange () {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.pager.page = 1;
                this.filterData();
            }, 100);
        },
        getPermissionNames (list) {
            if (!list.length) return 'None';
            const perms = Constants.PERMISSION_LIST.filter(o => list.indexOf(o.id) > -1);
            const names = perms.map(o => o.text);
            return names.length ? names.join(', ') : 'None';
        },
        onSelect (item) {
            this.$router.push({ name: 'Role', query: { id: item._id } });
        },
        onNew () {
            this.$router.push({ name: 'Role' });
        }
    },
    watch: {
        tableOptions: {
            handler () {
                if (this.tableData.length) this.filterData();
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.forStatus::before {
    content: "Status";
    color: rgba(0,0,0,.54);
    font-size: 9pt;
    position: absolute;
    margin-top: 35px;
}
</style>
