<template>
    <v-container>
        <!-- <v-progress-linear :active="true" absolute top indeterminate></v-progress-linear> -->
        <v-row>
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    Submissions per Form&nbsp;<span class="font-weight-light subtitle-1">{{ dataDate }}</span>
                    <v-progress-circular v-if="isBusy" class="ml-2" color="primary lighten-1" indeterminate></v-progress-circular>
                    <span class="float-right">
                        <v-menu open-on-hover bottom offset-y nudge-top="-4">
                            <template v-slot:activator="{ on }">
                                <span>
                                    <v-chip small label color="transparent" v-on="on" class="mt-1"><v-icon>mdi-sort-{{ sortIcon }}</v-icon></v-chip>
                                </span>
                            </template>
                            <v-list>
                                <v-list-item v-for="item in sortOptions" :key="item.id" @click="onSortClick(item.id)">
                                    <v-list-item-title><v-icon left>mdi-sort-{{ item.id }}</v-icon>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu open-on-hover bottom offset-y nudge-top="-4">
                            <template v-slot:activator="{ on }">
                                <span class="forStatus">
                                    <v-chip small label color="transparent" v-on="on" class="mt-1"><v-icon>mdi-view-{{ layoutIcon }}</v-icon></v-chip>
                                </span>
                            </template>
                            <v-list>
                                <v-list-item v-for="item in layoutOptions" :key="item.id" @click="onLayoutClick(item.id)">
                                    <v-list-item-title><v-icon left>mdi-view-{{ item.id }}</v-icon>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </span>
                </h1>
            </v-col>
        </v-row>
        <v-row class="mt-0" v-if="noSurveys">
            <v-col sm="12">
                <v-alert prominent type="info">
                    <v-row align="center">
                        <v-col class="grow">
                            No forms to display. Use the form section to create forms that will be displayed here.
                            Please note that the Dashboard is near-live and updated periodically.
                        </v-col>
                        <v-col class="shrink">
                            <v-btn text @click="onNewSurveyClick"><v-icon>mdi-plus</v-icon>Create a Form</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
        <v-row class="mt-0" v-if="!noSurveys">
            <v-col v-if="layoutIcon === 'module-outline'" sm="12" class="d-flex flex-wrap w-1200-max">
                <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" class="el-dash-box">
                    <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color, Count}">
                        <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-1 mr-1 mb-2 pa-3 body-2"><!-- w-250-min w-250-max -->
                            <!-- <div class="ribbon-wrapper-tag"><div class="ribbon-wrapper">LOCK</div></div> -->
                            <v-row class="row-smaller mt-0">
                                <v-col sm="8">
                                    <div class="text-truncate body-2 f-bm cur-default" :title="item.Name">{{ item.Name }}</div>
                                </v-col>
                                <v-col sm="4" class="text-right" align-self="end">
                                    <span class="caption mr-1 opa-5 cur-default">v.{{ item.Version }}</span>
                                    <v-icon class="mb-1 opa-5" medium>{{ item.Icon }}</v-icon>
                                </v-col>
                            </v-row>
                            <v-row class="row-smaller mb-0">
                                <v-divider></v-divider>
                            </v-row>
                            <v-row class="row-smaller mb-0 mt-1">
                                <v-col sm="6">{{ item.Rejected === 0 && item.Review === 0 ? "Total" : "Approved" }}</v-col>
                                <v-col sm="6">
                                    <div class="cur-default d-flex justify-end">{{ $format.int(Count) }}</div>
                                </v-col>
                            </v-row>
                            <v-row class="row-smaller mb-0 mt-1" v-show="item.Rejected > 0">
                                <v-col sm="6">Rejected</v-col>
                                <v-col sm="6">
                                    <div class="cur-default d-flex justify-end">{{ $format.int(item.Rejected) }}</div>
                                </v-col>
                            </v-row>
                            <v-row class="row-smaller mb-0 mt-1" v-show="item.Review > 0">
                                <v-col sm="6">Review</v-col>
                                <v-col sm="6">
                                    <div class="cur-default d-flex justify-end">{{ $format.int(item.Review) }}</div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </vuenime>
                </span>
            </v-col>
            <v-col v-if="layoutIcon === 'module'" sm="12" class="d-flex flex-wrap w-1200-max">
                <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" class="el-dash-box">
                    <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color, Count}">
                        <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-1 mr-1 mb-2 pa-3"><!-- w-250-min w-250-max -->
                            <v-row class="row-smaller mt-0">
                                <v-col sm="10" style="padding-right: 2px !important;">
                                    <div class="text-truncate body-2 opa-8 cur-default" :title="item.Name">{{ item.Name }}</div>
                                </v-col>
                                <v-col sm="2" style="padding-left: 2px !important;" class="text-right">
                                    <span class="caption mr-1 cur-default opa-8">v.{{ item.Version }}</span>
                                </v-col>
                            </v-row>
                            <v-row class="row-smaller mb-0">
                                <v-col sm="8">
                                    <div class="headline cur-default">
                                        {{ $format.int(Count) }}
                                        <v-chip color="success" small label class="pa-2 mr-1" v-show="item.Review > 0">{{ $format.int(item.Review) }}</v-chip>
                                        <v-chip color="error" small label class="pa-2" v-show="item.Rejected > 0">{{ $format.int(item.Rejected) }}</v-chip>
                                    </div>
                                </v-col>
                                <v-col sm="4" class="text-right" align-self="end">
                                    <v-icon class="mb-1" medium>{{ item.Icon }}</v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </vuenime>
                </span>
            </v-col>
            <v-col v-if="layoutIcon === 'sequential'" sm="12" class="d-flex flex-wrap w-1200-max">
                <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" style="width:100%;">
                    <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color, Count}">
                        <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-0 mr-0 mb-2 pa-3">
                            <v-row class="row-smaller mt-0 mb-0">
                                <v-col sm="9">
                                    <div class="text-truncate title cur-default" :title="item.Name">
                                        <v-icon medium left>{{ item.Icon }}</v-icon>
                                        {{ item.Name }}
                                        <span class="caption cur-default">v.{{ item.Version }}</span>
                                    </div>
                                </v-col>
                                <v-col sm="1" class="text-right" align-self="end">
                                    <div class="headline cur-default d-flex flex-nowrap justify-end">
                                        <v-chip color="error" label class="pa-2 mr-1" v-show="item.Rejected > 0">{{ $format.int(item.Rejected) }}</v-chip>
                                    </div>
                                </v-col>
                                <v-col sm="1" class="text-right" align-self="end">
                                    <div class="headline cur-default d-flex flex-nowrap justify-end">
                                        <v-chip color="success" label class="pa-2 mr-1" v-show="item.Review > 0">{{ $format.int(item.Review) }}</v-chip>
                                    </div>
                                </v-col>
                                <v-col sm="1" class="text-right" align-self="end">
                                    <div class="headline cur-default d-flex flex-nowrap justify-end">
                                        {{ $format.int(Count) }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </vuenime>
                </span>
            </v-col>
            <v-col v-if="layoutIcon === 'headline'" sm="12" class="d-flex flex-wrap w-1200-max">
                <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" style="width:100%;">
                    <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color, Count}">
                        <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-0 mr-0 mb-1 pa-1">
                            <v-row class="row-smaller mt-0 mb-0">
                                <v-col sm="9">
                                    <div class="text-truncate body-1 cur-default" :title="item.Name">
                                        <v-icon left>{{ item.Icon }}</v-icon>
                                        {{ item.Name }}
                                        <span class="caption cur-default">v.{{ item.Version }}</span>
                                    </div>
                                </v-col>
                                <v-col sm="1" class="text-right" align-self="end">
                                    <div class="body-1 cur-default d-flex flex-nowrap justify-end">
                                        <v-chip color="error" small label class="pa-2 mr-1" v-show="item.Rejected > 0">{{ $format.int(item.Rejected) }}</v-chip>
                                    </div>
                                </v-col>
                                <v-col sm="1" class="text-right" align-self="end">
                                    <div class="body-1 cur-default d-flex flex-nowrap justify-end">
                                        <v-chip color="success" small label class="pa-2 mr-1" v-show="item.Review > 0">{{ $format.int(item.Review) }}</v-chip>
                                    </div>
                                </v-col>
                                <v-col sm="1" class="text-right" align-self="end">
                                    <div class="body-1 cur-default d-flex flex-nowrap justify-end">
                                        {{ $format.int(Count) }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </vuenime>
                </span>
            </v-col>
        </v-row>
        <!-- <v-row>
            <chart-line></chart-line>
        </v-row> -->
    </v-container>
</template>

<script>
// import Data from '@/util/Data';
import Util from '@/util/Util';
import { Vuenime } from 'vuenime';
import { mapState } from 'vuex';
// import ChartLine from '../../controls/chart/Line.vue';

// let tmr;

export default {
    name: 'Dashboard',
    components: {
        Vuenime,
        // ChartLine,
    },
    mounted () {
        if (!this.viewProject._id) return;
        if (this.preLoadCheck()) return;
        this.sortIcon = this.$ls.set('Dash_Sort') || 'alphabetical-ascending';
        this.layoutIcon = this.$ls.get('Dash_Layout') || 'module-outline';
        this.loadData();
        // Refresh timer.
        this.refreshTmr = setInterval(this.loadData.bind(this), 30000);
    },
    beforeRouteLeave (to, from, next) {
        // Stop the refresh timer when we are not in this view.
        clearInterval(this.refreshTmr);
        next();
    },
    data: () => ({
        isBusy: false,
        noSurveys: false,
        dataDate: '',
        refreshTmr: '',
        sortIcon: 'alphabetical-ascending',
        sortOptions: [
            { id: 'alphabetical-ascending', text: 'Name Ascending' },
            { id: 'alphabetical-descending', text: 'Name Descending' },
            { id: 'numeric-ascending', text: 'Count Ascending' },
            { id: 'numeric-descending', text: 'Count Descending' },
            // { id: 'calendar-ascending', text: '-' },
            // { id: 'calendar-descending', text: '-' },
            // { id: 'clock-ascending-outline', text: 'Newest Submission Top' },
            // { id: 'clock-descending-outline', text: 'Oldest Submission Top' }
        ],
        layoutIcon: 'module-outline',
        layoutOptions: [
            { id: 'module-outline', text: 'Stacked' },
            { id: 'module', text: 'Blocks' },
            { id: 'sequential', text: 'Large' },
            { id: 'headline', text: 'Small' },
        ],
        surveys: []
    }),
    methods: {
        preLoadCheck () {
            if (this.viewProject.DashboardReportId) {
                this.$router.push({ name: 'CustomReport', query: { id: this.viewProject.DashboardReportId } }).catch(() => {}); // Catch same location error.
                return true;
            }
            return false;
        },
        loadData () {
            // if (!this.viewProject._id) return;
            try {
                this.loadDashboard();
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        // Submission count by survey.
        async loadDashboard () {
            if (!this.viewProject._id) return;
            const localRecords = await this.$db.getDashboards(this.viewProject._id, async latest => { // Locally stored.
                if (latest && latest.length) {
                    this.updateDashboard(latest); // There is new or updated data.
                }
            });
            this.updateDashboard(localRecords || []);
            // this.$db.countProjectAnswersToPush(this.viewProject._id);
        },
        updateDashboard (records) {
            // if (!records || !records.length) return;
            records.forEach(o => {
                o.ColorText = Util.getContrast(o.Color); // Get contrast text colour to be able to read the text properly.
            });
            switch (this.sortIcon) {
                case 'alphabetical-ascending': // Name Ascending
                    records.sort((a, b) => {
                        if (a.Name < b.Name) { return -1; }
                        if (a.Name > b.Name) { return 1; }
                        return 0;
                    });
                    break;
                case 'alphabetical-descending': // Name Descending
                    records.sort((a, b) => {
                        if (b.Name < a.Name) { return -1; }
                        if (b.Name > a.Name) { return 1; }
                        return 0;
                    });
                    break;
                case 'numeric-ascending': // Count Ascending
                    records.sort((a, b) => {
                        return a.Count - b.Count;
                    });
                    break;
                case 'numeric-descending': // Count Descending
                    records.sort((a, b) => {
                        return b.Count - a.Count;
                    });
                    break;
                // case 'clock-ascending-outline': // Newest Submission Top
                // case 'clock-descending-outline': // Oldest Submission To
                // UpdateDate
            }
            this.surveys = records;
            this.noSurveys = records.length === 0;
        },
        /* async loadData () {
            clearInterval(tmr);
            if (!this.viewProject._id) return;
            this.isBusy = true;
            try {
                const res = await this.$http.get(`/Dashboard/${this.viewProject._id}/standard`);
                this.dataDate = this.$format.dateTime(new Date());
                const d = res.data.d || []; // UpdateDate
                const animVals = [];
                d.forEach(o => {
                    o.ColorText = Util.getContrast(o.Color);
                    const o2 = Data.duplicate(o);
                    animVals.push(o2);
                    o.Color = '#FFFFFF';
                    o.Count = 0;
                });
                // Data.
                this.noSurveys = d.length === 0;
                if (d.length) {
                    this.$nextTick(() => {
                        this.surveys = animVals;
                    });
                    tmr = setInterval(this.onRefresh.bind(this), 60000);
                }
            }
            catch (ex) {
                if (ex.response && ex.response.data) this.$error(this.$t('general.data_failed'), ex.response.data.m);
                else {
                    console.error(ex.message);
                    this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
                }
            }
            finally {
                this.isBusy = false;
            }
        }, */
        async onRefresh () {
            if (!this.viewProject._id) return;
            if (this.noSurveys) return;
            try {
                this.loadDashboard();
            }
            catch (ex) {
                console.error(ex.message);
            }
        },
        onNewSurveyClick () {
            this.$router.push({ name: 'SurveyEdit' });
        },
        onDetailClick (surveyId) {
            /* if (surveyId === '5efb2907d259db8f27eb5e66') {
                this.$router.push({ name: 'DashboardDetail', query: { id: surveyId } });
            }
            else {
                this.$ls.set('DataMan_Survey', surveyId);
                this.$router.push({ name: 'DataManager' });
            } */

            let route = '';
            if (this.user.has([this.$CONST.PERMISSION.SURVEY_EDIT])) {
                this.$ls.set('Report_Survey', surveyId); // DataMan_Survey
                route = 'DataManager';
                const dash = this.surveys.find(o => o._id === surveyId);
                if (dash && dash.Review && dash.Review > 0) { // Go to approvals screen if there are records to approve.
                    route = 'Approvals';
                }
            }
            else if (this.user.has([this.$CONST.PERMISSION.SURVEY_ACCESS, this.$CONST.PERMISSION.SURVEY_VIEW])) route = `/SurveyAct?id=${surveyId}`;
            if (!route) return;
            this.$router.push({ path: route }).catch(err => {
                // Check if error is for nav to same location, do nothing, otherwise throw the error.
                if (err.name !== 'NavigationDuplicated') throw err;
            });
        },
        onSortClick (id) {
            this.sortIcon = id;
            this.$ls.set('Dash_Sort', id);
            this.updateDashboard(this.surveys);
        },
        onLayoutClick (id) {
            this.layoutIcon = id;
            this.$ls.set('Dash_Layout', id);
        },
    },
    watch: {
        viewProject () {
            this.loadData();
        }
    },
    computed: {
        ...mapState({
            user: 'user',
            viewProject: 'viewProject'
        })
    }
};
</script>

<style lang="scss">
.ribbon-wrapper-tag {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
    opacity: 0.8;
}
.ribbon-wrapper {
    font: bold 8px Sans-Serif;
    color: #FFF;
    text-align: center;
    text-shadow: rgba(255,0,0,0.6) 0px 1px 0px;
    transform: rotate(45deg);
    position: relative;
    padding: 2px 0;
    left: 43px;
    top: 8px;
    width: 56px;
    background-color: var(--v-error-base); // #f56c6c
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--v-error-base)), to(var(--v-error-darken1)));
    background-image: -webkit-linear-gradient(top, var(--v-error-base), var(--v-error-darken1));
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}
.ribbon-wrapper:before, .ribbon-wrapper:after {
    content: "";
    border-top: 3px solid var(--v-error-darken3);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position:absolute;
    bottom: -3px;
}
.ribbon-wrapper:before {
    left: 0;
}
.ribbon-wrapper:after {
    right: 0;
}
</style>
