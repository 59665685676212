<template>
    <v-app>
        <v-app-bar app :color="$vuetify.theme.themes.light.titlebarBack" dark dense clipped-left v-show="authed">
            <v-app-bar-nav-icon :color="$vuetify.theme.themes.light.titlebarText" @click="drawerShow = !drawerShow" />
            <v-btn class="mr-2" :color="`${online ? 'success' : 'error'}`" label text-color="white" @click="$store.dispatch('isOnline')"><v-icon>mdi-signal-variant</v-icon></v-btn>
            <v-select ref="projectSelector" hide-details solo v-model="viewingProjectId" height="34px" :items="projectList" item-text="Name" item-value="_id" class="toolbar-project-select" @change="onProjectChange"></v-select>
            <!-- <v-spacer></v-spacer> -->
            <v-btn small class="error mr-2 ml-4" v-if="hasAppUpdate" @click="onAppUpdate">Update Application</v-btn>
            <span :style="`color:${$vuetify.theme.themes.light.titlebarText}`" class="subtitle-1 mr-2 ml-4 main-user-title">{{ this.user.FullName || 'Guest' }}</span>
            <span class="app-menu-logo ml-2" v-if="logo">
                <v-img :src="logo" :alt="viewProject.Name" :title="`v${version}`"></v-img>
            </span>
            <v-menu left bottom offset-y>
                <template v-slot:activator="{ on }">
                    <span class="app-menu-prog">
                        <v-progress-circular :rotate="270" :size="32" :width="6" :value="submitValue" v-if="submitting" color="accent" ></v-progress-circular>
                        <v-btn :color="$vuetify.theme.themes.light.titlebarText" icon v-on="on" :style="`opacity: ${submitting ? 0.5 : 1};`"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </span>
                </template>
                <v-list>
                    <v-list-item v-for="item in userMenu" :key="item.id" @click="onUserMenuClick(item)" :class="`${item.disabled ? ' opa-5' : ''}`" :disabled="item.disabled">
                        <v-list-item-title><v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <!-- <v-navigation-drawer ref="navDrawer" :value="authed && drawerShow" app clipped hide-overlay persistent v-show="authed" style="background-color:#F5F5F5;"> -->
        <v-navigation-drawer ref="navDrawer" v-model="drawerShow" app clipped hide-overlay persistent v-show="authed" width="280px" style="background-color:#F5F5F5;">
            <v-container fluid dense class="pa-0 menu-pad-bottom"><!-- fill-height -->
                <v-row no-gutters dense class="mb-5">
                    <v-col cols="12">
                        <v-list dense>
                            <template v-for="item in menuItems">
                                <v-row v-if="item.heading" :key="item.idx" align="center" no-gutters>
                                    <v-col cols="12">
                                        <v-subheader v-if="item.heading" :style="`color:${$vuetify.theme.themes.light.menuText}`" class="text-uppercase">{{ item.heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                                <v-divider v-else-if="item.divider" :key="item.idx" dark />
                                <v-list-item v-else :key="item.idx" link :class="`${item.disabled ? ' opa-5' : ''}`" :dark="item.active" :style="`${item.active ? `background-color:${$vuetify.theme.themes.light.menuSelectedBack};color:${$vuetify.theme.themes.light.menuSelectedText};` : ''}`" :disabled="item.disabled" @click="onMenuClick(item)">
                                    <v-list-item-action class="ma-0 mr-5">
                                        <v-badge class="ma-0" color="info" :content="item.counter" :value="item.counter && item.tip" :offset-y="10" :offset-x="10">
                                            <v-icon :color="`${item.active ? $vuetify.theme.themes.light.menuSelectedText : $vuetify.theme.themes.light.menuText}`">{{ item.icon }}</v-icon>
                                        </v-badge>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-tooltip right v-if="item.tip">
                                            <template v-slot:activator="{ on }">
                                                <v-list-item-title class="subtitle-2" v-on="on" :style="menuItemStyle(item.active)">
                                                    {{ item.text }}
                                                </v-list-item-title>
                                            </template>
                                            <span>{{ item.text }}</span>
                                        </v-tooltip>
                                        <v-list-item-title v-else-if="item.counter" class="subtitle-2" :style="menuItemStyle(item.active)">
                                            <v-badge inline :dot="approvalDot" class="ma-0" color="error" :content="item.counter">
                                                {{ item.text }}
                                            </v-badge>
                                        </v-list-item-title>
                                        <v-list-item-title v-else class="subtitle-2" :style="menuItemStyle(item.active)">
                                            {{ item.text }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-navigation-drawer>
        <v-main>
            <transition name="fade">
                <router-view/>
            </transition>
        </v-main>
        <!-- Project Switch -->
        <v-dialog v-model="projectPop" max-width="800">
            <v-card>
                <v-card-title class="headline">Select Project to Context</v-card-title>
                <v-card-text class="pb-0">
                    <v-row class="row-list">
                        <v-col sm="12">
                            <v-text-field v-model="projectText" class="ml-3 mr-3 mb-3" autofocus dense single-line placeholder="Filter text ..." hint="Type to filter the project list" prepend-icon="mdi-magnify" persistent-hint></v-text-field>
                        </v-col>
                    </v-row>
                    <v-simple-table fixed-header height="500">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Project</th>
                                    <th class="text-left">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in projectList" :key="item._id" @click="selectProject(item._id)">
                                    <td>
                                        <div class="font-weight-medium subtitle-1">{{ item.Name }}</div>
                                        <div class="caption opa-5 mt-n2">{{ item.Comment }}</div>
                                    </td>
                                    <td><span :class="`color-circle-s mr-1 ${$getStatusColor(item.StatusId)}`"></span>{{ $getStatusText(item.StatusId) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="projectPop = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Confirm Feedback -->
        <v-dialog v-model="confirm.show" max-width="500">
            <v-card>
                <v-card-title class="headline">{{ confirm.title }}</v-card-title>
                <v-card-text>
                    {{ confirm.text }}
                    <br/>
                    <b>{{ confirm.value }}</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="onConfirm(false)">{{ $t("general.cancel") }}</v-btn>
                    <v-btn color="primary" @click="onConfirm(true)">{{ $t("general.yes") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Prompt Input -->
        <v-dialog v-model="prompt.show" max-width="600">
            <v-card>
                <v-card-title class="headline">{{ prompt.title }}</v-card-title>
                <v-card-text>
                    {{ prompt.text }}
                    <v-radio-group v-if="prompt.show && Array.isArray(prompt.options)" v-model="prompt.value">
                        <v-radio v-for="item in prompt.options" :key="item" :label="item" :value="item"></v-radio>
                    </v-radio-group>
                    <v-text-field v-if="prompt.show && !Array.isArray(prompt.options) && !prompt.isPassword" v-model="prompt.value" @keyup.native="onPromptKey" clearable autofocus></v-text-field>
                    <v-text-field :append-icon="pwdshow ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="pwdshow ? 'text' : 'password'" @click:append="pwdshow = !pwdshow"
                        v-if="prompt.show && !Array.isArray(prompt.options) && prompt.isPassword === true"
                        v-model="prompt.value"
                        @keyup.native="onPromptKey"
                        clearable autofocus></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="onPromptClick(false)">{{ $t("general.cancel") }}</v-btn>
                    <v-btn color="primary" @click="onPromptClick(true)">{{ prompt.okText || $t("general.ok") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Busy -->
        <v-dialog v-model="processing.show" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{ processing.text }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Alert -->
        <v-snackbar
            v-model="snack.show"
            top
            class="global-alert"
            :color="snack.color"
            :multi-line="false"
            :timeout="snack.timeout"
            :vertical="true">
            <div class="title">{{ snack.title }}</div>
            <div class="subheading" v-html="snack.text"></div>
            <template v-slot:action="{ attrs }">
                <div class="d-flex" style="width:100%;">
                    <span class="opa-5 mt-6" v-if="snack.timeoutCounter">{{ snack.timeoutCounter }}s</span>
                    <v-spacer></v-spacer>
                    <v-btn dark class="mt-2" v-bind="attrs" @click="snack.show = false">
                        {{ $t("general.ok") }}
                    </v-btn>
                </div>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import StatusColor from '@/util/StatusColorMixin';
import Util from '@/util/Util';
import Version from './version';
import { mapState } from 'vuex';

// let tmrService;

export default {
    name: 'App',
    mixins: [StatusColor],
    mounted () {
        // Lets first check if we are online
        this.$store.dispatch('isOnline');
        this.version = Version.VERSION;

        // Hook up system wide notification handlers.
        // Listen for notifcation messages.
        this.$root.$on('SRVY_Notification', options => this.globalAlert(options));
        // Listen for confirm messages.
        this.$root.$on('SRVY_Confirm', options => this.globalConfirm(options));
        // Listen for prompt messages.
        this.$root.$on('SRVY_Prompt', options => this.globalPrompt(options));
        // Listen for processing messages.
        this.$root.$on('SRVY_Process', options => this.globalProcess(options));
        // Used to show/hide the side menu.
        this.$root.$on('SRVY_Authed', () => this.authedChanged());
        // This event is received after a form is completed.
        this.$root.$on('SRVY_SubmitAnswers', options => this.submitAnswers(options));
        // Listen for form changes to update the menu with.
        this.$root.$on('SRVY_SurveyUpdate', options => this.surveyUpdate(options));
        // Listen for report changes to update the menu with.
        this.$root.$on('SRVY_ReportUpdate', options => this.reportUpdate(options));

        this.$http.interceptors.request.use(config => {
            const header = config.headers;
            // eslint-disable-next-line no-prototype-builtins
            if (header.hasOwnProperty('common')) {
                // Lets add the auth header if its not found and if it was perhaps persisted
                // eslint-disable-next-line no-prototype-builtins
                if (!header.hasOwnProperty('common').hasOwnProperty('Authorization')) {
                    const storedToken = window.localStorage.getItem('srvy__Token');
                    if (storedToken != null) {
                        const tokenVal = JSON.parse(storedToken).value;
                        if (tokenVal != null) {
                            config.headers.common.Authorization = tokenVal;
                        }
                    }
                }
            }
            this.$ls.set('LastDt', new Date());
            return config;
        },
        error => {
            if (!error.response) {
                console.log('Please check your internet connection.');
                this.$store.dispatch('isOnline');
            }
            return Promise.reject(error);
        });

        this.$http.interceptors.response.use(response => {
            // If error is 401.
            if (response && response.status === 401 && response.config && !response.config.__isRetryRequest) {
                this.$ls.set('User', null);
                this.$ls.set('Token', null);
                this.$store.dispatch('notAuthed');
                this.$store.dispatch('reset');
                this.$router.push({ name: 'SignIn' });
            }
            else return response;
        },
        error => {
            if (!error.response) {
                console.log('Please check your internet connection.');
                this.$store.dispatch('isOnline');
            }
            return Promise.reject(error);
        });
        this.$db.http = this.$http;

        // Check if the user is already signed in.
        const user = this.$ls.get('User');
        if (this.$ls.get('Token') != null) {
            this.$http.defaults.headers.common.Authorization = this.$ls.get('Token');
        }
        if (user) {
            Object.defineProperty(user, 'has', { value: Util.has });
            this.$http.defaults.headers.common.Authorization = this.$ls.get('Token');
            this.$store.commit('user', user);
            this.drawerShow = true;
        }

        // Listen for nav changes to update the menu selected item state.
        this.$router.afterEach((to, _from) => {
            this.selectedPath = to.fullPath;
            this.clearActiveFlag();
            const m = this.menuItems.find(o => o.route === this.selectedPath);
            if (m) m.active = true;
            this.CurrentView = to.name;
        });

        this.submitTmr = setInterval(this.submitAnswers.bind(this), 30000); // Periodically check for unsubmitted answers.
        setTimeout(this.submitAnswers.bind(this), 2000);

        // Listen for an update event from the service worker.
        document.addEventListener('swUpdated', this.appUpdateAvailable, { once: true });
        sessionStorage.setItem('hasAppUpdate', '');

        // Check if the URL is for doing the form only.
        // if (window.location.pathname.endsWith('/Survey')) { // Standalone survey. The form page will validate further.
        if (window.location.search.toLowerCase().startsWith('?survey=')) { // Standalone survey. The form page will validate further.
            this.$store.dispatch('isSingle');
            // this.$router.push({ name: 'Survey' }).catch(() => {}); // Catch same location error.
            this.$router.push({ name: 'Survey', query: { id: this.$route.query.Survey || this.$route.query.survey } }).catch(() => {}); // Catch same location error.
            return;
        }

        // Normal Console, carry on as usual.
        if (user) {
            this.$store.dispatch('isAuthed');
        }
        else {
            this.$router.push({ name: 'SignIn' }).catch(() => {}); // Catch same location error.
        }

        // tmrService = setInterval(this.checkApprovals.bind(this), 60000);
        this.viewingProjectId = this.viewProject._id;
        const elInput = this.$refs.projectSelector.$el.querySelector('div');
        elInput.style.minHeight = '34px';

        this.loadData();
        const hour = 1000 * 60 * 60;
        setInterval(async () => {
            await this.loadRejections();
        }, hour); // Every hour.
    },
    data: () => ({
        pwdshow: false,
        version: '0.0.0',
        hasAppUpdate: false,
        signedIn: true,
        drawerShow: false,
        drawerMenu: false,
        drawerFixed: false,
        viewingProjectId: '',
        projectPop: false, // Context selection.
        projectText: '', // For popup list filtering.
        projectList: [],
        menuGeneral: [],
        menuReports: [],
        menuSurveys: [],
        menuItems: [],
        userMenu: [],
        surveys: [],
        rejections: [],
        isBusy: false,
        submitValue: 0,
        submitting: false,
        approvalDot: false,
        logo: require('./assets/logo.png'),
        snack: {
            show: false,
            color: 'primary',
            timeout: 6000,
            timeoutCounter: 6,
            title: 'Alert',
            text: 'Message',
        },
        confirm: {
            show: false,
            title: 'Confirm',
            text: 'Message',
            id: 0
        },
        prompt: {
            show: false,
            isPassword: false,
            title: 'Input',
            text: 'Message',
            id: 0
        },
        processing: {
            show: false,
            text: 'Please stand by',
        },
    }),
    methods: {
        add (list, idx, text, icon, route, permissions, disabled, tip, counter = 0) {
            if (!permissions.length || this.user.has(permissions)) {
                list.push({ idx, text, icon, route, active: false, disabled, tip, counter });
            }
        },
        addDivider (list) {
            list.push({ divider: true });
        },
        addHeading (list, title) {
            list.push({ heading: title });
        },
        loadMenu () {
            if (!this.user._id) return;
            const P = this.$CONST.PERMISSION;
            // Main menu.
            const list = [];
            const manList = [];
            this.add(list, 'DASH', 'Dashboard', 'mdi-view-grid-outline', '/', [P.SURVEY_ACCESS, P.SURVEY_VIEW, P.REPORTS], !this.online);
            if (this.rejections.length) {
                // mdi-comment-remove-outline
                // mdi-rotate-left
                // mdi-close-octagon-outline
                this.add(list, 'REJECT', 'Rejections', 'mdi-comment-remove-outline', '/Rejections', [P.SURVEY_VIEW], false, false, this.rejections.length);
            }
            // Setup Management navigation Items
            this.add(manList, 'PROJ', 'Category', 'mdi-file-cabinet', '/Projects', [P.GROUP_EDIT], !this.online);
            this.add(manList, 'SURV', 'Forms', 'mdi-book-multiple-outline', '/Surveys', [P.SURVEY_VIEW, P.SURVEY_EDIT], !this.online);
            this.add(manList, 'PRIMARY', 'Primary Lists', 'mdi-file-table-box-multiple-outline', '/PrimaryLists', [P.SURVEY_VIEW, P.SURVEY_EDIT]);
            this.add(manList, 'REPSET', 'Report Setup', 'mdi-post-outline', '/ReportSetup', [P.REPORT_EDIT], !this.online);
            this.add(manList, 'DESIGN', 'Custom Reports', 'mdi-format-textbox', '/CustomReports', [P.REPORT_EDIT], !this.online);
            this.add(manList, 'ROLE', 'Roles', 'mdi-shield-key-outline', '/Roles', [P.ROLE_VIEW, P.ROLE_EDIT], !this.online);
            this.add(manList, 'USER', 'Users', 'mdi-face-woman-shimmer-outline', '/Users', [P.STAFF_VIEW, P.STAFF_EDIT], !this.online);
            this.add(manList, 'DATAM', 'Data Manager', 'mdi-database-outline', '/DataManager', [P.SURVEY_VIEW, P.SURVEY_EDIT], !this.online);
            const hasApprovalSurveys = this.surveys.find(o => o.Approval === true);
            if (hasApprovalSurveys) {
                this.add(manList, 'APPR', 'Approvals', 'mdi-check-all', '/Approvals', [P.SURVEY_APPROVER], !this.online, false, 0);
            }
            this.add(manList, 'WEBH', 'Webhooks', 'mdi-cast', '/Webhooks', [P.WEBHOOK_SETUP], !this.online);
            // this.add(manList, 'EDT', 'Editor', 'mdi-sim', '/Editor', [], false, false, 0);
            this.add(manList, 'REDT', 'RestEditor', 'mdi-sim', '/RestEditor', [P.REST_EDITOR], !this.online);
            this.add(manList, 'BACKUP', 'Backup History', 'mdi-folder-zip-outline', '/BackupList', [P.GROUP_EDIT], !this.online);
            if (manList.length > 0) {
                this.addDivider(list);
                this.addHeading(list, 'Management');
                for (let index = 0; index < manList.length; index++) {
                    const element = manList[index];
                    list.push(element);
                }
            }

            if (this.user.has([P.REPORTS, P.REPORT_EDIT])) {
                this.addDivider(list);
                this.addHeading(list, 'Reporting');
                this.add(list, 'GIS', 'GIS View', 'mdi-map-legend', '/GIS', [P.REPORTS, P.REPORT_EDIT], !this.online);
                this.add(list, 'REP', 'Reports', 'mdi-blur-linear', '/Reports', [P.REPORTS, P.REPORT_EDIT], !this.online);
                this.add(list, 'DOWN', 'Downloads', 'mdi-folder-download-outline', '/Downloads', [P.REPORTS, P.REPORT_EDIT], !this.online);
            }

            this.menuGeneral = list;
            this.menuItems = list;

            // Active menu item.
            const reg = new RegExp(`^${this.$router.currentRoute.path}`, 'i');
            const active = this.menuItems.find(o => reg.test(o.route));
            if (active) active.active = true;
            else this.menuItems[0].active = true;

            // User menu in toolbar.
            this.userMenu = [];
            this.userMenu.push({ id: 'proj-switch', text: 'Switch Category', icon: 'mdi-selection-ellipse-arrow-inside', disabled: !this.online });
            this.userMenu.push({ id: 'profile', text: 'My Profile', icon: 'mdi-account-circle-outline', route: '/Profile', disabled: !this.online });
            this.userMenu.push({ id: 'localdb', text: 'Storage', icon: 'mdi-database-cog-outline', route: '/LocalDatabase', disabled: !this.online });
            if (this.user.has([P.GROUP_EDIT])) this.userMenu.push({ id: 'backup', text: 'Backup Settings', icon: 'mdi-folder-zip-outline', route: '/BackupSettings', disabled: !this.online });
            this.userMenu.push({ id: 'signout', text: 'Sign Out', icon: 'mdi-exit-to-app', route: '/SignOut' });

            this.updateSurveyMenu();
        },
        async loadData () {
            if (!this.user._id) return;
            try {
                await this.loadProjects();
                if (this.viewProject._id) {
                    await this.loadPublished();
                }
                // this.loadMenu();
                // this.updateSurveyMenu();
                await this.loadRejections();

                // await this.$nextTick();
                // this.checkApprovals();
                // setTimeout(() => { this.submitAnswers(); }, 1000); // Check for unsubmitted answers after a dashboard refresh.
            }
            catch (ex) {
                console.error(ex.message);
                // this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        // User projects in the menu to switch.
        async loadProjects () {
            if (!this.user._id) return;
            this.projectList = await this.$db.getProjects('', { page: 1, size: 200 }, async latest => { // Locally stored.
                this.projectList = latest;
                this.loadSetStoreProject();
                this.loadPublished();
            });
            this.loadSetStoreProject();
            this.loadPublished();
            this.loadReports();
        },
        async refreshProjectList () { // Needs to happen in case of project changes.
            this.projectList = await this.$db.getProjects('', { page: 1, size: 200 }, async latest => { // Locally stored.
                this.projectList = latest;
            });
        },
        // Published surveys for the side menu.
        async loadPublished () {
            if (!this.user._id || !this.viewProject._id) return;
            const P = this.$CONST.PERMISSION;
            if (!this.user.has([P.SURVEY_ACCESS, P.SURVEY_VIEW])) return;

            this.surveys = await this.$db.getPublished(this.viewProject._id, '', { page: 1, size: 200 }, async latest => { // Locally stored.
                this.surveys = latest;
                this.loadMenu();
            });
            this.loadMenu();

            this.checkPrimaryLoaded();
        },
        loadSetStoreProject () {
            // Check if the project is already set.
            if (this.viewingProjectId && this.viewProject && this.viewingProjectId === this.viewProject._id) return;

            // Set the context project either from the stored ID or select the first one in the list.
            if (this.projectList.length) {
                const projectId = this.$ls.get('Dash_ProjectId');
                if (projectId) {
                    const p = this.projectList.find(o => o._id === projectId);
                    this.$store.commit('viewProject', p || this.projectList[0]);
                }
                else {
                    this.$store.commit('viewProject', this.projectList[0]);
                }
                this.$nextTick(() => { this.setBranding(); });
            }
        },
        updateSurveyMenu () {
            this.menuSurveys = [];
            if (this.surveys && this.surveys.length) {
                // Get the unsubmitted answer counts.
                // const counts = await this.$db.countProjectAnswersToPush(this.viewProject._id);
                // Add the survey menu items.
                this.addDivider(this.menuSurveys);
                this.addHeading(this.menuSurveys, 'Forms');
                this.surveys.forEach(o => {
                    // Only add active surveys to the menu.
                    if (o.StatusId !== 'A') return;
                    // const cnt = counts.has(o._id) ? counts.get(o._id) : 0;
                    this.add(this.menuSurveys, `SRVY_ACT_${o._id}`, o.Name, o.Icon, `/Survey${o.Form ? 'Form' : 'Act'}?id=${o.SurveyId}`, [], false, true, 0);
                });
            }
            this.menuItems = [...this.menuGeneral, ...this.menuReports, ...this.menuSurveys];
        },
        async loadReports () {
            if (!this.user._id || !this.viewProject._id) return;
            const P = this.$CONST.PERMISSION;
            if (!this.user.has([P.SURVEY_ACCESS, P.SURVEY_VIEW])) return;

            const localRecords = await this.$db.getReports(this.viewProject._id, 'A', { page: 1, size: 50 }, async latest => { // Locally stored.
                this.updateReportsMenu(latest);
            });
            this.updateReportsMenu(localRecords);
        },
        updateReportsMenu (reports) {
            this.menuReports = [];
            if (reports && reports.length) {
                this.addDivider(this.menuReports);
                // this.addHeading(heading, 'Reports');
                reports.forEach(o => {
                    this.add(this.menuReports, `RPT_${o._id}`, o.Title, 'mdi-chart-box-outline', `/CustomReport?id=${o._id}`, [], !this.online, true, 0); // o.Icon
                });
            }
            this.menuItems = [...this.menuGeneral, ...this.menuReports, ...this.menuSurveys];
        },
        async loadRejections () {
            if (!this.user._id) return;
            this.rejections = await this.$db.getRejections(this.pager, latest => { // Locally stored.
                this.rejections = latest; // Refresh from the server.
                this.loadMenu();
            });
            // const res = await this.$http.get('/User/rejections');
            // const d = res.data.d;
            // this.rejections = d;
            if (this.rejections) this.loadMenu();
        },
        async checkPrimaryLoaded () {
            //
        },
        onUserMenuClick (item) {
            this.$store.dispatch('isOnline');
            // if (item.id === 'signout') this.drawerShow = false;
            if (item.route) {
                this.$router.push({ path: item.route });
                return;
            }

            switch (item.id) {
                case 'proj-switch': {
                    this.refreshProjectList();
                    this.projectPop = true;
                    break;
                }
            }
        },
        onMenuClick (data) {
            this.$store.dispatch('isOnline');
            if (data.route) {
                // A hook in mounted checks for nav changes to update the menu highlight.
                this.$router.push({ path: data.route }).catch(err => {
                    // Check if error is for nav to same location, do nothing, otherwise throw the error.
                    if (err.name !== 'NavigationDuplicated') throw err;
                });
            }
        },
        clearActiveFlag () {
            const actives = this.menuItems.filter(o => o.active);
            actives.forEach(o => {
                o.active = false;
            });
        },
        onProjectChange () {
            this.selectProject(this.viewingProjectId);
        },
        selectProject (id) {
            const p = this.projectList.find(o => o._id === id);
            this.$ls.set('Dash_ProjectId', id);
            this.$store.commit('viewProject', p);
            this.projectPop = false;
            this.loadPublished();
            this.$nextTick(() => { this.setBranding(); });
        },
        setBranding () {
            const p = this.viewProject;
            this.logo = p.Logo32 || require('./assets/logo.png');
            this.$db.setSetting({ _id: 'Project', Value: p._id });
            if (p.Branding) {
                this.$vuetify.theme.themes.light.primary = p.ColorPrimary || this.$CONST.THEME.LIGHT.PRIMARY;
                // this.$vuetify.theme.themes.light.secondary = p.ColorSecondary || this.$CONST.THEME.LIGHT.SECONDARY;
                this.$vuetify.theme.themes.light.accent = p.ColorAccent || this.$CONST.THEME.LIGHT.ACCENT;
                this.$vuetify.theme.themes.light.titlebarBack = p.ColorTitlebarBack || this.$CONST.THEME.LIGHT.TITLEBAR_BACK;
                this.$vuetify.theme.themes.light.titlebarText = p.ColorTitlebarText || this.$CONST.THEME.LIGHT.TITLEBAR_TEXT;
                this.$vuetify.theme.themes.light.menuBack = p.ColorMenuBack || this.$CONST.THEME.LIGHT.MENU_BACK;
                this.$vuetify.theme.themes.light.menuText = p.ColorMenuText || this.$CONST.THEME.LIGHT.MENU_TEXT;
                this.$vuetify.theme.themes.light.menuSelectedBack = p.ColorMenuSelectedBack || this.$CONST.THEME.LIGHT.MENU_SELECTED_BACK;
                this.$vuetify.theme.themes.light.menuSelectedText = p.ColorMenuSelectedText || this.$CONST.THEME.LIGHT.MENU_SELECTED_TEXT;
            }
            else {
                // Reset the colours.
                this.$vuetify.theme.themes.light.primary = this.$CONST.THEME.LIGHT.PRIMARY;
                // this.$vuetify.theme.themes.light.secondary = this.$CONST.THEME.LIGHT.SECONDARY;
                this.$vuetify.theme.themes.light.accent = this.$CONST.THEME.LIGHT.ACCENT;
                this.$vuetify.theme.themes.light.titlebarBack = this.$CONST.THEME.LIGHT.TITLEBAR_BACK;
                this.$vuetify.theme.themes.light.titlebarText = this.$CONST.THEME.LIGHT.TITLEBAR_TEXT;
                this.$vuetify.theme.themes.light.menuBack = this.$CONST.THEME.LIGHT.MENU_BACK;
                this.$vuetify.theme.themes.light.menuText = this.$CONST.THEME.LIGHT.MENU_TEXT;
                this.$vuetify.theme.themes.light.menuSelectedBack = this.$CONST.THEME.LIGHT.MENU_SELECTED_BACK;
                this.$vuetify.theme.themes.light.menuSelectedText = this.$CONST.THEME.LIGHT.MENU_SELECTED_TEXT;
            }
        },
        menuItemStyle (active) {
            return active
                ? `color:${this.$vuetify.theme.themes.light.menuSelectedText};`
                : `color:${this.$vuetify.theme.themes.light.menuText};`;
        },
        authedChanged () {
            this.drawerShow = this.authed;
        },
        // A global alert system triggered by this.$alert().
        globalAlert (options) {
            // if (this.tmr) clearInterval(this.tmr);
            if (options.time !== undefined) this.snack.timeout = options.time * 1000;
            this.snack.timeoutCounter = this.snack.timeout / 1000;
            this.snack.title = options.title || 'Alert';
            this.snack.text = options.message || '';
            this.snack.color = options.type || 'info';
            this.snack.show = true;
            if (this.snack.timeoutCounter > 0) {
                this.tmr = setInterval(this.alertHider.bind(this), 1000);
            }
        },
        alertHider () {
            this.snack.timeoutCounter -= 1;
            if (this.snack.timeoutCounter > 0) return;
            clearInterval(this.tmr);
        },
        // A global confirm system triggered by this.$confirm().
        globalConfirm (options) {
            this.confirm.title = options.title || 'Confirm?';
            this.confirm.text = options.message || '';
            this.confirm.value = options.value || '';
            this.confirm.show = true;
        },
        onConfirm (result) {
            this.confirm.show = false;
            this.$root.$emit('SRVY_Confirm_Result', result);
        },
        // A global confirm system triggered by this.$prompt().
        globalPrompt (options) {
            this.prompt.title = options.title || 'Enter value';
            this.prompt.text = options.message || '';
            this.prompt.value = options.value || '';
            this.prompt.options = options.options || null;
            this.prompt.isPassword = options.isPassword || false;
            this.prompt.okText = options.okText || null;
            this.prompt.show = true;
        },
        onPromptKey (evt) {
            if (evt.key === 'Enter') this.onPromptClick(true);
            else if (evt.key === 'Escape') this.onPromptClick(false);
        },
        onPromptClick (result) {
            this.prompt.show = false;
            this.$root.$emit('SRVY_Prompt_Result', { Action: result, Value: this.prompt.value });
        },
        // A global confirm system triggered by this.$prompt().
        globalProcess (options) {
            this.processing.text = options.message || '';
            this.processing.show = options.show !== false;
        },
        // Pushes answers to the server.
        async submitAnswers () {
            if (!this.user._id || !this.viewProject._id) return;
            // Update the menu with the unsubmitted counts.
            const counts = await this.$db.countProjectAnswersToPush(this.viewProject._id);
            if (!counts.size) return; // Nothing to submit.
            counts.forEach((v, k) => {
                const m = this.menuItems.find(m => m.idx === `SRVY_ACT_${k}`);
                if (m) m.counter = v;
            });
            // Do not call `pushAnswers` if it is already busy.
            if (this.submitting) return;
            // If not online, do not attempt to push.
            if (!this.online) return null;
            // Push the answers to the server.
            this.submitValue = 0;
            this.$db.pushAnswers(this.user._id, (err, data) => {
                if (err) this.$error('Submit error', err);
                if (data) {
                    if (data.Progress !== undefined) {
                        if (data.Progress === 0) this.submitting = true; // Make the progress visible.
                        this.submitValue = data.Progress;
                    }
                    if (data.SurveyId) {
                        const m = this.menuItems.find(m => m.idx === `SRVY_ACT_${data.SurveyId}`);
                        if (m) {
                            m.counter -= 1;
                            if (m.counter < 0) m.counter = 0;
                        }
                    }
                    if (data.End) {
                        this.submitValue = 100;
                        setTimeout(() => {
                            this.submitting = false; // Hide the progress.
                        }, 1000);
                    }
                }
            });
        },
        async checkApprovals () {
            // TODO: Fix Roles error.
            /* if (!this.viewProject._id) return;
            try {
                const res = await this.$http.get(`/AnswerStaging/approvecount/${this.viewProject._id}`);
                const item = this.menuItems.find(o => o.idx === 'APPR');
                if (item) {
                    item.counter = res.data.d;
                    // this.menuItems = this.menuItems.slice();
                }
                else {
                    clearInterval(tmrService);
                }
            }
            catch (ex) {
                console.error(ex.message);
            } */
        },
        // A form was updated and fired this message.
        surveyUpdate (survey) {
            this.loadPublished();
            // NOTE: Using published. The following is now redundant.
            /* const id = `SRVY_ACT_${survey._id}`;
            const item = this.menuItems.find(o => o.idx === id);
            if (item) {
                if (survey.StatusId === 'A') {
                    item.text = survey.Name;
                    item.icon = survey.Icon;
                    item.route = `/Survey${survey.Form ? 'Form' : 'Act'}?id=${survey._id}`;
                }
                else this.menuItems.splice(this.menuItems.indexOf(item), 1);
            }
            else {
                // It's a new survey. Reload the list.
                this.loadSurveys();
            } */
        },
        // A report was updated and fired this message.
        reportUpdate () {
            this.loadReports();
        },
        appUpdateAvailable (event) {
            // this.registration = event.detail
            this.hasAppUpdate = true;
            sessionStorage.setItem('hasAppUpdate', 1);
        },
        async onAppUpdate () {
            if (!this.online) {
                this.$warning('Offline', 'You are currently offline and therefore cannot update at this moment.');
                return;
            }
            window.location.reload();
        }
    },
    watch: {
        drawerFixed (val) {
            this.$ls.set('DrawerFixed', val);
        },
        drawerFullText (val) {
            this.$ls.set('DrawerFullText', val);
        },
        async user () {
            if (this.single) return;
            await this.loadData();
        },
        async viewProject () {
            this.viewingProjectId = this.viewProject._id;
            await this.loadData();
        },
        async online () {
            await this.loadData();
            if (!this.online) {
                // Set Route to show you are offline
                this.$router.push({ name: 'Offline' }).catch(() => {});
            }
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject',
            user: 'user',
            authed: 'authed',
            single: 'single',
            online: 'online'
        })
    }
};
</script>

<style lang="sass">
@import '../node_modules/@fontsource/roboto/index.css'
</style>

<style scoped>
.toolbar-project-select {
    flex-shrink: 1;
    /* max-width: 300px; */
    max-height: 34px;
    cursor: default;
}
.menu-pad-bottom {
    margin-bottom: 150px !important;
}
</style>
