<template>
    <v-text-field
        v-model="model"
        mask="###-###-####"
        type="number"
        @change="onChange"
        v-bind="$attrs">
    </v-text-field>

    <!-- <el-input v-if="inputType === 'T'" placeholder="Value" v-model="model" clearable size="medium" v-bind="$attrs" @change="onChange"></el-input>
    <el-input-number v-else-if="inputType === 'N'" v-model="model" controls-position="right" size="medium" class="el-100p" v-bind="$attrs" @change="onChange"></el-input-number>
    <el-radio-group v-else-if="inputType === 'B'" v-model="model" size="medium" v-bind="$attrs" @change="onChange">
        <el-radio-button :label="true">True</el-radio-button>
        <el-radio-button :label="false">False</el-radio-button>
    </el-radio-group>
    <el-date-picker v-else-if="inputType === 'D'" v-model="model" type="datetime" size="medium" placeholder="Select" class="el-100p" v-bind="$attrs" @change="onChange"></el-date-picker> -->
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    name: 'v-mask',
    directives: { mask },
    props: {
        value: null,
        mask: { type: String, default: '###-###-####' }
    },
    data () {
        return {
            model: this.value
        };
    },
    methods: {
        onChange () {
            this.$emit('input', this.model);
            if (this.$listeners.change) this.$listeners.change();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
