<template>
    <v-container fluid>
        <v-row>
            <v-col sm="6">
                <h1 class="title font-weight-light">
                    Report Setup
                </h1>
            </v-col>
            <v-col sm="6">
                <v-select ref="survey" label="Survey" v-model="surveyId" :items="surveyOptions" item-text="Name" item-value="_id" autofocus hide-details @change="onSurveyChange"></v-select>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card :loading="isBusy">
                    <v-container fluid>
                        <div class="el-tbl">
                            <div class="el-tbl-row-title">
                                <div class="el-tbl-col caption el-rptcol-1 justify-center">#</div>
                                <div class="el-tbl-col caption el-rptcol-2 text-uppercase">Field</div>
                                <div class="el-tbl-col caption el-rptcol-3 text-uppercase">Question</div>
                                <div class="el-tbl-col caption el-rptcol-4 text-uppercase">Show</div>
                                <div class="el-tbl-col caption el-rptcol-5 text-uppercase">Filter</div>
                                <div class="el-tbl-col caption el-rptcol-6 text-uppercase">Export As</div>
                            </div>
                            <div class="el-tbl-row" v-for="q in item.Questions" :key="q._id">
                                <div class="el-tbl-col el-rptcol-1 justify-center"><v-chip>{{ q.Index }}</v-chip></div>
                                <div class="el-tbl-col el-rptcol-2"><v-chip label class="subtitle-2" color="primary">{{ q.Field }}</v-chip></div>
                                <div class="el-tbl-col el-rptcol-3">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on" class="el-tbl-question el-text-truncate" v-html="labels[q.Index]"></span>
                                        </template>
                                        <span v-html="labels[q.Index].replace(tagReg, '')"></span>
                                    </v-tooltip>
                                </div>
                                <div class="el-tbl-col el-rptcol-4 justify-center">
                                    <v-checkbox v-model="q.Rep_Show" hide-details></v-checkbox>
                                </div>
                                <div class="el-tbl-col el-rptcol-5 justify-center">
                                    <v-checkbox v-model="q.Rep_Filter" hide-details v-if="canFilter(q)"></v-checkbox>
                                </div>
                                <div class="el-tbl-col el-rptcol-6">
                                    <v-text-field :ref="`F_${q.Index}`" v-model="q.Rep_Field" hide-details solo clearable v-on:keydown="onFieldKey" @focus="$event.target.select()"></v-text-field>
                                </div>
                                <div class="el-tbl-col">
                                    <v-btn text @click="onAuto(q)"><v-icon>mdi-arrow-left-thin</v-icon><span class="hide-sx ml-1">Auto</span></v-btn>
                                </div>
                            </div>
                        </div>
                        <v-alert v-if="failed.length" prominent type="warning" class="ma-2">
                            <v-row align="center">
                                <v-col class="grow">
                                    Some <b>Export As</b> field names are invalid.<br/>
                                    Only <i>alphanumeric</i> values are allowd together with <i>underscore</i> (_) and <i>hyphen</i> (-).<br/>
                                    Please review the following entries and remove all invalid characters:<br/>
                                    <v-chip v-for="o in failed" :key="o" class="mr-1" @click="onFailClick(o)">{{o}}</v-chip>
                                </v-col>
                            </v-row>
                        </v-alert>
                        <v-row class="row-smaller mt-4 mb-2">
                            <v-col sm="12" class="d-flex">
                                <v-btn color="primary" :disabled="!isValid" :loading="isSaving" @click="onSave">Save</v-btn>
                                <v-btn class="ml-3 mr-10" @click="onCancel">Cancel</v-btn>
                                <v-menu bottom right offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text v-on="on" class="ml-10"><v-icon class="mr-2">mdi-auto-fix</v-icon>Auto Fill Export</v-btn>
                                    </template>
                                    <v-btn color="error" @click="onAutoField">
                                        <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                                        Replace all Export As Fields?
                                    </v-btn>
                                </v-menu>
                                <v-spacer></v-spacer>
                                <!-- <v-menu bottom left offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="error" v-show="item._id" :loading="isDeleting">Reset Defaults</v-btn>
                                    </template>
                                    <v-btn color="error" @click="onDelete">
                                        <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                                        Are you sure?
                                    </v-btn>
                                </v-menu> -->
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
// import Util from '@/util/Util';
// import numeric from '@/controls/Numeric';
import { mapState } from 'vuex';

export default {
    name: 'ReportSetup',
    /* components: {
        'v-numeric': numeric
    }, */
    mounted () {
        this.loadLookups();
    },
    data: () => ({
        isBusy: false,
        isSaving: false,
        isDeleting: false,
        tagReg: /<\/?[^>]+(>|$)/g, // Remove tags from string.
        itemId: '',
        surveyId: '',
        surveyOptions: [],
        labels: [],
        item: {},
        itemOrg: {},
        headers: [],
        isValid: false,
        failed: [],
        regInput: /[^a-z\d_-]/i,
        regFinal: /^[a-zA-Z0-9]([a-zA-Z0-9_]*[a-zA-Z0-9])?$/,
        regFinalG: /[^a-zA-Z0-9_]/g
    }),
    methods: {
        async loadLookups () {
            this.isBusy = true;
            try {
                const res = await this.$http.get('/Survey', { params: { where: { ProjectId: this.viewProject._id }, fields: 'Name Version' } });
                this.surveyOptions = res.data.d;
                this.surveyId = this.$ls.get('Report_Survey');
                if (this.surveyId) {
                    // Make sure the id is for the selected project.
                    const survey = this.surveyOptions.find(o => o._id === this.surveyId);
                    if (!survey) this.surveyId = this.surveyOptions[0]._id;
                }
                else {
                    if (this.surveyOptions[0] !== undefined) {
                        this.surveyId = this.surveyOptions[0]._id;
                    }
                    else {
                        this.surveyId = '';
                    }
                }
            }
            catch (ex) {
                // console.log(ex.stack);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.loadData();
            }
        },
        async loadData () {
            // _CONST.PERMISSION.SURVEY_ACCESS, _CONST.PERMISSION.SURVEY_VIEW
            this.isBusy = true;
            if (this.surveyId) {
                try {
                    const res = await this.$http.get(`/Survey/${this.surveyId}`);
                    this.itemOrg = Data.duplicate(res.data.d); // To calculate delta changes.
                    this.labels = [];
                    res.data.d.Questions.forEach(o => {
                        let title = o.Question[0].Value; // TODO: text.
                        if (title.startsWith('<p>')) title = title.substring(3);
                        if (title.endsWith('</p>')) title = title.substring(0, title.length - 4);
                        this.labels[o.Index] = title;
                        if (!o.Rep_Field) {
                            o.Rep_Show = true;
                            o.Rep_Field = o.Field;
                        }
                    });
                    this.item = res.data.d;
                    this.isValid = true;
                }
                catch (ex) {
                    console.error(ex.stack);
                }
                finally {
                    this.isBusy = false;
                }
            }
            else {
                this.isBusy = false;
            }
        },
        canFilter (q) {
            switch (q.InputType) {
                case Constants.INPUT_TYPE.Note:
                case Constants.INPUT_TYPE.GPSLocation:
                case Constants.INPUT_TYPE.Signature:
                case Constants.INPUT_TYPE.Image:
                    return false;
                default: return true;
            }
        },
        onFieldKey (evt) {
            if (this.failed.length) this.failed = [];
            if (this.regInput.test(evt.key)) {
                evt.preventDefault();
                return false;
            }
        },
        onAuto (q) {
            if (this.failed.length) this.failed = [];
            q.Rep_Field = this.labels[q.Index].replace(this.tagReg, '').replaceAll(this.regFinalG, '_').toLowerCase().substring(0, 50);
        },
        onAutoField () {
            if (this.failed.length) this.failed = [];
            this.item.Questions.forEach(o => {
                o.Rep_Field = this.labels[o.Index].replace(this.tagReg, '').replaceAll(this.regFinalG, '_').toLowerCase().substring(0, 50);
            });
        },
        onFailClick (idx) {
            const ctrl = this.$refs[`F_${idx}`];
            ctrl[0].focus();
        },
        onSurveyChange () {
            this.$ls.set('Report_Survey', this.surveyId);
            this.loadData();
        },
        async onSave () {
            this.failed = [];
            this.item.Questions.forEach(o => { // Run regFinal on each Rep_Field.
                if (!this.regFinal.test(o.Rep_Field)) {
                    this.failed.push(o.Index);
                }
            });
            const delta = Data.delta(this.itemOrg, this.item); // Only the changed fields.
            if (delta) {
                try {
                    this.isSaving = true;
                    const res = await this.$http.put(`/Survey/${this.item._id}`, delta);
                    if (res.data.s) {
                        this.$success('Success', 'Saved', 1);
                    }
                    else this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                }
                catch (ex) {
                    this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                }
                finally {
                    this.isSaving = false;
                }
            }
        },
        async onDelete () {
            try {
                this.isDeleting = true;
                const res = await this.$http.delete(`/Survey/${this.surveyId}/answer/${this.itemId}`);
                if (res.data.s) {
                    this.onCancel();
                }
                else this.$error(this.$t('general.delete_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isDeleting = false;
            }
        },
        onCancel () {
            this.$router.go(-1);
        }
    },
    watch: {
        viewProject () {
            this.$router.push({ name: 'DataManager' });
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject'
        })
    }
};
</script>
