import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard/Dashboard.vue';
import DataManager from '../views/DataManager/DataManager.vue';
import Profile from '../views/User/Profile.vue';
import Project from '../views/Project/Project.vue';
import ProjectList from '../views/Project/ProjectList.vue';
import Reports from '../views/Report/Reports.vue';
import ReportSetup from '../views/Report/ReportSetup.vue';
import Role from '../views/Role/Role.vue';
import RoleList from '../views/Role/RoleList.vue';
import SignIn from '../views/User/SignIn.vue';
import SignOut from '../views/User/SignOut.vue';
import SurveyEdit from '../views/Survey/SurveyEdit.vue';
import SurveyList from '../views/Survey/SurveyList.vue';
import Survey from '../views/Survey/Survey.vue';
import SurveyAct from '../views/Survey/Act.vue';
import SurveyForm from '../views/Survey/Form.vue';
import User from '../views/User/User.vue';
import UserList from '../views/User/UserList.vue';
import Offline from '../views/Offline.vue';
import JsonEditor from 'vue-json-edit';
import VueHighlightJS from 'vue-highlightjs';

import Data from '../util/Data';
import middlewarePipeline from './middlewarePipeline';
import guest from './guest';
// import logcall from './logcall';

const ApprovalList = Data.xcopy(DataManager);
ApprovalList.name = 'ApprovalList';

Vue.use(VueRouter);
Vue.use(JsonEditor);
Vue.use(VueHighlightJS);

const routes = [
    // { path: '/', name: 'Ello', component: Ello, meta: { middleware: [guest] } },
    { path: '/', name: 'Dashboard', component: Dashboard, meta: { middleware: [guest] } },
    { path: '/index.html', component: Dashboard }, // Fix for PWA at /index.html for the proxy server redirect.
    { path: '/Approval', name: 'Approval', component: () => import('../views/Approval/Approval.vue'), meta: { middleware: [guest] } },
    { path: '/Approvals', name: 'ApprovalList', component: ApprovalList, meta: { middleware: [guest] } },
    { path: '/BackupList', name: 'BackupList', component: () => import('../views/Backup/BackupList.vue'), meta: { middleware: [guest] } },
    { path: '/BackupSettings', name: 'BackupSettings', component: () => import('../views/Backup/BackupSettings.vue'), meta: { middleware: [guest] } },
    { path: '/BulkFile', name: 'BulkFile', component: () => import('../views/DataManager/BulkFile.vue'), meta: { middleware: [guest] } },
    { path: '/CustomReport', name: 'CustomReport', component: () => import('../views/Report/CustomReport.vue'), meta: { middleware: [guest] } },
    { path: '/CustomReports', name: 'CustomReportList', component: () => import('../views/Report/CustomReportList.vue'), meta: { middleware: [guest] } },
    // { path: '/DashboardDetail', name: 'DashboardDetail', component: () => import('../views/Dashboard/DashboardDetail.vue'), meta: { middleware: [guest] } },
    { path: '/DataEdit', name: 'DataEdit', component: () => import('../views/DataManager/DataEdit.vue'), meta: { middleware: [guest] } },
    { path: '/DataManager', name: 'DataManager', component: DataManager, meta: { middleware: [guest] } },
    { path: '/Downloads', name: 'Downloads', component: () => import('../views/Report/Downloads.vue'), meta: { middleware: [guest] } },
    { path: '/GIS', name: 'GIS', component: () => import('../views/Report/GIS.vue'), meta: { middleware: [guest] } },
    { path: '/LocalDatabase', name: 'LocalDatabase', component: () => import('../views/User/LocalDatabase.vue'), meta: { middleware: [guest] } },
    { path: '/PrimaryData', name: 'PrimaryData', component: () => import('../views/Primary/PrimaryData.vue'), meta: { middleware: [guest] } },
    { path: '/PrimaryLists', name: 'PrimaryLists', component: () => import('../views/Primary/PrimaryList.vue'), meta: { middleware: [guest] } },
    { path: '/Profile', name: 'Profile', component: Profile, meta: { middleware: [guest] } },
    { path: '/Project', name: 'Project', component: Project, meta: { middleware: [guest] } },
    { path: '/Projects', name: 'ProjectList', component: ProjectList, meta: { middleware: [guest] } },
    { path: '/Rejections', name: 'Rejections', component: () => import('../views/DataManager/RejectionsList.vue'), meta: { middleware: [guest] } },
    { path: '/Reports', name: 'Reports', component: Reports, meta: { middleware: [guest] } },
    { path: '/ReportSetup', name: 'ReportSetup', component: ReportSetup, meta: { middleware: [guest] } },
    { path: '/Role', name: 'Role', component: Role, meta: { middleware: [guest] } },
    { path: '/Roles', name: 'RoleList', component: RoleList, meta: { middleware: [guest] } },
    { path: '/SignIn', name: 'SignIn', component: SignIn },
    { path: '/SignOut', name: 'SignOut', component: SignOut, meta: { middleware: [guest] } },
    { path: '/SurveyEdit', name: 'SurveyEdit', component: SurveyEdit, meta: { middleware: [guest] } },
    { path: '/Surveys', name: 'SurveyList', component: SurveyList, meta: { middleware: [guest] } },
    { path: '/Survey', name: 'Survey', component: Survey, meta: { middleware: [guest] } },
    { path: '/SurveyAct', name: 'SurveyAct', component: SurveyAct, meta: { middleware: [guest] } },
    { path: '/SurveyForm', name: 'SurveyForm', component: SurveyForm, meta: { middleware: [guest] } },
    { path: '/User', name: 'User', component: User, meta: { middleware: [guest] } },
    { path: '/Users', name: 'UserList', component: UserList, meta: { middleware: [guest] } },
    // { path: '/Webhook', name: 'Webhook', component: Webhook, meta: { middleware: [guest] } },
    // { path: '/Webhooks', name: 'WebhookList', component: WebhookList, meta: { middleware: [guest] } },
    // { path: '/Editor', name: 'Editor', component: Editor, meta: { middleware: [guest] } },
    { path: '/Offline', name: 'Offline', component: Offline },
    { path: '*', component: () => import('../views/Dashboard/Dashboard.vue') }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
        return next();
    };
    const middleware = to.meta.middleware;
    const context = {
        to,
        from,
        next,
        // store  | You can also pass store as an argument
    };
    return middleware[0]({ ...context, next: middlewarePipeline(context, middleware, 1) });
});

export default router;
