<template>
    <v-menu
        v-model="modalPop"
        :close-on-content-click="false"
        :nudge-right="32"
        :nudge-top="20"
        transition="scale-transition"
        offset-y
        max-height="70%"
        min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field
                ref="field"
                v-model="displayValue"
                v-on:keyup.enter="onEnter"
                :error-messages="errorMessages"
                prepend-icon="mdi-list-box-outline"
                readonly
                v-bind="$attrs"
                v-on="on">
            </v-text-field>
        </template>
        <v-list dense class="pl-2 pr-2">
            <v-list-item dense>
                <component
                    ref="inputBox"
                    :is="filterValueControl"
                    autofocus
                    v-model="addingValue"
                    @change="onChange"
                    @send-value="addValue"
                    @append-click="addValue"
                    @paste="onPaste"
                    append-icon="mdi-plus-circle-outline"
                    v-bind="$props"
                    :inputType="filterValueInput"
                    @focus="$event.target.select()"></component>
                <slot></slot>
                <!-- <v-text-field
                    ref="inputBox"
                    autofocus
                    v-model="addingValue"
                    v-on:keyup.enter="addValue"
                    @click:append="addValue"
                    @paste="onPaste"
                    append-icon="mdi-plus-circle-outline">
                </v-text-field> -->
            </v-list-item>
            <v-list-item dense v-for="(item, index) in model" :key="item">
                {{ item }}
                <v-spacer></v-spacer>
                <!-- <v-icon class="ml-2">mdi-minus-circle-outline</v-icon> -->
                <v-btn icon class="ml-1" @click="removeValue(index)"><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import Constants from '@/util/Constants';
import filterBoolean from '@/controls/filter/Boolean';
import filterDate from '@/controls/filter/Date';
import filterEmail from '@/controls/filter/Email';
import filterMobile from '@/controls/filter/Mobile';
import filterNumber from '@/controls/filter/Number';
import filterText from '@/controls/filter/Text';
import filterTime from '@/controls/filter/Time';
import filterWeb from '@/controls/filter/Web';
import Util from '@/util/Util';

export default {
    name: 'v-filter-list',
    components: {
        'v-filter-boolean': filterBoolean,
        'v-filter-date': filterDate,
        'v-filter-email': filterEmail,
        'v-filter-mobile': filterMobile,
        'v-filter-number': filterNumber,
        'v-filter-text': filterText,
        'v-filter-time': filterTime,
        'v-filter-web': filterWeb,
    },
    props: {
        value: [],
        'error-messages': null,
        inputType: null,
    },
    data () {
        return {
            model: this.value || [],
            modalPop: false,
            addingValue: '',
            displayValue: '',
            filterValueInput: this.inputType || Constants.INPUT_TYPE.Text,
            filterValueControl: 'v-text-field',
        };
    },
    methods: {
        handleChange () {
            this.$emit('change', this.model);
        },
        focus () {
            this.$refs.field.focus();
        },
        onEnter () {
            return this.$emit('enter');
        },
        onChange (value) {
            this.addingValue = value;
        },
        addValue (value) {
            this.addingValue = value;
            this.addValueEx(this.addingValue);
        },
        addValueEx (val) {
            if (val === null || val === undefined || val === '') return;
            val = isNaN(val) ? val : +val;
            if (!this.model) this.model = [];
            if (this.model.indexOf(val) > -1) return;
            this.model.push(val);
            this.displayValue = Util.oneAndMore(this.model);
            setTimeout(() => {
                this.addingValue = '';
                this.$refs.inputBox.focus();
            }, 100);
        },
        removeValue (index) {
            this.model.splice(index, 1);
            this.$refs.inputBox.focus();
            this.handleChange();
        },
        onPaste (evt) {
            const value = evt.clipboardData.getData('text');
            const values = value.indexOf(',') > -1
                ? value.split(',').map(o => o.trim())
                : (value.indexOf('\n') > -1
                    ? value.split('\n').map(o => o.trim())
                    : [value]);
            if (!values.length) return;
            for (const v of values) {
                if (v === '') continue;
                this.addValueEx(v);
            }
            setTimeout(() => {
                this.addingValue = '';
                this.$refs.inputBox.focus();
            }, 100);
        },
        adjustFilterValueInput () {
            this.model = '';
            switch (this.filterValueInput) {
                case Constants.INPUT_TYPE.Note:
                case Constants.INPUT_TYPE.Text:
                case Constants.INPUT_TYPE.Barcode:
                    this.filterValueControl = 'v-filter-text';
                    break;
                case Constants.INPUT_TYPE.Number:
                case Constants.INPUT_TYPE.Rating:
                case Constants.INPUT_TYPE.RangeSlider:
                    this.filterValueControl = 'v-filter-number';
                    break;
                case Constants.INPUT_TYPE.Mobile:
                    this.filterValueControl = 'v-filter-mobile';
                    break;
                case Constants.INPUT_TYPE.Email:
                    this.filterValueControl = 'v-filter-email';
                    break;
                case Constants.INPUT_TYPE.WebAddress:
                    this.filterValueControl = 'v-filter-web';
                    break;
                case Constants.INPUT_TYPE.SelectOne:
                case Constants.INPUT_TYPE.SelectMany:
                    this.filterValueControl = 'v-filter-text';
                    break;
                case Constants.INPUT_TYPE.YesNo:
                    this.filterValueControl = 'v-filter-boolean';
                    break;
                // case Constants.INPUT_TYPE.GPSLocation: // TODO: Coords + find in radius.
                //     this.filterValueControl = 'v-filter-geo';
                //     this.filterValue = '';
                //     break;
                case Constants.INPUT_TYPE.Date:
                case Constants.INPUT_TYPE.DateOfBirth:
                    this.filterValueControl = 'v-filter-date';
                    // this.filterValue = new Date().toISOString().substring(0, 10);
                    break;
                case Constants.INPUT_TYPE.Time:
                    this.filterValueControl = 'v-filter-time';
                    // this.filterValue = new Date().toISOString().substring(11, 19);
                    break;
                case Constants.INPUT_TYPE.User:
                    this.filterValueControl = 'v-filter-text';
                    break;
                default: // Default is plain text input.
                    this.filterValueControl = 'v-filter-text';
                    break;
            }
            console.log('change to ...', this.filterValueControl);
        },
    },
    watch: {
        value () {
            this.model = this.value;
            this.addValue = this.model;
            this.displayValue = Util.oneAndMore(this.model);
        },
        inputType () {
            this.filterValueInput = this.inputType;
            this.adjustFilterValueInput();
        },
        modalPop () {
            if (!this.modalPop) this.handleChange(); // Trigger change on close.
        }
    }
};
</script>
