<template>
    <v-container fluid>
        <v-row>
            <v-col sm="3">
                <h1 class="title font-weight-light">
                    Users ({{ $format.int(pager.total) }})
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="primary" dark v-on="on" @click="onNew"><v-icon>mdi-plus-circle</v-icon></v-btn>
                        </template>
                        <span>Add a new user</span>
                    </v-tooltip>
                </h1>
            </v-col>
            <v-col sm="1">
                <v-btn color="primary" dark small right fab :loading="isBusy" @click="loadData">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
            <v-col sm="2">
                <v-menu open-on-hover bottom offset-y nudge-top="-10">
                    <template v-slot:activator="{ on }">
                        <span class="forStatus">
                            <v-chip label :color="viewStatus.color" text-color="white" v-on="on" class="mt-1"><v-icon left>mdi-filter-variant</v-icon>{{ viewStatus.text }}</v-chip>
                        </span>
                    </template>
                    <v-list>
                        <v-list-item v-for="item in viewStatusOptions" :key="item.id" @click="viewStatusClick(item)">
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col sm="6">
                <v-container class="pa-0">
                    <v-row no-gutters>
                        <v-spacer></v-spacer>
                        <v-col sm="auto" justify-self="end">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="toggleSearchAt">
                                        <v-icon>{{ searchAt.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>Match search text at this position</span>
                            </v-tooltip>
                        </v-col>
                        <v-col sm="10">
                            <v-text-field ref="search" v-model="searchText" dense placeholder="Type here to filter the list" :hint="`(${searchAt.text}) ${searchOnFields.join(', ')}`" prepend-icon="mdi-magnify" persistent-hint clearable autofocus @keyup.native="searchChange" @click:clear="searchChange"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card>
                    <v-data-table :headers="headers" :items="tableData" :loading="isBusy" multi-sort hide-default-footer no-data-text="No data." :options.sync="tableOptions" @click:row="onSelect">
                        <template v-slot:item.Username="{ item }">
                            <div class="subtitle-2 cur-default">{{ item.Username }}</div>
                            <div class="caption opa-5 cur-default" style="line-height:1;">{{ item.LastLogin ? intlFormatDistance(new Date(item.LastLogin), Date.now()) : 'Never logged in' }}</div>
                        </template>
                        <template v-slot:item.FirstName="{ item }">
                            <div class="cur-default">{{ item.FirstName }} {{ item.LastName }}</div>
                            <div class="caption opa-5 cur-default" style="line-height:1;">{{ getRoleNames(item.Roles) }}</div>
                        </template>
                        <template v-slot:item.Mobile="{ item }">
                            <div class="cur-default">{{ item.Mobile }}</div>
                            <div class="caption opa-5 cur-default" style="line-height:1;">{{ item.Email }}</div>
                        </template>
                        <template v-slot:item.StatusId="{ item }">
                            <span :class="`color-circle-s cur-default mr-1 ${$getStatusColorUser(item.StatusId)}`"></span>{{ $getStatusTextUser(item.StatusId) }}
                            <div class="caption opa-5 cur-default" style="line-height:1;">{{ item.StatusId === 'L' && item.LockReason ? item.LockReason : '' }}</div>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <div class="el-clearfix pb-1 pr-2">
                        <div class="mt-1 float-left">
                            <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="7" @next="loadData" @previous="loadData" @input="loadData"></v-pagination>
                        </div>
                        <div class="mt-3 pl-2 float-right">
                            <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details style="max-width:80px;" @change="loadData"></v-select>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { intlFormatDistance } from 'date-fns';
import Constants from '@/util/Constants';
import StatusColor from '@/util/StatusColorMixin';
import Util from '@/util/Util';
import { mapState } from 'vuex';

export default {
    name: 'UserList',
    mixins: [StatusColor],
    data: () => ({
        isBusy: false,
        viewStatus: {},
        viewStatusOptions: Constants.STATUS_USER_FILTER,
        searchText: '',
        searchOptions: Constants.SEARCH_OPTIONS,
        searchOnFields: ['Username', 'FirstName', 'LastName', 'Mobile', 'Tags'],
        searchAt: {},
        searchAtOptions: Constants.SEARCH_AT_OPTIONS,
        tableOptions: {
            sortBy: ['Username'],
            sortDesc: [false]
        },
        headers: [
            { text: 'Username', value: 'Username' },
            { text: 'Name', value: 'FirstName' },
            { text: 'Mobile', value: 'Mobile' },
            { text: 'Status', value: 'StatusId', width: '160px' }
        ],
        tableData: [],
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 20,
            page: 1,
            pages: 1,
            total: 0
        },
        roleOptions: [],
        intlFormatDistance,
    }),
    mounted () {
        this.searchAt = this.searchAtOptions[2];
        const status = this.viewStatusOptions.find(o => o.id === parseInt((this.$ls.get('User_ViewStatus') || '0'), 10));
        this.viewStatus = status || this.viewStatusOptions[0];
        this.loadLookups();
    },
    methods: {
        async loadLookups () {
            this.isBusy = true;
            try {
                const res = await this.$http.get('/Role', { params: { fields: 'Name' } });
                this.roleOptions = res.data.d;
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.loadData();
            }
        },
        async loadData () {
            this.isBusy = true;
            try {
                const parms = Util.buildGetParams({
                    searchText: this.searchText,
                    searchAt: this.searchAt,
                    pager: this.pager,
                    tableOptions: this.tableOptions,
                    searchFields: this.searchOnFields,
                    viewStatusId: this.viewStatus.id
                });
                if (!parms.params.where) parms.params.where = {};
                // parms.params.where.Projects = this.viewProject._id;
                const res = await this.$http.get('/User', parms);
                const d = res.data;
                // Data.
                if (d.d) this.tableData = d.d;
                else this.tableData = [];
                // Pager.
                this.pager = d.p;
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        viewStatusClick (rec) {
            this.viewStatus = rec;
            this.$ls.set('User_ViewStatus', this.viewStatus.text);
            this.pager.page = 1;
            this.loadData();
        },
        toggleSearchAt () {
            let pos = this.searchAtOptions.indexOf(this.searchAt);
            pos += 1;
            if (pos === this.searchAtOptions.length) pos = 0;
            this.searchAt = this.searchAtOptions[pos];
            if (this.searchText.trim().length) {
                this.pager.page = 1;
                this.loadData();
            }
            this.$refs.search.focus();
        },
        searchChange () {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
                this.pager.page = 1;
                this.loadData();
            }, 600);
        },
        onSelect (item) {
            this.$router.push({ name: 'User', query: { id: item._id } });
        },
        onNew () {
            this.$router.push({ name: 'User' });
        },
        getRoleNames (list) {
            const roles = this.roleOptions.filter(o => (list || []).indexOf(o._id) > -1);
            const names = roles.map(o => o.Name);
            return names.length ? names.join(', ') : 'No Roles';
        }
    },
    watch: {
        tableOptions: {
            handler () {
                this.loadData();
            },
            deep: true,
        },
        viewProject () {
            this.loadData();
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject'
        })
    }
};
</script>

<style scoped>
.forStatus::before {
    content: "Status";
    color: rgba(0,0,0,.54);
    font-size: 9pt;
    position: absolute;
    margin-top: 35px;
}
</style>
