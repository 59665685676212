<template>
    <v-btn v-if="canPaste()" v-bind="$attrs" @click="onClick"><v-icon>{{ icon }}</v-icon>{{ value }}</v-btn>
    <v-menu v-else v-model="pop" :close-on-content-click="true" offset-y>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-bind="$attrs"><v-icon>{{ icon }}</v-icon>{{ value }}</v-btn>
        </template>
        <v-sheet class="pa-4 paste-pop-text" color="info" dark>
            <v-text-field value="" @paste="onPaste" autofocus hide-details solo dense width="1px" style="position:absolute;opacity:0.0001;width:1px;height:1px;"></v-text-field>
            Please press <b>{{ ctrlKey }}+V</b> to accept your paste
        </v-sheet>
    </v-menu>
</template>

<script>
import Ui from '@/util/Ui';

export default {
    nane: 'v-btn-paste',
    props: {
        value: { type: String, default: 'Paste' },
        icon: { type: String, default: 'mdi-content-paste' }
    },
    data () {
        return {
            pop: false,
            ctrlKey: Ui.isMac() ? 'Cmd' : 'Ctrl'
        };
    },
    methods: {
        canPaste () {
            return Ui.canPaste();
        },
        onClick () {
            Ui.clipboardGet(null).then(value => {
                this.$emit('paste', value);
            }).catch(ex => {
                this.$error('Paste Error', ex.message);
            });
        },
        onPaste (evt) {
            const data = evt.clipboardData.getData('text/plain');
            this.pop = false;
            this.$emit('paste', data);
        }
    }
};
</script>

<style lang="scss" scoped>
.paste-pop-text,
.paste-pop-text .v-input__slot,
.paste-pop-text .v-text-field__slot {
    cursor: default !important;
}
</style>
