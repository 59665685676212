<template>
    <v-text-field
        ref="field"
        v-model="model"
        type="number"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange"
        @keydown.native="onKeyDown"
        @keyup="onKeyUp"
        @paste="onPaste"
        @click:append="onAppendClick"
        @click:prepend="onPrepend"
        @click:append-outer="onAppendOuter"
        :error-messages="errorMessages"
        v-bind="$attrs">
    </v-text-field>
</template>

<script>
const allowedKeys = ['Enter', 'Backspace', 'Tab', 'Home', 'End', 'Delete', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '.'];
const integerList = ['int', 'currencyInt', 'intZeroDash', 'intZeroEmpty'];

export default {
    name: 'v-numeric',
    props: {
        value: null,
        'error-messages': null,
        allowNegative: { type: Boolean, default: false },
        alwaysShowValue: { type: Boolean, default: true }, // Will display the placeholder when the value is blank instead of a 0
        numberType: { type: String, default: 'int' } // number, currency, currencyInt, int, intZeroDash, intZeroEmpty, km
    },
    emits: ['input', 'enter', 'paste', 'outer', 'prepend', 'append-click', 'send-value'],
    data () {
        return {
            numberValue: this.value,
            model: this.value,
            numberFormatType: this.numberType
        };
    },
    methods: {
        focus () {
            this.$refs.field.focus();
        },
        onFocus () {
            this.updateModel();
        },
        onBlur () {
            if (this.$listeners.blur) this.$listeners.blur();
            if (this.model === '' && !this.alwaysShowValue) return;
            this.format();
        },
        onKeyDown (evt) {
            // const code = evt.keyCode || evt.which;
            const key = evt.key;
            // if ((evt.ctrlKey || evt.metaKey) && code === 65) { // Select all.
            if ((evt.ctrlKey || evt.metaKey) && key === 'a') { // Select all.
                return;
            }
            // if (allowedKeys.indexOf(code) === -1) { // Only allowed keys can pass.
            if (allowedKeys.indexOf(key) === -1) { // Only allowed keys can pass.
                evt.preventDefault();
            }
            else if (key === 'Enter') {
                this.$emit('send-value', this.model);
                return this.$emit('enter');
            }
            // else if ((code === 109 || code === 189) && !this.allowNegative) evt.preventDefault();
            // else if ((code === 110 || code === 190) && integerList.indexOf(this.numberType) === -1) evt.preventDefault();
            else if (key === '-' && !this.allowNegative) evt.preventDefault();
            else if (key === '.') {
                if (integerList.indexOf(this.numberFormatType) !== -1 || `${this.model}`.indexOf('.') > -1) evt.preventDefault();
            }
        },
        onKeyUp () {
            if (this.model === '' && !this.alwaysShowValue) {
                this.numberValue = '';
                return;
            }
            this.numberValue = Math.round(this.model * 100) / 100;
        },
        onChange () {
            if (this.$listeners.change) this.$listeners.change();
        },
        /* updateNumberValue () {
            // let v = this.model;
            let parsed;
            // v = v.replace(this.thousandsSeparatorRegex, '');
            // if (this.decimalSeparator !== '.') v = v.replace(this.decimalSeparatorRegex, this.thousandsSeparator);
            const result = tryParseFloat(this.model);
            if (!result) parsed = 0;
            else parsed = result;
            if (!this.allowNegative && result < 0) parsed = 0;
            this.numberValue = Math.round(parsed * 100) / 100;
        }, */
        updateModel () {
            if (this.numberValue === null) return;
            if (this.model === '' && !this.alwaysShowValue) {
                this.numberValue = '';
                return;
            }
            // let v = this.numberValue.toString();
            // v = v.replace(this.thousandsSeparatorRegex, this.decimalSeparator);
            // this.model = v;
            this.model = this.numberValue;
            const field = this.$refs.field.$el.querySelectorAll('input');
            if (field) {
                this.$nextTick(() => {
                    field[0].select();
                });
            }
        },
        format () {
            if (this.numberValue === null) return;
            if (this.model === '' && !this.alwaysShowValue) return;
            // let v = this.numberValue;
            // v = v.toLocaleString(this.languageCode);
            // if (v.length !== 1 && v.slice(v.indexOf(this.decimalSeparator) + 1).length === 1) v += '0';
            this.model = this.$format[this.numberType].call(this, this.numberValue);
        },
        onPaste (evt) {
            this.$emit('paste', evt);
        },
        onAppendClick () {
            return this.$emit('append-click', this.model);
        },
        onPrepend () {
            return this.$emit('prepend', this.model);
        },
        onAppendOuter () {
            return this.$emit('outer', this.model);
        },
    },
    watch: {
        numberValue (v) {
            this.$emit('input', v);
        },
        value (v) {
            this.model = v;
            this.numberValue = v;
            if (!this.$refs.field.isFocused) {
                this.format();
            }
        }
    },
    created () {
        this.format();
    }
};
</script>
