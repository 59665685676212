import Vue from 'vue';

Vue.mixin({
    methods: {
        // App is listeting for the JMP_Notification. Will display an alert/snackbar with the options.
        $alert (options) {
            this.$root.$emit('SRVY_Notification', options);
        },
        $info (title, message, time) {
            this.$root.$emit('SRVY_Notification', { type: 'info', title, message, time });
        },
        $success (title, message, time) {
            this.$root.$emit('SRVY_Notification', { type: 'success', title, message, time });
        },
        $warning (title, message, time) {
            this.$root.$emit('SRVY_Notification', { type: 'warning', title, message, time });
        },
        $error (title, message, time) {
            this.$root.$emit('SRVY_Notification', { type: 'error', title, message, time });
        },
        $confirm (options, done) {
            this.$root.$emit('SRVY_Confirm', options);
            this.$root.$once('SRVY_Confirm_Result', done);
        },
        $prompt (options, done) {
            this.$root.$emit('SRVY_Prompt', options);
            this.$root.$once('SRVY_Prompt_Result', done);
        },
        $process (options) {
            this.$root.$emit('SRVY_Process', options);
        },
        $submitAnswers (options) {
            this.$root.$emit('SRVY_SubmitAnswers', options);
        }
    }
});
