import Constants from './Constants';

export default {
    methods: {
        $getStatusColor (id) {
            const status = Constants.STATUS.find(o => o.id === id);
            return status ? status.color : 'primary';
        },
        $getStatusText (id) {
            const status = Constants.STATUS.find(o => o.id === id);
            return status ? status.text : 'Unknown';
        },
        $getStatusColorUser (id) {
            const status = Constants.STATUS_USER.find(o => o.id === id);
            return status ? status.color : 'primary';
        },
        $getStatusTextUser (id) {
            const status = Constants.STATUS_USER.find(o => o.id === id);
            return status ? status.text : 'Unknown';
        },
        $getStatusColorBackup (id) {
            const status = Constants.STATUS_BACKUP_FILTER.find(o => o.id === id);
            return status ? status.color : 'primary';
        },
        $getStatusTextBackup (id) {
            const status = Constants.STATUS_BACKUP_FILTER.find(o => o.id === id);
            return status ? status.text : 'Unknown';
        },
    }
};
