<template>
    <v-container>
        <v-row>
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    <v-icon @click="onCancel">mdi-arrow-left-circle</v-icon>
                    User Detail
                    <span :class="`color-circle-s ml-1 ${$getStatusColorUser(item.StatusId)}`"></span>
                    <span class="subtitle-1 ml-2 primary--text">{{item.FirstName}} {{item.LastName}}</span>
                </h1>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card class="w-700" :loading="isBusy">
                    <v-container>
                        <v-tabs>
                            <v-tab><v-badge color="error" dot :value="!isValidDetail">Detail</v-badge></v-tab>
                            <v-tab v-show="canSecurity"><v-badge color="error" dot :value="!isValidSecurity">Security</v-badge></v-tab>
                            <v-tab v-show="canRoles"><v-badge color="error" dot :value="!isValidRoles">Roles</v-badge></v-tab>
                            <v-tab v-show="canProjects"><v-badge color="error" dot :value="!isValidProjects">Projects</v-badge></v-tab>
                            <!-- Detail -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-form ref="formDetail" v-model="isValidDetail">
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-text-field label="First Name" v-model="item.FirstName" autofocus max-length="50" counter="50"></v-text-field>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="Last Name" v-model="item.LastName" max-length="50" counter="50"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-text-field
                                                ref="email"
                                                label="Email"
                                                v-model="item.Email"
                                                max-length="100"
                                                counter="100"
                                                clearable
                                                :loading="isEmailBusy"
                                                :error="emailError"
                                                :error-messages="emailErrors"
                                                :rules="[rules.required, rules.email, rules.availEmail]"
                                                @blur="onEmailBlur">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-text-field label="Mobile" v-model="item.Mobile" max-length="10" counter="10"></v-text-field>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="Last Login" v-model="item.LastLoginString" disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-select label="Tags (Setup in Project)" v-model="item.Tags" :items="tagsOptions" item-text="Name" item-value="_id" multiple>
                                                <template v-slot:prepend-item>
                                                    <v-list-item ripple @click="toggleTags">
                                                        <v-list-item-action>
                                                            <v-icon :color="item.Tags.length > 0 ? 'primary darken-4' : ''">{{ tagIcon }}</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Select All</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-select ref="status" label="Status" v-model="item.StatusId" :items="statusOptions" item-text="text" item-value="id" :rules="[rules.required]"></v-select>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field label="Reason" v-model="item.LockReason" hint="Reason for locked account" max-length="100" counter="100" persistent-hint v-if="item.StatusId === 'L'"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                            <!-- Security -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-form ref="formSecurity" v-model="isValidSecurity">
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-text-field
                                                label="Username"
                                                v-model="item.Username"
                                                hint="Login username"
                                                max-length="40"
                                                counter="40"
                                                :rules="[rules.usermin, rules.required, rules.availUsername]"
                                                :disabled="hasUsername"
                                                :loading="isUsernameBusy"
                                                :error="usernameError"
                                                :error-messages="usernameErrors"
                                                persistent-hint
                                                clearable
                                                required
                                                @blur="onUsernameBlur">
                                            </v-text-field>
                                        </v-col>
                                        <v-col sm="6" class="text-right">
                                            <v-switch v-model="item.PassSet" label="User to set new password" hint="User must set new password on login" persistent-hint></v-switch>
                                        </v-col>
                                        <v-col sm="6" class="text-right" align-self="center">
                                            <v-btn class="mt-2" :loading="isPasswordSaving" @click="onPassword">Set Password</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                            <!-- Roles -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-form ref="formRoles" v-model="isValidRoles">
                                    <v-row class="row-smaller" v-if="!roleOptions.length">
                                        <v-col sm="12">
                                            <div title class="mt-4 bt-4">Please create a role in the Roles section.</div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller" v-if="roleOptions.length">
                                        <v-col sm="12" style="padding-bottom:8px !important">
                                            <v-checkbox
                                                v-model="item.Roles"
                                                v-for="o in roleOptions"
                                                :key="o._id"
                                                :label="o.Name"
                                                :value="o._id"
                                                class="mt-1"
                                                multiple
                                                hide-details>
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                            <!-- Projects -->
                            <v-tab-item class="mt-3 mb-3">
                                <v-form ref="formProjects" v-model="isValidProjects">
                                    <v-row class="row-smaller" v-if="!projectOptions.length">
                                        <v-col sm="12">
                                            <div title class="mt-4 bt-4">Please create a project in the Projects section.</div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller" v-if="projectOptions.length">
                                        <v-col sm="12" style="max-height:800px; overflow-y:auto; padding-bottom:8px !important">
                                            <v-checkbox
                                                v-model="item.Projects"
                                                v-for="o in projectOptions"
                                                :key="o._id"
                                                :label="o.Name"
                                                :value="o._id"
                                                class="mt-1"
                                                multiple
                                                hide-details>
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller mt-3">
                                        <v-col sm="12">
                                            <v-btn text @click="onAll">
                                                <v-icon class="mr-2">mdi-select-all</v-icon>
                                                Select All
                                            </v-btn>
                                            <v-btn text @click="onNone">
                                                <v-icon class="mr-2">mdi-select-off</v-icon>
                                                Deselect All
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-tab-item>
                        </v-tabs>
                        <v-row class="row-smaller mt-4 mb-2">
                            <v-col sm="12" class="d-flex">
                                <v-btn color="primary" :disabled="!isValidDetail || !isValidSecurity || !isValidRoles || !isValidProjects" :loading="isSaving" @click="onSave">Save</v-btn>
                                <v-btn class="ml-3" @click="onCancel">Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-menu bottom left offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="error" v-show="item._id" :loading="isDeleting">Delete</v-btn>
                                    </template>
                                    <v-btn color="error" @click="onDelete">
                                        <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                                        Are you sure?
                                    </v-btn>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import StatusColor from '@/util/StatusColorMixin';
import { mapState } from 'vuex';

let isEmailGood = false;
let isUsernameGood = false;

export default {
    name: 'User',
    mixins: [StatusColor],
    mounted () {
        this.item.Languages = ['en'];
        this.item.UpdateDate = new Date();
        this.item.UpdateDateString = this.$format.dateShortTime(this.item.UpdateDate);

        // What to display and not.
        const P = this.$CONST.PERMISSION;
        this.canSecurity = this.user.has([P.STAFF_PASSWORD]);
        this.canRoles = this.user.has([P.STAFF_ROLES]);
        this.canProjects = this.user.has([P.STAFF_PROJECTS]);

        this.isValidDetail = false;
        this.isValidSecurity = !this.canSecurity;
        this.isValidRoles = !this.canRoles;
        this.isValidProjects = !this.canProjects;

        if (this.$route.query && this.$route.query.id) this.item._id = this.$route.query.id;
        this.loadLookups();
    },
    data: () => ({
        isBusy: false,
        isSaving: false,
        isDeleting: false,
        isPasswordSaving: false,
        isEmailBusy: false,
        isUsernameBusy: false,
        item: { // Defined the whole object otherwise the properties are not reactive.
            Username: '',
            Password: '',
            FirstName: '',
            LastName: '',
            Email: '',
            Mobile: '',
            StatusId: 'A',
            LockReason: '',
            PassSet: true,
            Tags: [],
            LastLogin: ''
        },
        itemOrg: {},
        statusOptions: Constants.STATUS_USER,
        roleOptions: [],
        projectOptions: [],
        tagsOptions: [],
        hasUsername: false,
        isValidDetail: false,
        isValidSecurity: false,
        isValidRoles: true,
        isValidProjects: true,
        emailError: null,
        emailErrors: null,
        usernameError: null,
        usernameErrors: null,
        canSecurity: false,
        canRoles: false,
        canProjects: false,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.',
            min: value => (value || '').length > 1 || 'Minimum 2 characters',
            usermin: value => (value || '').length > 5 || 'Minimum 6 characters',
            startChar: value => isNaN((value || '')[0]) || 'Must start with a character',
            availEmail: value => isEmailGood || 'Email validation required.',
            availUsername: value => isUsernameGood || 'Username validation required.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail.';
            }
        }
    }),
    methods: {
        async loadLookups () {
            this.isBusy = true;
            try {
                const res = await this.$http.get('/Role', { params: { fields: 'Name' } });
                this.roleOptions = res.data.d;
                const res2 = await this.$http.get('/Project', { params: { fields: 'Name StatusId' } });
                this.projectOptions = Data.sort(res2.data.d, 'Name');
                const res3 = await this.$http.get(`/Project/${this.viewProject._id}`, { params: { fields: 'Tags' } });
                this.tagsOptions = res3.data.d.Tags;
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.loadData();
            }
        },
        async loadData () {
            if (!this.item._id) {
                this.isBusy = false;
                return;
            }
            this.isBusy = true;
            try {
                const res = await this.$http.get(`/User/${this.item._id}`);
                if (!res.data.s) {
                    return this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
                }
                const d = res.data;
                isEmailGood = true;
                isUsernameGood = true;
                this.isValidDetail = true;
                this.isValidSecurity = true;
                this.isValidRoles = true;
                this.isValidProjects = true;
                if (!d.d.Tags) d.d.Tags = [];
                this.item = d.d;
                this.hasUsername = typeof this.item.Username === 'string' && !!this.item.Username.length;
                if (!this.hasUsername) isUsernameGood = false;
                this.item.LastLoginString = this.item.LastLogin ? this.$format.dateShortTime(this.item.LastLogin) : 'n/a';
                this.itemOrg = Data.duplicate(d.d); // To calculate delta changes.

                // What to display and not.
                const P = this.$CONST.PERMISSION;
                this.canSecurity = this.user.has([P.STAFF_PASSWORD]);
                this.canRoles = this.user.has([P.STAFF_ROLES]);
                this.canProjects = this.user.has([P.STAFF_PROJECTS]);
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        getStatusColor (id) {
            const status = Constants.STATUS_USER.find(o => o.id === id);
            return status ? status.color : 'primary';
        },
        onUsernameBlur () {
            const name = this.item.Username || '';
            if (!this.item._id && name.length) {
                // Check the database.
                this.isUsernameBusy = true;
                this.checkUserName();
            }
        },
        async checkUserName () {
            this.isUsernameBusy = true;
            try {
                const res = await this.$http.get(`/User/available/username/${this.item.Username}`);
                const d = res.data.d;
                isUsernameGood = d.Username === null; // There is no exact name in the DB.
                this.usernameError = !isUsernameGood;
                this.usernameErrors = isUsernameGood ? null : 'This username is already taken!';
                this.$refs.formSecurity.validate();
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isUsernameBusy = false;
            }
        },
        onEmailBlur () {
            const email = this.item.Email || '';
            if (email.length) {
                // Check the database.
                this.isEmailBusy = true;
                this.checkEmail();
            }
        },
        async checkEmail () {
            this.isEmailBusy = true;
            try {
                const res = await this.$http.get(`/User/available/email/${this.item.Email}`);
                const d = res.data.d;
                isEmailGood = d.Email === null; // There is no exact name in the DB.
                this.usernameError = !isEmailGood;
                this.usernameErrors = isEmailGood ? null : 'This email is already taken!';
                this.$refs.formDetail.validate();
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isEmailBusy = false;
            }
        },
        onPassword () {
            this.$prompt({ title: 'Password', message: 'Enter a new password.', isPassword: true }, this.storePassword.bind(this));
        },
        async storePassword (result) {
            if (result.Action) {
                try {
                    this.isPasswordSaving = true;
                    const res = await this.$http.put(`/User/${this.item._id}/password`, { password: result.Value });
                    if (res.data.d) {
                        this.$success('Saved', 'The new password is set.', 2);
                    }
                    else {
                        this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                    }
                }
                catch (ex) {
                    this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                }
                finally {
                    this.isPasswordSaving = false;
                }
            }
        },
        toggleTags () {
            this.$nextTick(() => {
                if (this.item.Tags.length) {
                    this.item.Tags = [];
                }
                else {
                    this.item.Tags = this.tagsOptions.map(o => o._id).slice();
                }
            });
        },
        onAll () {
            this.item.Projects = this.projectOptions.map(o => o._id).slice();
        },
        onNone () {
            this.item.Projects = [];
        },
        async onSave () {
            this.$refs.formDetail.validate();
            if (this.$refs.formSecurity) this.$refs.formSecurity.validate(); // Don't run if not initialised.
            // this.$refs.formRoles.validate();
            if (this.isValidDetail && this.isValidSecurity && this.isValidRoles && this.isValidProjects) {
                const delta = Data.delta(this.itemOrg, this.item); // Only the changed fields.
                if (delta) {
                    try {
                        this.isSaving = true;
                        let res = null;
                        if (this.item._id) { // Update.
                            res = await this.$http.put(`/User/${this.item._id}`, delta);
                        }
                        else { // Add.
                            res = await this.$http.post('/User', delta);
                        }
                        if (res.data.s) {
                            this.onCancel();
                        }
                        else this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                    }
                    catch (ex) {
                        this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                    }
                    finally {
                        this.isSaving = false;
                    }
                }
            }
        },
        async onDelete () {
            try {
                this.isDeleting = true;
                const res = await this.$http.delete(`/User/${this.item._id}`);
                if (res.data.s) {
                    this.onCancel();
                }
                else this.$error(this.$t('general.delete_error'), this.$t('general.an_error'));
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isDeleting = false;
            }
        },
        onCancel () {
            this.$router.go(-1);
        }
    },
    computed: {
        tagIcon () {
            if (this.item.Tags.length === this.tagsOptions.length) return 'mdi-close-box';
            else if (this.item.Tags.length) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        })
    }
};
</script>
