var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"4"}},[_c('h1',{staticClass:"title font-weight-light"},[_vm._v(" Roles ("+_vm._s(_vm.$format.int(_vm.pager.total))+") "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","dark":""},on:{"click":_vm.onNew}},on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}])},[_c('span',[_vm._v("Add a new role")])])],1)]),_c('v-col',{attrs:{"sm":"2"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","right":"","fab":"","loading":_vm.isBusy},on:{"click":_vm.loadData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-col',{attrs:{"sm":"6"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',{attrs:{"sm":"auto","justify-self":"end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.toggleSearchAt}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchAt.icon))])],1)]}}])},[_c('span',[_vm._v("Match search text at this position")])])],1),_c('v-col',{attrs:{"sm":"10"}},[_c('v-text-field',{ref:"search",attrs:{"autofocus":"","dense":"","placeholder":"Type here to filter the list","hint":("(" + (_vm.searchAt.text) + ") " + (_vm.searchOnFields.join(', '))),"prepend-icon":"mdi-magnify","persistent-hint":"","clearable":""},on:{"click:clear":_vm.searchChange},nativeOn:{"keyup":function($event){return _vm.searchChange.apply(null, arguments)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"sm":"12"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"items-per-page":_vm.pager.size,"loading":_vm.isBusy,"multi-sort":"","hide-default-footer":"","no-data-text":"No data.","options":_vm.tableOptions},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.onSelect},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.Name))])]}},{key:"item.Perms",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getPermissionNames(item.Perms)))])]}}])}),_c('v-divider'),_c('div',{staticClass:"el-clearfix pb-1 pr-2"},[_c('div',{staticClass:"mt-1 float-left"},[_c('v-pagination',{attrs:{"length":_vm.pager.pages,"total-visible":7},on:{"next":_vm.filterData,"previous":_vm.filterData,"input":_vm.filterData},model:{value:(_vm.pager.page),callback:function ($$v) {_vm.$set(_vm.pager, "page", $$v)},expression:"pager.page"}})],1),_c('div',{staticClass:"mt-3 pl-2 float-right"},[_c('v-select',{staticStyle:{"max-width":"80px"},attrs:{"items":_vm.pageSizes,"label":"Page Size","dense":"","hide-details":""},on:{"change":_vm.filterData},model:{value:(_vm.pager.size),callback:function ($$v) {_vm.$set(_vm.pager, "size", $$v)},expression:"pager.size"}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }