<template>
    <v-container>
    <v-container v-if="showLogin">
        <v-row >
            <v-col sm="12">
                <div class="text-center f-xxxl">
                    Form
                    <v-img :src="require('../../assets/relax.svg')" alt="180.earth" width="270" class="d-inline-block"></v-img>
                </div>
                <div class="text-center font-weight-light">{{ surveyName }}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="0" sm="1" md="3" lg="3" class="text-center">&nbsp;</v-col>
            <v-col xs="12" sm="10" md="6" lg="6">
                <v-card class="pa-5 pt-2">
                    <v-card-title>{{ title }}</v-card-title>
                    <v-card-text>
                        <v-form ref="formLogin" v-model="isValid">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        autofocus
                                        label="Email address or username"
                                        v-model="email"
                                        max-length="50"
                                        hide-details
                                        :rules="[rules.required]"
                                        @keyup.native="goToPass">
                                    </v-text-field>
                            </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        ref="passwordTxt"
                                        label="Password"
                                        v-model="password"
                                        max-length="50"
                                        hide-details
                                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required]"
                                        :type="showPass ? 'text' : 'password'"
                                        @keyup.native="onKey"
                                        @click:append="showPass = !showPass">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :disabled="!isValid" :loading="isBusy" @click="onSignIn"><v-icon class="mr-2">mdi-lock-open-outline</v-icon>Log In</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col xs="0" sm="1" md="3" lg="3" class="text-center">&nbsp;</v-col>
        </v-row>
    </v-container>
    <v-container fill-height fluid v-else>
        <v-row align="center" justify="center">
        <v-spacer></v-spacer>
            <v-col cols="2">
                <v-progress-circular indeterminate :size="117" :width="12" color="primary"></v-progress-circular>
            </v-col>
        <v-spacer></v-spacer>
        </v-row>
        </v-container>
    </v-container>
</template>

<script>
import Util from '@/util/Util';

export default {
    name: 'Survey',
    mounted () {
        const surveyId = this.$route.query.id;
        if (surveyId) {
            const ctx = this;
            this.$nextTick(() => {
                ctx.checkSurvey(surveyId);
            });
        }
        else this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
        // http://localhost:8060/Survey?id=61588fd3b58aa63364bbca3f
        // http://localhost:8060/?Survey=61588fd3b58aa63364bbca3f
    },
    data: () => ({
        surveyName: '',
        external: false,
        showLogin: false,
        surveyId: '',
        projectId: '',
        isForm: false,
        title: 'Sign In',
        isBusy: false,
        email: '',
        password: '',
        showPass: false,
        isValid: false,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.'
        }
    }),
    methods: {
        async checkSurvey (id) {
            const res = await this.$http.get(`/Survey/${id}/check`);
            if (!res.data.s) {
                return this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            this.surveyName = res.data.d.Name;
            if (res.data.d.StatusId !== 'A') { // Only allow active surveys.
                return this.$info(res.data.d.Name + ' - ' + this.$t('general.survey_not_available_title'), this.$t('general.survey_not_available'), 0);
            }
            const user = this.$ls.get('User');
            if (user) {
                Object.defineProperty(user, 'has', { value: Util.has });
                this.$http.defaults.headers.common.Authorization = this.$ls.get('Token');
                this.$store.commit('user', user);
            }
            this.surveyId = res.data.d._id;
            this.projectId = res.data.d.ProjectId;
            this.isForm = res.data.d.Form;
            if (res.data.d.Exposure === 'I' && !user) { // If internal, the user must be logged in.
                // Sign in to continue.
                // return this.$error('Error', 'Sign in is required!');
                this.external = false;
                this.showLogin = true;
            }
            else if (res.data.d.Exposure === 'E') {
                this.external = true;
                this.loadSurvey();
            }
            else {
                this.loadSurvey();
            }
        },
        loadSurvey () {
            this.loadProjectBranding();
            console.log(this.isForm ? 'Form' : 'Act');
            if (this.external === true) {
                console.log('Pushing external');
                this.$router.push({ name: `Survey${this.isForm ? 'Form' : 'Act'}`, query: { id: this.surveyId, external: true } }).catch(() => {}); // Catch same location error.
            }
            else {
                console.log('Pushing normal');
                this.$router.push({ name: `Survey${this.isForm ? 'Form' : 'Act'}`, query: { id: this.surveyId } }).catch(() => {}); // Catch same location error.
            }
        },
        async loadProjectBranding () {
            try {
                const res = await this.$http.get(`/Project/${this.projectId}`);
                const d = res.data.d;

                this.$vuetify.theme.themes.light.primary = d.ColorPrimary || '#604B41';
                this.$vuetify.theme.themes.light.secondary = d.ColorSecondary || '#638856';
                this.$vuetify.theme.themes.light.accent = d.ColorAccent || '#85B973';
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        goToPass (evt) {
            if (evt.key === 'Enter') this.$refs.passwordTxt.focus();
        },
        onKey (evt) {
            if (evt.key === 'Enter') this.onSignIn();
        },
        async onSignIn () {
            this.$refs.formLogin.validate();
            if (this.isValid) {
                this.isBusy = true;
                try {
                    const res = await this.$http.post('/User/signin', { Email: this.email, Password: this.password });
                    if (res.data.s) {
                        this.showLogin = false;
                        const d = res.data.d;
                        d.user.FullName = `${d.user.FirstName || ''} ${d.user.LastName || ''}`.trim();
                        this.$ls.set('User', d.user);
                        this.$ls.set('Token', d.token);
                        // this.$store.commit('token', d.token); // Useful for file uploads.
                        Object.defineProperty(d.user, 'has', { value: Util.has });
                        this.$store.commit('user', d.user);
                        // this.$store.dispatch('isAuthed'); // Store authentication state.
                        this.$ls.set('LastDt', new Date());
                        this.$http.defaults.headers.common.Authorization = d.token; // Set the token on all request headers.
                        this.loadSurvey();
                    }
                    else this.$error(this.$t('signin.failed'), this.$t('signin.failed_msg'));
                }
                catch (ex) {
                    this.$error(this.$t('signin.failed'), this.$t('signin.failed_msg'));
                }
                finally {
                    this.isBusy = false;
                }
            }
        }
    }
};
</script>
