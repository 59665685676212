const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

export default {
    /**
     * Converst an array of values to an HTML bullet list.
     *
     * @param {Array} a Array of values.
     */
    arrayToBullet (a) {
        if (!a || !Array.isArray(a)) return a;
        const vals = ['<ul>'];
        a.forEach(value => {
            vals.push('<li>');
            vals.push(value);
            vals.push('</li>');
        });
        vals.push('</ul>');
        return vals.join('');
    },

    /*
    Symbol & Name               Condition                       Next Condition
    =======================================================================================
    @ Current Q value
    >> Jump to Q
    # Options id                @ == #2 (only valid for Select One and Select Many)
    == Equal to                 @ == Maize                      {9} == "Water Buck" >> 15
    != Not equal to             @ != Maize                      {9} != Maize >> 15
    > Greater than              @ > 2                           {9} > Maize >> 15
    >= Greater than or equals   @ >= 2                          {9} >= Maize >> 15
    < Less than                 @ < 10                          {9} < Maize >> 15
    <= Less than or equals      @ <= 10                         {9} <= Maize >> 15
    ^ Starts with               @^Mai                           {9}^Maize >> 15
    [] Format                   @[999999/99/9]                  {9}[999999/99/9] >> 15
    && And                      @ == Maize && Wheat             {9} == Maize && Wheat >> 15
    || Or                       @ == Maize || Wheat             {9} == Maize || Wheat >> 15
    () Group                    @ == 1 && 2 || 11 && 12         {9} == 1 && 2 || 11 && 12 >> 15
    {} Ref to other Q value     @ == {8}                        {9} == {8} >> 15
    time (input within 23:59)   @ == time
    today (must equal today)    @ == today
    // Regular expression       @ == /[^0-9]/

    Complex samples
    =======================================================================================
    @ == {8} && {5} == Chipata
    @ >= 0 && @ <= 2.5 || @ >= 5 && @ <= 10 || @ >= 20 && @ <= 50 >> 9
    @ == 'Maize'||'Wheat'
    */
    calc (rule, questions, options) { // , INPUT_TYPE
        try {
            if (!rule.length) return true; // Rule is empty.
            // 1. Break the rule apart.
            let tokens = null;
            rule = rule.trim();
            // var tokens = fn.replace(/[^\w\s]|_/g, function ($1) { return ' ' + $1 + ' ';}).replace(/[ ]+/g, ' ').split(' ');
            if (rule.indexOf('== /') > -1) {
                const reg = rule.split('==');
                if (reg.length < 2) return false;
                reg[0] = reg[0].trim();
                reg[1] = reg[1].trim();
                // this._tst.rule = reg[1].substr(1, reg[1].length - 2);
                const re = new RegExp(reg[1].substr(1, reg[1].length - 2));
                return re.test(reg[0]);
            }
            else tokens = rule.match(/(".*?"|[^"\s]+)+(?=\s*|\s*$)/g);
            if (!tokens) return true; // No tokens.

            let len = tokens.length;
            // 2. Replace all the {} question value placeholders and other variables.
            for (let i = 0; i < len; i++) {
                const t = tokens[i];
                if (t.startsWith('{') && t.length > 1) {
                    const vals = +t.substr(1, t.length - 2);
                    const q = questions.find(o => o.Index === vals);
                    if (q) {
                        // const qval = this.getAnswerValue(q, INPUT_TYPE);
                        let qval = false;
                        if (q.InputType === 20) qval = this.getAnswerValue(q, options);
                        else qval = q.Answer[q.loopCounter || 0];
                        if (Array.isArray(qval)) {
                            const vt = [];
                            // v = '[' + v.join(',') + ']';
                            const len2 = qval.length;
                            for (let i2 = 0; i2 < len2; i2++) {
                                if (this.isString(qval[i2])) vt.push('"' + qval[i2] + '"');
                                else vt.push(qval[i2]);
                            }
                            tokens[i] = '[' + vt.join(',') + ']';
                        }
                        else if (this.isDate(qval)) {
                            tokens[i] = `${qval.getFullYear()}${((qval.getMonth() + 1) + '').padStart(2, '0')}${qval.getDate()}`;
                        }
                        else tokens[i] = qval || "''";
                    }
                    else tokens[i] = "''";
                }
                else if (options && t.startsWith('#') && t.length > 1 && !isNaN(t.substr(1))) {
                    const tid = +t.substr(1);
                    const optionMap = Object.keys(options).map(key => [key, options[key]]);
                    const optionsIsArray = Array.isArray(options);
                    if (!optionsIsArray) options = optionMap;
                    const option = options.find(o => o._id === tid);
                    if (option) tokens[i] = option.Value; // Replace all spaces in value
                }
                // Replace today with the current date.
                else if (t === 'today') {
                    const dt = new Date();
                    tokens[i] = `${dt.getFullYear()}${((dt.getMonth() + 1) + '').padStart(2, '0')}${dt.getDate()}`;
                }
                else if (t === 'time') {
                    i -= 2;
                    tokens[i] += '';
                    tokens[i] = `${tokens[i].substr(0, 2)}:${tokens[i].substr(2)}`;
                    // tokens[i] = '/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test('' + tokens[i] + '')'
                    tokens[i] = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(tokens[i]);
                    tokens.splice(i + 1, 2);
                    len = tokens.length;
                }
            }

            // 3. Wrap strings in quotes.
            for (let i = 0; i < len; i++) {
                const t = tokens[i];
                if (t.length && ('"\'=!<>[]&|'.indexOf(t[0]) === -1 && this.isString(t))) {
                    tokens[i] = '"' + t + '"';
                }
            }

            // 4. Change array checks to use .indexOf
            const toks = [];
            for (let i = 0; i < len; i++) {
                let tok = tokens[i];
                if (tok[0] === '[' && tok.length > 1) {
                    if (['==', '!='].indexOf(tokens[i + 1]) === -1) {
                        // Publish the error.
                        return false;
                    }
                    tok = tok + '.indexOf(' + tokens[i + 2] + ') ' + (tokens[i + 1] === '==' ? '>' : '===') + ' -1';
                    i = i + 2;
                }
                toks.push(tok);
            }

            // 5. Check and replace the format check.
            len = toks.length;
            for (let i = 0; i < len; i++) {
                const t = toks[i];
                // if (typeof t === 'string' && t.indexOf('[') > -1) {
                if (t.startsWith('[') && t.endsWith(']')) {
                    console.log(t);
                    // const val = t.substring(1, t.length - 1).replace(']', '').split('[');
                    // console.log(val);
                    // val = this.makeFormatRegEx(val);
                    // toks[i] = vals[1].test(vals[0]);
                }
                else if (typeof t === 'string' && t.indexOf('^') > -1) {
                    const vals = t.substring(1, t.length - 1).split('^');
                    toks[i] = new RegExp('^' + vals[1]).test(vals[0]);
                }
            }

            // 6. Rebuild the rule.
            rule = toks.join(' ');

            // 7. Run the rule and return the result.
            return new Function('return ' + rule)(); // eslint-disable-line no-new-func
        }
        catch (ex) {
            console.error(ex.message, ':', ex.stack);
            // alert('Conditional Rule failure: ' + ex.message, 'Error', $.alerts.ERROR);
            return false;
        }
    },

    /**
     * Parses string dates (from service result JSON that is in ISO or MS Ajax format) to JS Dates.
     *
     * @param {*} key Property name that is parsed.
     * @param {*} value Property value.
     * @returns Date
     */
    dateParser (key, value) {
        if (typeof value === 'string') {
            let a = reISO.exec(value);
            if (a) return new Date(value);
            a = reMsAjax.exec(value);
            if (a) {
                const b = a[1].split(/[-+,.]/);
                return new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
        }
        return value;
    },

    /**
     * Enforces that a function not be called again until a certain amount of time has passed without it being called, e.g. execute the function only if 100 milliseconds have passed without it being called.
     *
     * @param {*} func Function to limit.
     * @param {*} delay Delay in ms.
     * @returns Function
     */
    debounce (func, delay) {
        let inDebounce;
        return function noName (...args) {
            const context = this;
            // const args = arguments
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    },

    /**
     * Shallow field comparison between two objects.
     * Returns the changed fields of oUpdated if different from oSource.
     * Overlapping fields from oSource are returned if overlap is true.
     *
     * @param {*} oSource Master object to compare against.
     * @param {*} oUpdated Updated object and values to test.
     * @param {*} overlap If a boolean, indicates that overlapping fields from source must be returned, otherwise it can be the master object fields.
     * @returns Object
     */
    delta (oSource, oUpdated, overlap) {
        const delta = {};
        if (!oSource || !oUpdated) return null;
        let keys;
        if (typeof overlap === 'object') keys = Object.keys(overlap);
        else if (overlap === true) keys = Object.keys(oSource);
        else keys = Object.keys(oUpdated);
        const len = keys.length;
        if (len === 0) return null;
        keys.forEach(key => { // Check each property.
            if (Array.isArray(oUpdated[key])) {
                if (typeof oUpdated[key][0] === 'object') {
                    delta[key] = oUpdated[key];
                }
                else {
                    if (!this.isArrayEqual(oSource[key], oUpdated[key])) {
                        delta[key] = oUpdated[key];
                    }
                }
            }
            else if (typeof oUpdated[key] === 'object') {
                const deltaSub = this.delta(oSource[key], oUpdated[key]); // Only the changed fields.
                if (deltaSub) {
                    delta[key] = oUpdated[key]; // Value is different. Add to delta.
                }
            }
            else {
                if (oUpdated[key] !== oSource[key]) { // Check strict value.
                    delta[key] = oUpdated[key]; // Value is different. Add to delta.
                }
            }
        });

        if (Object.keys(delta).length === 0) return null; // No changed fields.
        delta._id = oSource._id;
        return delta;
    },

    /**
     * Can clone anything by using JSON parse and stringify. Deep copy.
     * Slow but makes a full deep copy.
     *
     * @param {any} o Object to duplicate.
     * @returns Duplicated object.
     */
    duplicate (o) {
        return o ? JSON.parse(JSON.stringify(o)) : {};
    },

    /**
     * Returns the answer value by type.
     *
     * @param {Object} q Question.
     * @param {Object} INPUT_TYPE Input types constants.
     */
    getAnswerValue (q, INPUT_TYPE) {
        if (!q) return null;
        switch (q.InputType) {
            case INPUT_TYPE.Note:
            case INPUT_TYPE.Text:
            case INPUT_TYPE.Mobile:
            case INPUT_TYPE.Email:
            case INPUT_TYPE.WebAddress:
            case INPUT_TYPE.SelectOne:
            case INPUT_TYPE.Barcode:
            case INPUT_TYPE.Image:
            case INPUT_TYPE.Signature:
                return q.ValueStr === undefined ? null : q.ValueStr;
            case INPUT_TYPE.Number:
            case INPUT_TYPE.Rating:
            case INPUT_TYPE.RangeSlider:
                return q.ValueNum === undefined ? null : q.ValueNum;
            case INPUT_TYPE.YesNo:
                return q.ValueBool === undefined ? null : q.ValueBool;
            case INPUT_TYPE.SelectMany:
            case INPUT_TYPE.GPSLocation:
                return q.ValueArr === undefined ? null : q.ValueArr.slice(); // Make a copy.
            case INPUT_TYPE.Date:
            case INPUT_TYPE.DateOfBirth:
                return q.ValueDate === undefined ? null : q.ValueDate;
            case INPUT_TYPE.Time:
                return q.ValueTime === undefined ? null : q.ValueTime;
            default:
                return q.ValueStr === undefined ? null : q.ValueStr;
            // q.ValueBytes
        }
    },

    /**
     * Generates a GUID string.
     * @link http://slavik.meltser.info/?p=142
     *
     * @returns {String} The generated GUID.
     * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
     * @author Slavik Meltser (slavik@meltser.info).
     */
    guid () {
        return this.p8() + this.p8(true) + this.p8(true) + this.p8();
    },

    /**
     * Returns the data type for the input type.
     *
     * @param {Number} inputType Input type id.
     * @param {Object} INPUT_TYPE Input types constants.
     */
    inputDataType (inputType, INPUT_TYPE) {
        switch (inputType) {
            case INPUT_TYPE.Note:
            case INPUT_TYPE.Text:
            case INPUT_TYPE.Mobile:
            case INPUT_TYPE.Email:
            case INPUT_TYPE.WebAddress:
            case INPUT_TYPE.SelectOne:
            case INPUT_TYPE.Barcode:
                return 'String';
            case INPUT_TYPE.Number:
            case INPUT_TYPE.Rating:
            case INPUT_TYPE.RangeSlider:
                return 'Number';
            case INPUT_TYPE.YesNo:
                return 'Boolean';
            case INPUT_TYPE.SelectMany:
                return 'Array';
            case INPUT_TYPE.GPSLocation:
                return 'Location';
            case INPUT_TYPE.Date:
            case INPUT_TYPE.DateOfBirth:
                return 'Date';
            case INPUT_TYPE.Time:
                return 'Time';
            case INPUT_TYPE.Image:
            case INPUT_TYPE.Signature:
                return 'Image';
            default:
                return 'String';
        }
    },

    isArrayEqual (arr1, arr2) {
        if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;
        const a1 = arr1.concat().sort();
        const a2 = arr2.concat().sort();
        const len = a1.length;
        for (let i = 0; i < len; i++) {
            if (a1[i] !== a2[i]) {
                return false;
            }
        }
        return true;
    },

    /**
     * Checks if the given value is a date.
     *
     * @param {any} v Value to check.
     * @returns Boolean.
     */
    isDate (v, obj) {
        if (!obj) obj = {};
        // Date object.
        if (Object.prototype.toString.call(v) === '[object Date]') return true;
        return false;
        /* // Anything but a string fails because only the string will be parsed further.
        if (typeof v !== 'string') return false;
        // Check if the string is a date format. Don't use Date.parse() because 'Test 1' parses as a sate.
        const nums = v.replace(/[^0-9]/g, '');
        let other = v.replace(/[0-9]/g, '');
        let dp;
        // Check if a 00/00/0000 style date.
        if (!other.length) return false;
        if (other === '//' && nums.length <= 8) {
            dp = v.split('/');
            if (dp[0].length === 4) { // TODO: Day/Month position is locle dependant. Move to config.
                obj.dt = new Date(parseInt(dp[0], 10), parseInt(dp[1], 10), parseInt(dp[2]));
            }
            else {
                obj.dt = new Date(parseInt(dp[2], 10), parseInt(dp[1], 10), parseInt(dp[0]));
            }
        }
        return !isNaN(obj.dt.getTime()); */
    },

    isString (v) {
        if (isNaN(v)) {
            if (v === 'true' || v === 'false') return false;
            else return true;
        }
        else return false;
    },

    /**
     * var reg = makeFormatRegEx("999999/**\/9");
     * console.log(reg.test("123456/BA/9"));
     *
     * @param {String} fmt Fomrat
     */
    makeFormatRegEx (fmt) {
        if (typeof fmt !== 'string') return fmt;
        // 9 - Allow numbers only.
        // a - Allow alphas only.
        // * - Allow alphanumeric only.
        // All others are literals.
        let rs = '^';
        let prev = null;
        let fmask = false;
        let cnt = 0;
        const len = fmt.length;
        for (let i = 0; i < len; i++) {
            let curr = fmt[i];
            if (curr === prev) {
                ++cnt;
            }
            else {
                if (i !== 0) {
                    // Close the previous entry.
                    if (fmask) {
                        rs += cnt + '}';
                    }
                }
                fmask = true;
                if (curr === '9') rs += '\\d{';
                else if (curr === 'a') rs += '\\D{';
                else if (curr === '*') rs += '\\w{';
                else {
                    if (curr === '/') curr = '/';
                    rs += curr;
                    fmask = false;
                }
                cnt = 1;
            }
            prev = curr;
        }
        // Close the last entry.
        rs += cnt + '}';
        // console.log(rs);
        return new RegExp(rs);
    },

    /**
     * Used by guid() to create one part.
     *
     * @param {any} sep Specifies that a seperator must be prepended.
     * @returns A guid part.
     * @see guid
     */
    p8 (sep) {
        const p = (Math.random().toString(16) + '000000000').substr(2, 8);
        return sep ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
    },

    random (min, max) { // min and max included.
        return Math.floor(Math.random() * (max - min + 1) + min);
    },

    /**
     * Sort an object array by field.
     *
     * @param {*} arr Array to sort.
     * @param {*} field Field to sort on.
     */
    sort (arr, field) {
        if (!arr.length) return arr;
        if (isNaN(arr[0][field])) { // Text sort.
            arr.sort((a, b) => {
                const aV = (a[field] || '').toUpperCase();
                const bV = (b[field] || '').toUpperCase();
                if (aV < bV) return -1;
                if (aV > bV) return 1;
                return 0;
            });
        }
        else { // Numeric sort (10 or "10" is a valid numeric)
            arr.sort((a, b) => {
                const aV = a[field] || a || 0;
                const bV = b[field] || b || 0;
                return aV - bV;
            });
        }
        return arr; // Convenience return. Sometimes a new array can be returned from a function e.g. `return Data.sort(arr, field);`.
    },

    /**
     * Limit the amount a function is invoked.
     * Enforces a maximum number of times a function can be called over time, e.g. execute the function at most once every n milliseconds.
     *
     * @param {*} func Function to limit.
     * @param {*} limit Time limit in ms.
     * @returns Function
     */
    throttle (func, limit) {
        let lastFunc;
        let lastRan;
        return function noName (...args) {
            const context = this;
            // const args = arguments;
            if (!lastRan) {
                func.apply(context, args);
                lastRan = Date.now();
            }
            else {
                clearTimeout(lastFunc);
                lastFunc = setTimeout(() => {
                    if ((Date.now() - lastRan) >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                }, limit - (Date.now() - lastRan));
            }
        };
    },

    /**
     * Can clone anything by using JSON parse and stringify. Deep copy.
     * Slow but makes a full deep copy.
     * Similar to duplicate but reinstates all functions.
     *
     * @export
     * @param {any} o Object to duplicate.
     * @returns Duplicated object.
     */
    xcopy (o, o2) {
        if (o === undefined || o === null) return;
        if (o.toISOString) return o; // Date.
        if (!o2) o2 = {};
        const keys = Object.keys(o);
        for (const key of keys) {
            switch (typeof o[key]) {
                case 'object':
                    if (Array.isArray(o[key])) {
                        o2[key] = [];
                        for (const item of o[key]) {
                            switch (typeof item) {
                                case 'object': o2[key].push(this.xcopy(item)); break;
                                default: o2[key].push(item); break;
                            }
                        }
                    }
                    else if (o2.toISOString) o2[key] = o[key]; // Date.
                    else o2[key] = this.xcopy(o[key]);
                    break;
                default:
                    o2[key] = o[key];
                    break;
            }
        }
        return o2;
    }
};
