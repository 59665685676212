<template>
    <v-container fluid class="h-100p">
        <v-row class="mb-0">
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    Reports
                    <span class="float-right" v-if="viewReport">
                        <v-menu open-on-hover bottom offset-y nudge-top="-4">
                            <template v-slot:activator="{ on }">
                                <span class="forStatus">
                                    <!-- <v-chip label color="transparent" v-on="on" class="mt-1 f-bm">Export <v-icon class="ml-1">mdi-export</v-icon></v-chip> -->
                                    <v-btn v-on="on" text class="ml-2"><v-icon class="mr-2">mdi-export</v-icon>Export</v-btn>
                                </span>
                            </template>
                            <v-list>
                                <v-list-item v-for="item in exportOptions" :key="item.id" @click="onExportClick(item.id)">
                                    <v-list-item-title><v-icon left>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </span>
                </h1>
            </v-col>
        </v-row>
        <v-row class="el-map-cover mt-0">
            <v-col sm="12" class="pa-1 pt-0">
                <v-card class="h-100p d-flex flex-column" :loading="isBusy">
                    <v-card-actions class="pa-0">
                        <v-tabs small v-model="tab" @change="onTabChange">
                            <v-tab>Options</v-tab>
                            <v-tab :disabled="options.Survey === null">Report</v-tab>
                            <v-subheader style="position: absolute; right: 5px;">Data for<b class="ml-2 f-xl">{{selectedReportName}}</b></v-subheader>
                        </v-tabs>
                    </v-card-actions>
                    <!-- Options -->
                    <v-card-text class="flex-grow-1 pa-0" v-if="!viewReport">
                        <v-form class="w-1024-max">
                            <v-row class="row-smaller">
                                <v-col sm="5">
                                    <v-subheader class="text-uppercase mt-8 mb-3" style="height: 20px;">Select Survey</v-subheader>
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-radio-group v-model="options.Survey" column @change="onReportChange">
                                                <v-radio
                                                    v-for="o in surveyNames"
                                                    :key="o._id"
                                                    :label="o.Name"
                                                    :value="o._id"
                                                    :class="`mt-1 ${o._id}`"
                                                    hide-details>
                                                </v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col sm="7">
                                    <v-subheader class="text-uppercase mt-8 mb-3" style="height: 20px;">Filter Options</v-subheader>
                                    <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-menu
                                                v-model="modalStartDate"
                                                :close-on-content-click="false"
                                                :nudge-right="32"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="options.StartDate"
                                                        placeholder="Data start date"
                                                        hint="From date"
                                                        prepend-icon="mdi-calendar"
                                                        persistent-hint
                                                        readonly
                                                        v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="options.StartDate" @input="modalStartDate = false" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-menu
                                                v-model="modalEndDate"
                                                :close-on-content-click="false"
                                                :nudge-right="32"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="options.EndDate"
                                                        placeholder="Data end date"
                                                        hint="To date"
                                                        prepend-icon="mdi-calendar"
                                                        persistent-hint
                                                        readonly
                                                        v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="options.EndDate" @input="modalEndDate = false" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-select multiple disabled label="Filter data by project tags" clearable v-model="options.Tags" :items="tagOptions" item-text="Name" item-value="_id"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-filter class="mb-4" :filterList="filters" :surveyFilterFields="filterFields"></v-filter>
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row class="row-smaller">
                                        <v-col sm="6">
                                            <v-select label="Marker type" v-model="markerType" :items="typeOptions" item-text="Name" item-value="_id" @change="onMarkerTypeChange"></v-select>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-select label="Marker colour" v-model="markerColor" :items="colorOptions" item-text="Name" item-value="_id" @change="onMarkerColorChange"></v-select>
                                        </v-col>
                                    </v-row> -->
                                    <v-row class="row-smaller">
                                        <v-col sm="12">
                                            <v-btn color="primary" @click="onViewReport" :disabled="options.Survey === null">View Report</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <!-- Report -->
                    <v-card-text class="flex-grow-1 pa-0" v-if="viewReport">
                        <v-alert v-if="noData" prominent type="info" class="ma-2">
                            <v-row align="center">
                                <v-col class="grow">
                                    No data found. The specified criteria yielded no results.<br/>
                                    If you are certain that there is data, review and amend the specified filter options and try again.
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn text @click="onToOptionsClick"><v-icon class="mr-1">mdi-arrow-left-circle</v-icon>Amend Filter Options</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                        <div v-else class="d-flex flex-column">
                            <div class="mt-4 ml-2">
                                <div class="f-b ml-0">Total records: {{$format.int(pager.total)}}</div>
                            </div>
                            <v-divider></v-divider>
                                <div class="el-clearfix pb-1 pr-2">
                                    <div class="mt-1 float-left">
                                        <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="10" @next="loadData" @previous="loadData" @input="loadData" style="width:auto;"></v-pagination>
                                    </div>
                                    <div class="mt-3 pl-2 float-right">
                                        <!-- <v-text-field v-model.number="pager.page" label="Go to Page" dense placeholder="Page #" :hint="`Max: ${$format.int(pager.pages)}`" class="el-text-right" style="max-width:90px;" persistent-hint v-on:keyup.enter="loadData" v-on:blur="loadData"></v-text-field> -->
                                        <v-numeric v-model="pager.page" :maxlength="11" label="Go to Page" dense placeholder="Page #" :hint="`Max: ${$format.int(pager.pages)}`" class="el-text-right" style="max-width:90px;" persistent-hint @enter="loadData" v-on:blur="loadData"></v-numeric>
                                    </div>
                                    <div class="mt-3 pl-2 float-right">
                                        <v-select v-model="pager.size" :items="pageSizes" label="Page Size" dense hide-details style="max-width:80px;" @change="loadData"></v-select>
                                    </div>
                                </div>
                            <v-card class="d-flex flex-column ml-2 mr-2 mt-0 mb-2" :elevation="2" v-for="o in reportData" :key="o._id">
                                <div class="f-b ml-2 el-text-truncate">{{o._Tech}}<span class="fs ml-2">({{o._User}})</span></div>
                                <div class="f-s ml-2 mr-2 opa-5">{{o._Date}}<span class="float-right">{{o._Version}}</span></div>
                                <v-divider></v-divider>
                                <v-card-text class="flex-grow-1 pa-0">
                                    <v-container fluid class="pt-0">
                                        <div v-for="item in o.Values" :key="item._id">
                                            <v-row dense class="mt-2">
                                                <v-col sm="12" class="pt-0">
                                                    <div class="f-s" style="line-height:1;" v-html="item.Title"></div>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-0" dense>
                                                <v-col sm="12" class="pt-0">
                                                    <div class="body-1">
                                                        <img v-if="item.Image" :src="item.Image" alt="item.Title" width="100%" style="max-width:300px;">
                                                        <v-simple-table v-else-if="Array.isArray(item.Value)">
                                                            <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                <th class="text-left">
                                                                    <b>Question</b>
                                                                </th>
                                                                <th class="text-left">
                                                                    <b>Answer</b>
                                                                </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="qa in item.Value" :key="qa._id">
                                                                <td>{{ qa.Question }}</td>
                                                                <td>{{ (qa.Value)? 'Yes': 'No' }}</td>
                                                                </tr>
                                                            </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                        <span v-else>{{item.Value || 'n/a'}}</span>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                            <v-divider></v-divider>
                                <div class="el-clearfix pb-1 pr-2">
                                    <div class="mt-1 float-left">
                                        <v-pagination v-model="pager.page" :length="pager.pages" :total-visible="10" @next="loadData" @previous="loadData" @input="loadData" style="width:auto;"></v-pagination>
                                    </div>
                                </div>
                        </div>
                    </v-card-text>
                </v-card>
                <!-- <div class="el-map-float" v-if="viewReport && viewing.On">
                    <v-card ref="formDetail" class="h-100p d-flex flex-column" :loading="isBusy">
                        <div class="f-b ml-2 el-text-truncate">{{viewing.Tech}}</div>
                        <div class="f-s ml-2 mr-2 opa-5">{{viewing.Date}}<span class="float-right">{{viewing.Version}}</span></div>
                        <v-divider></v-divider>
                        <v-card-text class="flex-grow-1 pa-0" v-if="viewReport" style="overflow-y:auto;">
                            <v-container fluid class="pt-0">
                                <div v-for="item in viewing.Values" :key="item._id">
                                    <v-row dense class="mt-2">
                                        <v-col sm="12" class="pt-0">
                                            <div class="f-s" style="line-height:1;" v-html="item.Title"></div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0" dense>
                                        <v-col sm="12" class="pt-0">
                                            <div class="body-1">
                                                <img v-if="item.Image" :src="item.Image" alt="item.Title" width="100%">
                                                <span v-else>{{item.Value || 'n/a'}}</span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-0">
                            <v-btn color="primary" text small :loading="isPdfBusy" @click="onPdf">
                                <v-icon>mdi-email-receive-outline</v-icon><span class="hide-sx ml-1">PDF</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </div> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import filterFilter from '@/controls/filter/Filter';
import Util from '@/util/Util';
import numeric from '@/controls/Numeric';
import { mapState } from 'vuex';

export default {
    name: 'Reports',
    components: {
        'v-numeric': numeric,
        'v-filter': filterFilter,
    },
    data: () => ({
        isBusy: false,
        isPdfBusy: false,
        tab: 'options',
        surveyId: '',
        surveyOptions: [],
        editLang: 'en', // English is the first language for this deployment.
        tagReg: /<\/?[^>]+(>|$)/g, // Remove tags from string.
        viewReport: true,
        surveyNames: [],
        selectedReportName: 'n/a',
        userOptions: [],
        tagOptions: [],
        options: {
            ProjectId: '',
            Survey: null,
            StartDate: new Date().toISOString().substring(0, 10),
            EndDate: new Date().toISOString().substring(0, 10),
            Tags: [],
            EmbedImages: true,
        },
        tableOptions: {
            sortBy: ['_UD'],
            sortDesc: [true],
            page: 1,
            itemsPerPage: 20
        },
        exportOptions: [
            { id: 'pdf', icon: 'mdi-alpha-p-box-outline', text: 'Acrobat PDF (pdf)' },
            { id: 'comma', icon: 'mdi-comma-box-outline', text: 'Comma Separated Values (csv)' },
            { id: 'xlsx', icon: 'mdi-file-excel-box-outline', text: 'Microsoft Excel (xlsx)' },
            { id: 'semi', icon: 'mdi-alpha-s-box-outline', text: 'Semicolon Separated Values (csv)' },
            { id: 'tab', icon: 'mdi-alpha-t-box-outline', text: 'Tab Separated Values (tsv)' },
        ],
        // ---
        filterMenu: false,
        filters: [],
        filterFields: [],
        // reasons: [],
        searchAt: {},
        searchAtText: '',
        searchAtOptions: Constants.SEARCH_STRING,
        approvalOptions: [
            { _id: 'P', Value: 'To Review' },
            { _id: 'N', Value: 'Rejected' },
            { _id: 'Y', Value: 'Approved' },
        ],
        // ---
        fieldNames: false,
        modalStartDate: false,
        modalEndDate: false,
        reportData: [],
        noData: false,
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 20,
            page: 1,
            pages: 1,
            total: 0
        },
    }),
    mounted () {
        const now = new Date();
        now.setMonth(now.getMonth() - 1);
        this.options.StartDate = now.toISOString().substring(0, 10);
        this.options.Survey = this.$ls.get('Report_Survey', null);
        const pageSize = this.$ls.get('Report_PageSize') || '20';
        if (pageSize) this.pager.size = parseInt(pageSize, 10);
        this.loadLookups();
    },
    methods: {
        onTabChange (idx) {
            this.viewReport = idx === 1; // Index 0 is the Options tab.
            if (this.viewReport) this.loadData();
        },
        async loadLookups () {
            if (!this.viewProject) return;
            this.isBusy = true;
            try {
                // Multi column sorting uses a different pager object. Sync the two.
                this.tableOptions.page = this.pager.page;
                this.tableOptions.itemsPerPage = this.pager.size;
                // Surveys.
                const surveys = await this.$db.getPublished(this.viewProject._id, '', null, latest => { // Locally stored.
                    this.setSurveyNames(latest); // Refresh from the server.
                });
                this.setSurveyNames(surveys);
                this.onReportChange();

                this.tagOptions = this.viewProject.Tags.slice(0);

                const res = await this.$http.get('/User/fullnames', { params: { ProjectId: this.viewProject._id } });
                if (res.data.s) {
                    this.userOptions = res.data.d;
                    for (const user of this.userOptions) { // Adjust for common selects.
                        user.Value = user.FullName;
                    }
                }
                if (this.options.Survey) this.onReportChange();
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                // this.loadData();
            }
        },
        async loadData () {
            if (!this.viewProject) return;
            this.isBusy = true;
            try {
                if (!this.pager.page) this.pager.page = 1;
                if (this.pager.page > this.pager.pages) this.pager.page = this.pager.pages;
                // Remember the page size.
                this.$ls.set('Report_PageSize', this.pager.size);
                this.$ls.set('Report_Survey', this.options.Survey);
                this.reportData = [];
                this.noData = false;
                this.options.ProjectId = this.viewProject._id;
                // this.options.Survey = this.surveyId; // NOTE: This is set by the UI.
                this.options.paging = this.pager;
                this.options.Where = {};
                Util.buildQueryWithFilters(this.options.Where, this.filters);
                this.options.Table = this.tableOptions;
                const { data } = await this.$http.post(`/Survey/${this.options.Survey}/answer-report`, this.options);
                if (data.s) {
                    // Data.
                    data.d.forEach(d => {
                        d.Values.forEach(o => {
                            // if (o.Image) o.Image = `${this.$_ROOT_URL_IMG}/${o.Image}`;
                            if (o.Image) {
                                o.Value = `<image src="${o.Image}" alt="" width="100%" style="max-width:300px;" />`; // Same as the template.
                            }
                        });
                    });
                    this.reportData = data.d;
                    // Pager.
                    this.pager = data.p;
                    this.options.paging = this.pager; // In case the user exports, this is needed.
                    if (!this.reportData.length) this.noData = true;
                }
                else this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
            }
        },
        setSurveyNames (surveys) {
            const names = [];
            for (const survey of surveys) {
                names.push({ _id: survey.SurveyId, Name: survey.Name });
            }
            this.surveyNames = names;
        },
        onToOptionsClick () {
            this.tab = 0;
            this.viewReport = false;
        },
        onViewReport () {
            this.tab = 1;
            this.viewReport = true;
            this.reportData = [];
            this.loadData();
        },
        async onReportChange () {
            const rep = this.surveyNames.find(o => o._id === this.options.Survey);
            this.selectedReportName = rep ? rep.Name : 'n/a';
            this.filters.splice(0, this.filters.length);

            // Prepare the survey questions for the filter.
            const survey = await this.$db.getPublishedOne(this.options.Survey);
            if (!survey) return;
            const headers = [];
            if (survey.Approval) {
                headers.push({ text: `${this.makeShort('Approval')}`, align: 'start', value: 'Approved', width: 100, type: 'String', itype: Constants.INPUT_TYPE.SelectOne, options: this.approvalOptions, Iter: null, _vis: this.getHeaderVisibility('Approval'), removable: false });
                this.filters.push({ _id: Date.now(), Field: 'Approved', Value: 'Y', Oper: Data.duplicate(Constants.SEARCH_STRING.find(o => o.text === 'Exact')), IType: Constants.INPUT_TYPE.SelectOne, Removable: false });
            }
            for (const o of survey.Questions) {
                // Options for when the input has additional settings.
                let options;
                if (o.InputType === Constants.INPUT_TYPE.SelectOne || o.InputType === Constants.INPUT_TYPE.SelectMany) {
                    options = o.Options.filter(x => x.Lang === this.editLang && x.Value);
                }
                // const showField = req.body.fields && req.body.fields.indexOf(q.Field) > -1;
                const showField = true;
                o.Question = o.Question[0].Value; // TODO: Language.
                o.Iter = o.LoopParentIndex !== undefined;
                o.Type = Data.inputDataType(o.InputType, Constants.INPUT_TYPE);
                o.Show = o.Rep_Show === false ? showField : false;
                headers.push({
                    text: `${o.Index}. ${this.getHeaderText(o)}`,
                    align: o.Type === 'Number' ? 'end' : 'start',
                    value: o.Field,
                    width: 100,
                    type: o.Type,
                    itype: o.InputType,
                    options,
                    Iter: o.Iter,
                    _vis: this.getHeaderVisibility(o.Field)
                });
            }
            // System columns.
            headers.push({ text: `${this.makeShort('Version')}`, align: 'start', value: '_Version', width: 100, type: 'String', itype: Constants.INPUT_TYPE.String, Iter: null, _vis: this.getHeaderVisibility('_Version') });
            // headers.push({ text: `${this.makeShort('Start')}`, align: 'start', value: '_Started', width: 100, type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_Started') });
            // headers.push({ text: `${this.makeShort('End')}`, align: 'start', value: '_Ended', width: 100, type: 'Date', itype: Constants.INPUT_TYPE.Date, Iter: null, _vis: this.getHeaderVisibility('_Ended') });
            headers.push({ text: `${this.makeShort('User')}`, align: 'start', value: '_Tech', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_Tech') });
            headers.push({ text: `${this.makeShort('Username')}`, align: 'start', value: '_User', width: 200, type: 'String', itype: Constants.INPUT_TYPE.SelectMany, options: this.userOptions, Iter: null, _vis: this.getHeaderVisibility('_User') });
            headers.push({ text: 'UID', align: 'start', value: '_id', width: 100, type: 'String', itype: Constants.INPUT_TYPE.Text, Iter: '', _vis: this.getHeaderVisibility('_id') });
            this.filterFields = headers;
        },
        getHeaderVisibility (field) {
            // if (!this.headerVis || this.headerVis[field] === undefined) return true;
            // return this.headerVis[field];
            return true; // TODO:
        },
        getHeaderText (q) {
            if (this.fieldNames) return q.Rep_Field || q.Field;
            else return this.makeShort(q.Question);
        },
        makeShort (str) {
            const len = str.length;
            const s = str.replace(this.tagReg, '').substr(0, 20);
            return (len > 20 && s.length === 20) ? `${s}...` : s;
        },
        async onExportClick (id) {
            try {
                const options = Data.duplicate(this.options);
                options.exportAs = id;
                const res = await this.$http.post(`/Survey/${this.options.Survey}/answer-report`, options);
                if (res.data.s) {
                    this.$success('Submitted', 'Your export has been queued for generation and will be available in the Downloads section, once complete.', 3);
                }
                else this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            catch (ex) {
                console.error(ex.message);
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        /* onPdf () {
            const options = {
                title: 'Send PDF to ...',
                message: 'Email address of recipient. (Comma separate multiple email addresses.)',
                value: this.user.Email
            };
            this.$prompt(options, async value => {
                if (value.Action && value.Value) {
                    this.isPdfBusy = true;
                    try {
                        const res = await this.$http.post(`/Survey/${this.viewing.SurveyId}/answer/${this.viewing.AnswerId}/email-pdf`, { Email: value.Value });
                        if (res.data.s) this.$success('PDF Sent', `The PDF has been sent to ${value.Value}`);
                        else this.$error('Error', res.data.m);
                    }
                    catch (ex) {
                        this.$error('Error', ex.message);
                    }
                    finally {
                        this.isPdfBusy = false;
                    }
                }
            });
        } */
        /* onPrint () {
            var win = window.open('print', 'Print', {}); // gen.CONST.POP_OPTIONS
            var cnt = this.$refs.formDetail.$el.outerHTML;
            var tmr = setInterval(function () {
                if (win.document.readyState === 'complete' && win.init) {
                    clearInterval(tmr);
                    win.content = cnt;
                    // win.init();
                }
            }, 100);
        } */
    },
    watch: {
        viewProject () {
            this.loadLookups();
        },
    },
    computed: {
        ...mapState({
            user: 'user',
            viewProject: 'viewProject'
        })
    }
};
</script>
