// import axios from 'axios';
// import config from '@/config';

export default function guest ({ next }) {
    const userDetails = localStorage.srvy__User;
    if (userDetails !== undefined) {
        // Todo: Add code to validate token on each call to ensure user gets redirected to login if token has expired
        /*
        const userObj = JSON.parse(userDetails);
        const token = JSON.parse(localStorage.srvy__Token);
        const data = {
            headers: {
                Authorization: token.value
            }
        };
        axios.get(config.API_SERVER + '/api/v1/UserToken/validate/' + userObj.value._ref, data).then(resp => {
            var currentDate = new Date();
            var dbDate = new Date(resp.data.d[0].UpdateDate);
            console.log(currentDate.getTime() <= dbDate.getTime());
        }); */
        return next();
    }
    return next('/SignOut');
}
